import React, { useCallback, useEffect } from 'react';
import { Icon, Tab } from 'semantic-ui-react';
import Stopper from './stopper';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupRequest, valuesListSelector } from '../../../../ducks/lookup';

const Stoppers = ({ form, routeActiveIndex, tabChange, settings, onChange, updateHistory }) => {
    const dispatch = useDispatch();
    const { stoppers: stoppers = [] } = form;
    const stopperTabs = [];

    /*
    */
    stoppers.forEach((stopper, i) => {
        stopperTabs.push({
            menuItem: {
                key: i,
                content: (
                    <label>
                        {stopper.stopperType} {stopper.index}
                    </label>
                ),
            },
            render: () => {
                return (
                    <Stopper
                        index={i}
                        form={form}
                        stopper={stopper}
                        settings={settings}
                        onChange={onChange}
                        updateHistory={updateHistory}
                    />
                );
            },
        });
    });

    return (
        <div className="tabs-card">
            <Tab
                className="all-tabs"
                panes={stopperTabs}
                activeIndex={routeActiveIndex}
                menu={{ vertical: true }}
                menuPosition="left"
                onTabChange={tabChange}
            />
        </div>
    );
};

export default Stoppers;
