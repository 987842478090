import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Input } from 'semantic-ui-react'
import InputMask from 'react-input-mask'

const PhoneNumber = ({
    value,
    name,
    onChange,
    onBlur,
    isDisabled,
    noLabel,
    className,
    text,
    error,
    type,
    datalist: valuesList = [],
    placeholder,
    isRequired,
    autoComplete,
    autoFocus,
    warningText
}) => {
    const { t } = useTranslation()

    const pastHandler = e => {
        const pastedValue = e.clipboardData.getData('Text').trim()
        const phoneNumber = normalizePhoneNumber(pastedValue);

        if(phoneNumber.length == 12) {
            e.preventDefault()
            onChange(e, { value: pastedValue, name })
            onChange(e, { value: phoneNumber, name })
        } 
    }

    const normalizePhoneNumber = value => {
        let processedValue = ''

        const allowedFirstChar = ['8', '7', '+7']
        if (allowedFirstChar.includes(value[0])) {
            for(let i = 0; i < value.length; ++i){
                const char = value[i]

                if(!isNaN(parseInt(char))) {
                    processedValue += char;
                }
            }

            processedValue = `+7${processedValue.substring(1, processedValue.length)}`
        }

        return processedValue
    }

    return (
        <Form.Field>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span
                        dangerouslySetInnerHTML={{
                            __html: `${t(text || name)}${isRequired ? ' *' : ''}`,
                        }}
                    />
                    {warningText && <span className='label-warning-text'> ({t(warningText)})</span>}
                </label>
            ) : null}
            <InputMask
                mask="+79999999999"
                maskChar={null}
                value={value || ''}
                disabled={isDisabled}
                onChange={e => onChange(e, { value: e.target.value, name })}
                onPaste={pastHandler}
            >
                {inputProps => (
                    <Input
                        {...inputProps}
                        placeholder={placeholder}
                        list={valuesList && name}
                        className={className}
                        type={type}
                        disabled={isDisabled}
                        name={name}
                        onBlur={onBlur}
                        autoFocus={autoFocus}
                        autoComplete={autoComplete}
                        error={error}
                        required={isRequired}
                    />
                )}
            </InputMask>
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
            {valuesList && valuesList.length ? (
                <datalist id={name}>
                    {valuesList.map(item => (
                        <option key={item.id} value={item.name} />
                    ))}
                </datalist>
            ) : null}
        </Form.Field>
    )
}

export default PhoneNumber
