import React from 'react';
import { createSelector } from 'reselect';
import {downloader, postman} from '../utils/postman';
import { all, call, fork, put, select, takeEvery, delay, cancel } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { roleIdSelector, showAuctionRules } from './profile';
import { fieldsSettingSelector, getFieldsSettingSaga } from './fieldsSetting';
import { SETTINGS_TYPE_HIDE } from '../constants/formTypes';
import { errorMapping } from '../utils/errorMapping';
import {showModal} from "./modal";
import downloadFile from "../utils/downloadFile";
import {invokeActionRequest} from "./gridActions";
import {SHIPPINGS_GRID} from "../constants/grids";

//*  TYPES  *//

const OPEN_GRID_CARD_REQUEST = 'OPEN_GRID_CARD_REQUEST';
const OPEN_GRID_CARD_SUCCESS = 'OPEN_GRID_CARD_SUCCESS';
const OPEN_GRID_CARD_ERROR = 'OPEN_GRID_CARD_ERROR';

const CREATE_DRAFT_REQUEST = 'CREATE_DRAFT_REQUEST';
const CREATE_DRAFT_SUCCESS = 'CREATE_DRAFT_SUCCESS';
const CREATE_DRAFT_ERROR = 'CREATE_DRAFT_ERROR';

const GET_CARD_CONFIG_REQUEST = 'GET_CARD_CONFIG_REQUEST';
const GET_CARD_CONFIG_SUCCESS = 'GET_CARD_CONFIG_SUCCESS';
const GET_CARD_CONFIG_ERROR = 'GET_CARD_CONFIG_ERROR';

const GET_GRID_CARD_REQUEST = 'GET_GRID_CARD_REQUEST';
const GET_GRID_CARD_SUCCESS = 'GET_GRID_CARD_SUCCESS';
const GET_GRID_CARD_ERROR = 'GET_GRID_CARD_ERROR';

const EDI_GRID_CARD_REQUEST = 'EDI_GRID_CARD_REQUEST';
const EDIT_GRID_CARD_SUCCESS = 'EDIT_GRID_CARD_SUCCESS';
const EDIT_GRID_CARD_ERROR = 'EDIT_GRID_CARD_ERROR';

const IS_UNIQUE_NUMBER_REQUEST = 'IS_UNIQUE_NUMBER_REQUEST';
const IS_UNIQUE_NUMBER_SUCCESS = 'IS_UNIQUE_NUMBER_SUCCESS';
const IS_UNIQUE_NUMBER_ERROR = 'IS_UNIQUE_NUMBER_ERROR';

const ASSIGN_AUCTION_WINNER_REQUEST = 'ASSIGN_AUCTION_WINNER_REQUEST';
const ASSIGN_AUCTION_WINNER_SUCCESS = 'ASSIGN_AUCTION_WINNER_SUCCESS';
const ASSIGN_AUCTION_WINNER_ERROR = 'ASSIGN_AUCTION_WINNER_ERROR';

const AUCTION_SAVE_PRICE_REQUEST = 'AUCTION_SAVE_PRICE_REQUEST';
const AUCTION_SAVE_PRICE_SUCCESS = 'AUCTION_SAVE_PRICE_SUCCESS';
const AUCTION_SAVE_PRICE_ERROR = 'AUCTION_SAVE_PRICE_ERROR';

const AUTOUPDATE_AUCTION_INFO_REQUEST = 'AUTOUPDATE_AUCTION_INFO_REQUEST';
const AUTOUPDATE_AUCTION_INFO_SUCCESS = 'AUTOUPDATE_AUCTION_INFO_SUCCESS';
const AUTOUPDATE_AUCTION_INFO_ERROR = 'AUTOUPDATE_AUCTION_INFO_ERROR';
const STOP_AUTOUPDATE_AUCTION_INFO_REQUEST = 'STOP_AUTOUPDATE_AUCTION_INFO_REQUEST';

const CLEAR_GRID_CARD = 'CLEAR_GRID_CARD';

const ADD_ERROR = 'ADD_ERROR';
const CLEAR_ERROR = 'CLEAR_ERROR';

const REG_TC_CONFIGURATION_REQUEST = 'REG_TC_CONFIGURATION_REQUEST';
const REG_TC_CONFIGURATION_SUCCESS = 'REG_TC_CONFIGURATION_SUCCESS';
const REG_TC_CONFIGURATION_ERROR = 'REG_TC_CONFIGURATION_ERROR';

const REG_TC_REQUEST = 'REG_TC_REQUEST';
const REG_TC_SUCCESS = 'REG_TC_SUCCESS';
const REG_TC_ERROR = 'REG_TC_ERROR';

const SET_DRIVER_INN_REQUEST = 'SET_DRIVER_INN_REQUEST';
const SET_DRIVER_INN_SUCCESS = 'SET_DRIVER_INN_SUCCESS';
const SET_DRIVER_INN_ERROR = 'SET_DRIVER_INN_ERROR';

const SET_DRIVER_LICENSE_REQUEST = 'SET_DRIVER_LICENSE_REQUEST';
const SET_DRIVER_LICENSE_SUCCESS = 'SET_DRIVER_LICENSE_SUCCESS';
const SET_DRIVER_LICENSE_ERROR = 'SET_DRIVER_LICENSE_ERROR';

const SET_USER_PHONE_REQUEST = 'SET_USER_PHONE_REQUEST';
const SET_USER_PHONE_SUCCESS = 'SET_USER_PHONE_SUCCESS';
const SET_USER_PHONE_ERROR = 'SET_USER_PHONE_ERROR';

const SET_PAYMENT_TERM_REQUEST = 'SET_PAYMENT_TERM_REQUEST';
const SET_PAYMENT_TERM_SUCCESS = 'SET_PAYMENT_TERM_SUCCESS';
const SET_PAYMENT_TERM_ERROR = 'SET_PAYMENT_TERM_ERROR';

const SET_REQUIRED_FIELDS_REQUEST = 'SET_REQUIRED_FIELDS_REQUEST';
const SET_REQUIRED_FIELDS_SUCCESS = 'SET_REQUIRED_FIELDS_SUCCESS';
const SET_REQUIRED_FIELDS_ERROR = 'SET_REQUIRED_FIELDS_ERROR';

const CHECK_REQUIRED_FIELDS_REQUEST = 'CHECK_REQUIRED_FIELDS_REQUEST';
const CHECK_REQUIRED_FIELDS_SUCCESS = 'CHECK_REQUIRED_FIELDS_SUCCESS';
const CHECK_REQUIRED_FIELDS_ERROR = 'CHECK_REQUIRED_FIELDS_ERROR';

const IS_EXIST_MODEL_REQUEST = 'IS_EXIST_MODEL_REQUEST';
const IS_EXIST_MODEL_SUCCESS = 'IS_EXIST_MODEL_SUCCESS';
const IS_EXIST_MODEL_ERROR = 'IS_EXIST_MODEL_ERROR';

const SET_MODEL_REQUEST = 'SET_MODEL_REQUEST';
const SET_MODEL_SUCCESS = 'SET_MODEL_SUCCESS';
const SET_MODEL_ERROR = 'SET_MODEL_ERROR';

const CHECK_DRIVER_REQUEST = 'CHECK_DRIVER_REQUEST';
const CHECK_DRIVER_SUCCESS = 'CHECK_DRIVER_SUCCESS';
const CHECK_DRIVER_ERROR = 'CHECK_DRIVER_ERROR';

const GET_ANOMALY_PHOTOS_REQUEST = 'GET_ANOMALY_PHOTOS_REQUEST';
const GET_ANOMALY_PHOTOS_SUCCESS = 'GET_ANOMALY_PHOTOS_SUCCESS';
const GET_ANOMALY_PHOTOS_ERROR = 'GET_ANOMALY_PHOTOS_ERROR';

const DOWNLOAD_ANOMALY_PHOTO_REQUEST = 'DOWNLOAD_ANOMALY_PHOTO_REQUEST';
const DOWNLOAD_ANOMALY_PHOTO_SUCCESS = 'DOWNLOAD_ANOMALY_PHOTO_SUCCESS';
const DOWNLOAD_ANOMALY_PHOTO_ERROR = 'DOWNLOAD_ANOMALY_PHOTO_ERROR';

const DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_REQUEST = 'DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_REQUEST';
const DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_SUCCESS = 'DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_SUCCESS';
const DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_ERROR = 'DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_ERROR';

const GET_ANOMALIES_PHOTOS_REQUEST = 'GET_ANOMALIES_PHOTOS_REQUEST';
const GET_ANOMALIES_PHOTOS_SUCCESS = 'GET_ANOMALIES_PHOTOS_SUCCESS';
const GET_ANOMALIES_PHOTOS_ERROR = 'GET_ANOMALIES_PHOTOS_ERROR';

const NEW_ANOMALY_PHOTOS_REQUEST = 'NEW_ANOMALY_PHOTOS_REQUEST';
const NEW_ANOMALY_PHOTOS_SUCCESS = 'NEW_ANOMALY_PHOTOS_SUCCESS';
const NEW_ANOMALY_PHOTOS_ERROR = 'NEW_ANOMALY_PHOTOS_ERROR';

const DELETE_ANOMALY_PHOTO_REQUEST = 'DELETE_ANOMALY_PHOTO_REQUEST';
const DELETE_ANOMALY_PHOTO_SUCCESS = 'DELETE_ANOMALY_PHOTO_SUCCESS';
const DELETE_ANOMALY_PHOTO_ERROR = 'DELETE_ANOMALY_PHOTO_ERROR';

const NEW_ANOMALY_REQUEST = 'NEW_ANOMALY_REQUEST';
const NEW_ANOMALY_SUCCESS = 'NEW_ANOMALY_SUCCESS';
const NEW_ANOMALY_ERROR = 'NEW_ANOMALY_ERROR';

const DELETE_ANOMALY_REQUEST = 'DELETE_ANOMALY_REQUEST';
const DELETE_ANOMALY_SUCCESS = 'DELETE_ANOMALY_SUCCESS';
const DELETE_ANOMALY_ERROR = 'DELETE_ANOMALY_ERROR';

const SHURL_LINK_REQUEST = 'SHURL_LINK_REQUEST';
const SHURL_LINK_SUCCESS = 'SHURL_LINK_SUCCESS';
const SHURL_LINK_ERROR = 'SHURL_LINK_ERROR';

const GET_PAYMENTS_TERMS_REQUEST = 'GET_PAYMENTS_TERMS_REQUEST';
const GET_PAYMENTS_TERMS_SUCCESS = 'GET_PAYMENTS_TERMS_SUCCESS';
const GET_PAYMENTS_TERMS_ERROR = 'GET_PAYMENTS_TERMS_ERROR';

const SET_DELIVERY_TYPE_REQUEST = 'SET_DELIVERY_TYPE_REQUEST';
const SET_DELIVERY_TYPE_SUCCESS = 'SET_DELIVERY_TYPE_SUCCESS';
const SET_DELIVERY_TYPE_ERROR = 'SET_DELIVERY_TYPE_ERROR';

const GET_DEFAULT_FORM_DATA_REQUEST = 'GET_DEFAULT_FORM_DATA_REQUEST';
const GET_DEFAULT_FORM_DATA_SUCCESS = 'GET_DEFAULT_FORM_DATA_SUCCESS';
const GET_DEFAULT_FORM_DATA_ERROR = 'GET_DEFAULT_FORM_DATA_ERROR';

const GET_ANOMALIES_REQUEST = 'GET_ANOMALIES_REQUEST';
const GET_ANOMALIES_SHIPPING_SUCCESS = 'GET_ANOMALIES_SHIPPING_SUCCESS';
const GET_ANOMALIES_CLIENT_SUCCESS = 'GET_ANOMALIES_CLIENT_SUCCESS';
const GET_ANOMALIES_ERROR = 'GET_ANOMALIES_ERROR';

const SET_PHONE_MODAL = 'SET_PHONE_MODAL';
const SET_PAYMENT_TERM_MODAL = 'SET_PAYMENT_TERM_MODAL';
const SET_DELIVERY_TYPE_MODAL = 'SET_DELIVERY_TYPE_MODAL';

let task;

//*  INITIAL STATE  *//

const initial = {
    config: {},
    data: {},
    error: [],
    progress: false,
    editProgress: false,
    checkInn: true,
    checkLicense: true,
    driverLicenseNumber: null,
    checkFields: true,
    anomaliesClient: {},
    anomaliesShipping: [],
    anomaliesPhotos: [],
    link: null,
    phoneModal: {
        open: false
    },
    paymentTermModal: {
        open: false
    },
    deliveryTypeModal: {
        open: false
    },
    saveLoading: false,
    paymentTermsData: {},
    paymentTermsLoading: false,
    defaultData: {},
    checkModelResult: {
        vehicles: true,
        trailers: true
    },
    checkModelResultProgress: false,
    saveModelProgress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case GET_GRID_CARD_REQUEST:
        case CREATE_DRAFT_REQUEST:
        case GET_CARD_CONFIG_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_GRID_CARD_SUCCESS:
            return {
                ...state,
                progress: false,
                data: payload,
            };
        case CREATE_DRAFT_SUCCESS:
            return {
                ...state,
                progress: false,
                data: { id: payload.id },
            };
        case GET_CARD_CONFIG_SUCCESS:
            return {
                ...state,
                progress: false,
                config: payload,
            };
        case IS_EXIST_MODEL_REQUEST:
            return {
                ...state,
                checkModelResultProgress: true,
            };
        case IS_EXIST_MODEL_SUCCESS:
            return {
                ...state,
                checkModelResult: {
                    ...state.checkModelResult,
                    ...payload
                },
                checkModelResultProgress: false,
            };
        case IS_EXIST_MODEL_ERROR:
            return {
                ...state,
                checkModelResultProgress: false,
            };
        case GET_GRID_CARD_ERROR:
        case CREATE_DRAFT_ERROR:
        case GET_CARD_CONFIG_ERROR:
            return {
                ...state,
                data: {},
                progress: false,
            };
        case OPEN_GRID_CARD_REQUEST:
            return {
                ...state,
                progress: true,
                data: {
                    id: payload.id,
                },
            };
        case OPEN_GRID_CARD_SUCCESS:
        case OPEN_GRID_CARD_ERROR:
            return {
                ...state,
                progress: false,
            };
        case EDI_GRID_CARD_REQUEST:
        case REG_TC_REQUEST:
            return {
                ...state,
                editProgress: true,
            };
        case EDIT_GRID_CARD_SUCCESS:
        case REG_TC_SUCCESS:
            return {
                ...state,
                error: [],
                editProgress: false,
                idNew: payload
            };
        case EDIT_GRID_CARD_ERROR:
        case REG_TC_ERROR:
            return {
                ...state,
                error: payload,
                editProgress: false,
            };
        case AUCTION_SAVE_PRICE_REQUEST:
            return {
                ...state,
                editProgress: true,
            };
        case AUCTION_SAVE_PRICE_SUCCESS:
            return {
                ...state,
                error: [],
                editProgress: false,
            };
        case AUCTION_SAVE_PRICE_ERROR:
            return {
                ...state,
                error: payload,
                editProgress: false,
            };
        case IS_UNIQUE_NUMBER_SUCCESS:
            return {
                ...state,
                error: [
                    ...state.error,
                    {
                        name: payload.fieldName,
                        message: payload.errorText,
                    },
                ],
            };
        case CLEAR_GRID_CARD:
            return {
                ...state,
                error: [],
                data: {},
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: state.error ? state.error.filter(item => item.name !== payload) : [],
            };
        case ADD_ERROR:
            return {
                ...state,
                error: [...state.error, payload],
            };
        case REG_TC_CONFIGURATION_REQUEST:
            return {
                ...state,
                progress: true
            };
        case REG_TC_CONFIGURATION_ERROR:
            return {
                ...state,
                progress: false
            };
        case REG_TC_CONFIGURATION_SUCCESS:
            return {
                ...state,
                regTCConfigs: payload,
                progress: false
            };
        case SET_DRIVER_INN_REQUEST:
        case SET_DRIVER_LICENSE_REQUEST:
            return {
                ...state
            };
        case SET_DRIVER_INN_SUCCESS:
        case SET_DRIVER_LICENSE_SUCCESS:
            return {
                ...state,
                error: payload
            };
        case SET_DRIVER_INN_ERROR:
        case SET_DRIVER_LICENSE_ERROR:
            return {
                ...state,
                error: [...state.error, ...(payload || [])]
            };
        case SET_USER_PHONE_REQUEST:
        case SET_DELIVERY_TYPE_REQUEST:
            return {
                ...state,
                saveLoading: true
            };
        case SET_USER_PHONE_SUCCESS:
        case SET_DELIVERY_TYPE_SUCCESS:
            return {
                ...state,
                error: payload,
                saveLoading: false
            };
        case SET_USER_PHONE_ERROR:
        case SET_DELIVERY_TYPE_ERROR:
            return {
                ...state,
                error: payload ? [...state.error, ...payload] : state.error,
                saveLoading: false
            };
        case SET_PAYMENT_TERM_REQUEST:
            return {
                ...state,
                saveLoading: true
            };
        case SET_PAYMENT_TERM_SUCCESS:
            return {
                ...state,
                saveLoading: false
            };
        case SET_PAYMENT_TERM_ERROR:
            return {
                ...state,
                saveLoading: false
            };
        case SET_REQUIRED_FIELDS_REQUEST:
            return {
                ...state
            };
        case SET_REQUIRED_FIELDS_SUCCESS:
            return {
                ...state,
                error: payload
            };
        case SET_REQUIRED_FIELDS_ERROR:
            return {
                ...state,
                error: [...state.error, ...payload]
            };
        case CHECK_DRIVER_REQUEST:
            return {
                ...state,
                checkInn: true,
                checkLicense: true,
            };
        case CHECK_DRIVER_SUCCESS:
            return {
                ...state,
                checkInn: payload.checkInn !== undefined ? payload.checkInn : state.checkInn,
                driverLicenseNumber: payload.driverLicenseNumber,
                checkLicense: payload.checkLicense !== undefined ? payload.checkLicense : state.checkLicense,

            };
        case CHECK_DRIVER_ERROR:
            return {
                ...state,
            };
        case CHECK_REQUIRED_FIELDS_REQUEST:
            return {
                ...state,
                checkFields: true
            };
        case CHECK_REQUIRED_FIELDS_SUCCESS:
            return {
                ...state,
                checkFields: payload
            };
        case CHECK_REQUIRED_FIELDS_ERROR:
            return {
                ...state,
            };
        case GET_ANOMALY_PHOTOS_REQUEST:
            return {
                ...state,
                anomalyPhotos: [],
                anomalyPhotosLoading: true
            };
        case GET_ANOMALY_PHOTOS_SUCCESS:
            return {
                ...state,
                anomalyPhotos: payload,
                anomalyPhotosLoading: false
            };
        case GET_ANOMALY_PHOTOS_ERROR:
            return {
                ...state,
                anomalyPhotosLoading: false
            };
        case GET_ANOMALIES_REQUEST:
            return {
                ...state,
                anomaliesLoading: true
            };
        case GET_ANOMALIES_SHIPPING_SUCCESS:
            return {
                ...state,
                anomaliesShipping: payload,
                anomaliesLoading: false
            };
        case GET_ANOMALIES_CLIENT_SUCCESS:
            return {
                ...state,
                anomaliesClient: {
                    ...state.anomaliesClient,
                    [payload.id] : payload.anomalies
                },
                anomaliesLoading: false
            };
        case GET_ANOMALIES_ERROR:
            return {
                ...state,
                anomaliesLoading: false
            };
        case NEW_ANOMALY_PHOTOS_REQUEST:
        case NEW_ANOMALY_PHOTOS_SUCCESS:
        case NEW_ANOMALY_PHOTOS_ERROR:
            return {
                ...state
            };
        case NEW_ANOMALY_REQUEST:
            return {
                ...state,
                loadingSave: true
            };
        case NEW_ANOMALY_SUCCESS:
            return {
                ...state,
                loadingSave: false
            };
        case NEW_ANOMALY_ERROR:
            return {
                ...state,
                loadingSave: false
            };
        case DELETE_ANOMALY_REQUEST:
        case DELETE_ANOMALY_SUCCESS:
        case DELETE_ANOMALY_ERROR:
            return {
                ...state
            };
        case DELETE_ANOMALY_PHOTO_REQUEST:
        case DELETE_ANOMALY_PHOTO_SUCCESS:
        case DELETE_ANOMALY_PHOTO_ERROR:
            return {
                ...state
            };
        case GET_ANOMALIES_PHOTOS_REQUEST:
            return {
                ...state,
                anomalyPhotosLoading: true,
                anomaliesPhotos: []
            };
        case GET_ANOMALIES_PHOTOS_SUCCESS:
            return {
                ...state,
                anomalyPhotosLoading: false,
                anomaliesPhotos: payload
            };
        case GET_ANOMALIES_PHOTOS_ERROR:
            return {
                ...state,
                anomalyPhotosLoading: false,
                anomaliesPhotos: []
            };
        case DOWNLOAD_ANOMALY_PHOTO_REQUEST:
        case DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_REQUEST:
            return {
                ...state,
                progress: true
            };
        case DOWNLOAD_ANOMALY_PHOTO_SUCCESS:
        case DOWNLOAD_ANOMALY_PHOTO_ERROR:
        case DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_SUCCESS:
        case DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_ERROR:
            return {
                ...state,
                progress: false
            };
        case SHURL_LINK_REQUEST:
            return {
                ...state,
                progress: true
            };
        case SHURL_LINK_SUCCESS:
            return {
                ...state,
                link: payload,
                progress: false
            };
        case SHURL_LINK_ERROR:
            return {
                ...state,
                link: '/',
                progress: false
			};
        case SET_PHONE_MODAL:
            return {
                ...state,
                phoneModal: payload
            };
        case SET_PAYMENT_TERM_MODAL:
            return {
                ...state,
                paymentTermModal: payload
            };
        case SET_DELIVERY_TYPE_MODAL:
            return {
                ...state,
                deliveryTypeModal: payload
            };
        case GET_PAYMENTS_TERMS_REQUEST:
            return {
                ...state,
                paymentTermsLoading: true,
                paymentTermsData: {}
            };
        case GET_PAYMENTS_TERMS_SUCCESS:
            return {
                ...state,
                paymentTermsLoading: false,
                paymentTermsData: payload
            };
        case GET_PAYMENTS_TERMS_ERROR:
            return {
                ...state,
                paymentTermsLoading: false,
                paymentTermsData: {}
            };
        case GET_DEFAULT_FORM_DATA_REQUEST:
            return {
                ...state,
                progress: true
            };
        case GET_DEFAULT_FORM_DATA_SUCCESS:
            return {
                ...state,
                defaultData: payload,
                progress: false
            };
        case GET_DEFAULT_FORM_DATA_ERROR:
            return {
                ...state,
                progress: false
            };
        case SET_MODEL_REQUEST:
            return {
                ...state,
                saveModelProgress: true
            };
        case SET_MODEL_ERROR:
        case SET_MODEL_SUCCESS:
            return {
                ...state,
                saveModelProgress: false
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const openGridCardRequest = payload => {
    return {
        type: OPEN_GRID_CARD_REQUEST,
        payload,
    };
};

export const createDraftRequest = payload => {
    return {
        type: CREATE_DRAFT_REQUEST,
        payload,
    };
};

export const getCardRequest = payload => {
    return {
        type: GET_GRID_CARD_REQUEST,
        payload,
    };
};

export const getCardConfigRequest = payload => {
    return {
        type: GET_CARD_CONFIG_REQUEST,
        payload,
    };
};

export const editCardRequest = payload => {
    return {
        type: EDI_GRID_CARD_REQUEST,
        payload,
    };
};

export const regTCRequest = payload => {
    return {
        type: REG_TC_REQUEST,
        payload,
    };
};

export const auctionSavePriceRequest = payload => {
    return {
        type: AUCTION_SAVE_PRICE_REQUEST,
        payload,
    };
};

export const isUniqueNumberRequest = payload => {
    return {
        type: IS_UNIQUE_NUMBER_REQUEST,
        payload,
    };
};


export const clearGridCard = () => {
    return {
        type: CLEAR_GRID_CARD,
    };
};

export const clearError = payload => {
    return {
        type: CLEAR_ERROR,
        payload,
    };
};

export const addError = payload => {
    return {
        type: ADD_ERROR,
        payload,
    };
};

export const assignAuctionWinnerRequest = payload => {
    return {
        type: ASSIGN_AUCTION_WINNER_REQUEST,
        payload,
    }
};

export const startAutoUpdateAuctionInfo = payload => {
    return {
        type: AUTOUPDATE_AUCTION_INFO_REQUEST,
        payload
    }
};

export const stopAutoUpdateAuctionInfo = () => {
    return {
        type: STOP_AUTOUPDATE_AUCTION_INFO_REQUEST
    }
};

export const getRegTcConfig = () => {
    return {
        type: REG_TC_CONFIGURATION_REQUEST
    }
};

export const setDriverInn = (payload) => {
    return {
        type: SET_DRIVER_INN_REQUEST,
        payload
    }
};

export const setDriverLicense = (payload) => {
    return {
        type: SET_DRIVER_LICENSE_REQUEST,
        payload
    }
};

export const setModel = (payload) => {
    return {
        type: SET_MODEL_REQUEST,
        payload
    }
};

export const setRequiredFieldsFromModal = (payload) => {
    return {
        type: SET_REQUIRED_FIELDS_REQUEST,
        payload
    }
};

export const checkDriver = (payload) => {
    return {
        type: CHECK_DRIVER_REQUEST,
        payload
    }
};

export const checkRequiredFields = (payload) => {
    return {
        type: CHECK_REQUIRED_FIELDS_REQUEST,
        payload
    }
};

export const setCheckDriver = (payload) => {
    return {
        type: CHECK_DRIVER_SUCCESS,
        payload
    }
};

export const setCheckRequiredFields = (payload) => {
    return {
        type: CHECK_REQUIRED_FIELDS_SUCCESS,
        payload
    }
};

export const getAnomalyPhotos = (payload) => {
    return {
        type: GET_ANOMALY_PHOTOS_REQUEST,
        payload
    }
};

export const newAnomalyPhoto = (payload) => {
    return {
        type: NEW_ANOMALY_PHOTOS_REQUEST,
        payload
    }
};

export const newAnomaly = (payload) => {
    return {
        type: NEW_ANOMALY_REQUEST,
        payload
    }
};

export const deleteAnomalyPhoto = (payload) => {
    return {
        type: DELETE_ANOMALY_PHOTO_REQUEST,
        payload
    }
};

export const deleteAnomaly = (payload) => {
    return {
        type: DELETE_ANOMALY_REQUEST,
        payload
    }
};

export const getAnomalies = (payload) => {
    return {
        type: GET_ANOMALIES_REQUEST,
        payload
    }
};

export const getAnomaliesPhotos = (payload) => {
    return {
        type: GET_ANOMALIES_PHOTOS_REQUEST,
        payload
    }
};

export const setPhoneModal = (payload) => {
    return {
        type: SET_PHONE_MODAL,
        payload
    }
};

export const setPaymentTermModal = (payload) => {
    return {
        type: SET_PAYMENT_TERM_MODAL,
        payload
    }
};

export const setDeliveryTypeModal = (payload) => {
    return {
        type: SET_DELIVERY_TYPE_MODAL,
        payload
    }
};

export const downloadAnomalyPhoto = (payload) => {
    return {
        type: DOWNLOAD_ANOMALY_PHOTO_REQUEST,
        payload
    }
};

export const downloadAnomalyPhotosArchive = (payload) => {
    return {
        type: DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_REQUEST,
        payload
    }
};

export const shurlLink = (payload) => {
    return {
        type: SHURL_LINK_REQUEST,
        payload
    }
};

export const saveUserPhone = (payload) => {
    return {
        type: SET_USER_PHONE_REQUEST,
		payload
    }
};

export const saveDeliveryType = (payload) => {
    return {
        type: SET_DELIVERY_TYPE_REQUEST,
		payload
    }
};

export const setPaymentTerm = (payload) => {
    return {
        type: SET_PAYMENT_TERM_REQUEST,
		payload
    }
};

export const getPaymentTerms = (payload) => {
    return {
        type: GET_PAYMENTS_TERMS_REQUEST,
		payload
    }
};

export const getDefaultFormData = (payload) => {
    return {
        type: GET_DEFAULT_FORM_DATA_REQUEST,
		payload
    }
};

export const isExistModel = (payload) => {
    return {
        type: IS_EXIST_MODEL_REQUEST,
		payload
    }
};

export const setIsExistModel = (payload) => {
    return {
        type: IS_EXIST_MODEL_SUCCESS,
		payload
    }
};

//*  SELECTORS *//

const stateSelector = state => state.gridCard;

const gridName = (state, name) => name;

const idSelector = createSelector(stateSelector, state => state.data.id);

export const progressSelector = createSelector(stateSelector, state => state.progress);
export const checkInnDriverSelector = createSelector(stateSelector, state => state.checkInn);
export const checkLicenseDriverSelector = createSelector(stateSelector, state => state.checkLicense);
export const driverLicenseNumberSelector = createSelector(stateSelector, state => state.driverLicenseNumber);
export const checkFieldsSelector = createSelector(stateSelector, state => state.checkFields);
export const editProgressSelector = createSelector(stateSelector, state => state.editProgress);

export const cardSelector = createSelector(stateSelector, state => state.data);
export const idNewSelector = createSelector(stateSelector, state => state.idNew);
export const linkSelector = createSelector(stateSelector, state => state.link);
export const anomalyPhotosSelector = createSelector(stateSelector, state => state.anomalyPhotos || []);
export const anomaliesPhotosSelector = createSelector(stateSelector, state => state.anomaliesPhotos || []);
export const loadingSaveSelector = createSelector(stateSelector, state => state.loadingSave);
export const anomalyPhotosLoadingSelector = createSelector(stateSelector, state => state.anomalyPhotosLoading);
export const anomaliesShippingSelector = createSelector(stateSelector, state => state.anomaliesShipping || []);
export const anomaliesLoadingSelector = createSelector(stateSelector, state => state.anomaliesLoading);
export const phoneModalSelector = createSelector(stateSelector, state => state.phoneModal || {});
export const deliveryTypeModalSelector = createSelector(stateSelector, state => state.deliveryTypeModal || {});
export const paymentTermModalSelector = createSelector(stateSelector, state => state.paymentTermModal || {});
export const saveLoadingSelector = createSelector(stateSelector, state => state.saveLoading);
export const paymentTermsLoadingSelector = createSelector(stateSelector, state => state.paymentTermsLoading);
export const paymentTermsDataSelector = createSelector(stateSelector, state => state.paymentTermsData);
export const defaultFormDataSelector = createSelector(stateSelector, state => state.defaultData);
export const saveModelProgressSelector = createSelector(stateSelector, state => state.saveModelProgress);
export const checkModelResultProgressSelector = createSelector(stateSelector, state => state.checkModelResultProgress);
export const checkModelResultSelector = createSelector(
    [
        stateSelector,
        (state, name) => name
    ], (state, name) => {
        return state.checkModelResult[name];
    });

const stopperId = (state, id) => id;
export const anomaliesClientSelector = createSelector([stateSelector, stopperId], (state, stopperId) => {
    return state.anomaliesClient[stopperId] ? state.anomaliesClient[stopperId] : [];
});

export const settingsFormSelector = createSelector(
    [fieldsSettingSelector, (state, status) => status],
    (state, status) => {
        let settings = {};
        const { base = [], form = [] } = state;
        [...base, ...form].forEach(item => {
            settings = {
                ...settings,
                [item.fieldName]: item.isHidden ? SETTINGS_TYPE_HIDE : item.accessTypes[status],
            };
        });

        return settings;
    },
);

export const configRegTCSelector = createSelector(stateSelector,
        state => {
            console.log(state.regTCConfigs)
            const columns = state.regTCConfigs ? state.regTCConfigs.columns : [];
            const additionalFormColumns = state.regTCConfigs ? state.regTCConfigs.additionalFormColumns : [];
            let res = {};
            [...columns, ...additionalFormColumns].forEach(item => {
                res = {
                    ...res,
                    [item.name]: item
                }
            })
            return res
        }
);

export const settingsExtSelector = createSelector(
    [fieldsSettingSelector, (state, status) => status],
    (state, status) => {
        let settings = {};
        const { ext = [] } = state;
        ext.forEach(item => {
            settings = {
                ...settings,
                [item.fieldName]: item.isHidden ? SETTINGS_TYPE_HIDE : item.accessTypes[status],
            };
        });
        return settings;
    },
);

export const errorSelector = createSelector(stateSelector, state => errorMapping(state.error));

//*  SAGA  *//

function* openGridCardSaga({ payload }) {
    try {
        const { name, id: idRow, callbackSuccess } = payload;

        if (!idRow) {
            yield call(createDraftSaga, { payload: { name } });
        }

        const id = yield select(idSelector);

        // yield call(getCardConfigSaga, { payload: { name, id } });

        yield call(getCardSaga, { payload: { name, id } });

        const card = yield select(cardSelector);

        callbackSuccess(card);
    } catch (error) {
        yield put({
            type: OPEN_GRID_CARD_ERROR,
            payload: error,
        });
    }
}

function* createDraftSaga({ payload }) {
    try {
        const { name } = payload;
        const result = yield postman.post(`/${name}/saveOrCreate`, {});

        yield put({
            type: CREATE_DRAFT_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: CREATE_DRAFT_ERROR,
            payload: error,
        });
    }
}

function* editCardSaga({ payload }) {
    try {
        const { name, params, callbackSuccess } = payload;
        const result = yield postman.post(`/${name}/saveOrCreate`, params);

        if (result.isNeedModalForm) {
            yield put({
                type: CHECK_DRIVER_SUCCESS,
                payload: {
                    checkInn: false,
                    checkLicense: false
                }
            })
        }
        if (result.isError) {
            toast.error(<span dangerouslySetInnerHTML={{ __html: result.message }} />);
            yield put({
                type: EDIT_GRID_CARD_ERROR,
                payload: result.errors,
            });
        } else {
            yield put({
                type: EDIT_GRID_CARD_SUCCESS,
                payload: result,
            });

            callbackSuccess && callbackSuccess(result.actionTryToRun);
        }
    } catch (error) {
        yield put({
            type: EDIT_GRID_CARD_ERROR,
        });
    }
}

function* regTCCardSaga({ payload }) {
    try {
        const { params, callbackSuccess, isNew = false } = payload;
        const result = yield postman.post(`/registrationTCRequests/form${isNew ? '/registration' : ''}`, params);

        if (result.isError) {
            toast.error(<span dangerouslySetInnerHTML={{ __html: result.message }} />);
            yield put({
                type: REG_TC_ERROR,
                payload: result.errors,
            });
        } else {
            if (result.rtcNewRequestShow) {
                yield put(
                    showModal({
                        text: 'rtcNewRequestModalMessage',
                        buttons: [{text: 'rtcNewRequestModalButton', type: 1}]
                    }),
                );
            }

            yield put({
                type: REG_TC_SUCCESS,
                payload: result.id,
            });

            if (isNew) {
                window.ym(95880881,'reachGoal','formsubmitted')
            } else if (params.state === "rtcDraft") {
                window.ym(95880881,'reachGoal','formbigsubmitted')
            }

            callbackSuccess && callbackSuccess(result.actionTryToRun, result);
        }
    } catch (error) {
        yield put({
            type: REG_TC_ERROR,
        });
    }
}

function* auctionSavePriceSaga({payload}) {
    try {
        const {id, params, callbackSuccess} = payload;
        const result = yield postman.post(`/shippings/${id}/savePriceOffer`, params);

        if (result.messageType === 2) {
            yield put(
                setPhoneModal({
                    open: true,
                    oldPhone: result.title,
                    callbackAction: false
                })
            )
        } else if (result.auctionRulesShow) {
            yield put(showAuctionRules(payload));
            yield put({
                type: EDIT_GRID_CARD_ERROR,
            })
        } else if (result.isError) {
            toast.error(<span dangerouslySetInnerHTML={{__html: result.message}}/>);
            yield put({
                type: EDIT_GRID_CARD_ERROR,
                payload: result.errors,
            })
        } else {
            yield put({
                type: AUCTION_SAVE_PRICE_SUCCESS,
                payload: result,
            });
            callbackSuccess && callbackSuccess();
        }
    } catch (error) {
        yield put({
            type: AUCTION_SAVE_PRICE_ERROR,
        });
    }
}

function* getCardConfigSaga({ payload }) {
    try {
        const { name, id } = payload;
        const result = yield postman.get(`/getFormFor/${name}/${id}`);

        yield put({
            type: GET_CARD_CONFIG_SUCCESS,
            payload: result,
        });
    } catch (error) {
        yield put({
            type: GET_CARD_CONFIG_ERROR,
            payload: error,
        });
    }
}

function* getCardSaga({ payload }) {
    try {
        const { name, id, callbackSuccess, redirectCallback, isRegTC = false } = payload;
        const roleId = yield select(state => roleIdSelector(state));
        yield fork(getFieldsSettingSaga, {
            payload: {
                forEntity: name,
                roleId,
                isForm: true,
            }, isRegTC
        });
        const result = yield postman.get(!isRegTC ? `${name}/getById/${id}` : `/registrationTCRequests/form/${id}`);
        if (result.needRedirection) {
            yield put(
                showModal({
                    text: result.message,
                    callbackSuccess: redirectCallback,
                }),
            );
        } else {
            yield put({ type: GET_GRID_CARD_SUCCESS, payload: result.result });
            callbackSuccess && callbackSuccess();
        }

    } catch (error) {
        yield put({ type: GET_GRID_CARD_ERROR });
    }
}

function* isUniqueNumberSaga({ payload }) {
    try {
        const { number, fieldName, errorText, callbackSuccess } = payload;
        const result = yield postman.post('/orders/findNumber', { number, isPartial: false });

        if (result.length && result[0].name) {
            yield put({
                type: IS_UNIQUE_NUMBER_SUCCESS,
                payload: {
                    fieldName,
                    errorText,
                },
            });
        } else {
            callbackSuccess && callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: IS_UNIQUE_NUMBER_ERROR,
        });
    }
}

function* assignAuctionWinnerSaga({ payload }) {
    try {
        const {priceOfferId, cardId, shippingVersion, callbackSuccess} = payload;

        const result = yield postman.post('shippings/assignAuctionWinner', {
            priceOfferId: priceOfferId,
            shippingId: cardId,
            shippingVersion
        });

        if (result.isError) {
            toast.error(result.message)
        } else {
            callbackSuccess && callbackSuccess();
        }
    } catch (e) {

    }
}


function* autoUpdateAuctionInfoSaga({payload}) {
    if (!task) {
        task = yield fork(backgroundSyncListSaga, payload);
    }
}

function* stopAutoUpdateAuctionInfoSaga() {
    if (task) {
        yield cancel(task);
        task = null;
    }
}


export const backgroundSyncListSaga = function*(payload) {
    while (true) {
        yield delay(10 * 1000);

        try {
            const {id, callbackSuccess} = payload;

            const {result} = yield postman.get(`shippings/getByIdLightweight/${id}`);

            callbackSuccess && callbackSuccess({
                bestAuctionPriceOffer: result.bestAuctionPriceOffer,
                auctionFinishDateTime: result.auctionFinishDateTime,
            })
        } catch (e) {
            yield put({
                type: AUTOUPDATE_AUCTION_INFO_ERROR
            })
        }
    }
};


export const regTcConfigSaga = function*() {

        try {
            const res = yield postman.get(`/registrationTCRequests/form/configuration`);

            yield put({
                type: REG_TC_CONFIGURATION_SUCCESS,
                payload: res
            })

        } catch (e) {
            yield put({
                type: REG_TC_CONFIGURATION_ERROR
            })
        }
};

export const setDriverInnSaga = function*({payload}) {
        const {callbackSuccess, inn, driverId} = payload;
        try {
            const res = yield postman.post(`/transportCompanyDrivers/addInnFromShipping`, null, {params: {inn, driverId}});

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_DRIVER_INN_ERROR,
                    payload: res.errors,
                });
            }
            else {
                callbackSuccess && callbackSuccess();
                yield put({
                    type: SET_DRIVER_INN_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            yield put({
                type: SET_DRIVER_INN_ERROR
            })
        }
};

export const setDriverLicenseSaga = function*({payload}) {
        const {callbackSuccess, form, id} = payload;
        try {
            const res = yield postman.post(`/transportCompanyDrivers/addDriverLicenseFromShipping`, {...form, id});

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_DRIVER_LICENSE_ERROR,
                    payload: res.errors,
                });
            }
            else {
                callbackSuccess && callbackSuccess();
                yield put({
                    type: SET_DRIVER_LICENSE_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            yield put({
                type: SET_DRIVER_LICENSE_ERROR
            })
        }
};

export const setModelSaga = function*({payload}) {
        const {callbackSuccess, model, id, name} = payload;
        try {
            const res = yield postman.post(`/${name}/saveModel/${id}/${model}`);

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_MODEL_ERROR,
                    payload: res.errors,
                });
            }
            else {
                callbackSuccess && callbackSuccess();
                yield put({
                    type: SET_MODEL_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            yield put({
                type: SET_MODEL_ERROR
            })
        }
};

export const setUserPhoneSaga = function*({payload}) {
        const {callbackSuccess, ids, form, action} = payload;
        try {
            const res = yield postman.post(`/users/updatePhoneNumber`,{...form, ids});

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_USER_PHONE_ERROR,
                    payload: res.errors,
                });
            }
            else {
                if (action) {
                    yield put(invokeActionRequest({
                        ids,
                        name: SHIPPINGS_GRID,
                        actionName: action,
                        callbackSuccess
                    }))
                } else callbackSuccess && callbackSuccess();

                yield put({
                    type: SET_USER_PHONE_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            yield put({
                type: SET_USER_PHONE_ERROR,
            })
        }
};

export const setPaymentTermSaga = function*({payload}) {
        const {callbackSuccess, ids, form, action, name} = payload;
        try {
            const res = yield postman.post(`/${name}/reducePaymentTerms`,{...form, ids});

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_PAYMENT_TERM_ERROR,
                });
            }
            else {
                if (action) {
                    yield put(invokeActionRequest({
                        ids,
                        name,
                        actionName: action,
                        callbackSuccess
                    }))
                } else callbackSuccess && callbackSuccess();

                yield put({
                    type: SET_PAYMENT_TERM_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            toast.error('Error');
            yield put({
                type: SET_PAYMENT_TERM_ERROR,
            })
        }
};

export const setRequiredFieldsSaga = function*({payload}) {
        const {callbackSuccess, form, id} = payload;
        try {
            const res = yield postman.post(`/vehicles/addEngineAndFuelTypes`, {id, ...form});

            if (res.isError) {
                toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
                yield put({
                    type: SET_REQUIRED_FIELDS_ERROR,
                    payload: res.errors,
                });
            }
            else {
                callbackSuccess && callbackSuccess();
                yield put({
                    type: SET_REQUIRED_FIELDS_SUCCESS,
                    payload: res
                })
            }
        } catch (e) {
            yield put({
                type: SET_REQUIRED_FIELDS_ERROR
            })
        }
};

export const checkDriverSaga = function*({payload}) {

        try {
            const res = yield postman.get(`/transportCompanyDrivers/checkDriver`, {params: {driverId: payload}});

            yield put({
                type: CHECK_DRIVER_SUCCESS,
                payload: {
                    checkLicense: res.driverLicenseDate,
                    driverLicenseNumber: res.driverLicense,
                    checkInn: res.inn
                }
            })

        } catch (e) {
            yield put({
                type: CHECK_DRIVER_ERROR
            })
        }
};

export const checkRequiredFieldsSaga = function*({payload}) {

        try {
            const res = yield postman.get(`/vehicles/checkVehicle`, {params: {id: payload}});

            yield put({
                type: CHECK_REQUIRED_FIELDS_SUCCESS,
                payload: res
            })

        } catch (e) {
            yield put({
                type: CHECK_REQUIRED_FIELDS_ERROR
            })
        }
};

export const checkModelSaga = function*({payload}) {

        const {name, form} = payload;
        try {
            const res = yield postman.get(`/${name}/isExistModel`, {params: form});

            yield put({
                type: IS_EXIST_MODEL_SUCCESS,
                payload: {[name]: res}
            })

        } catch (e) {
            yield put({
                type: IS_EXIST_MODEL_ERROR
            })
        }
};

export const getAnomalyPhotosSaga = function*({payload}) {

        try {
            const res = yield postman.get(`/anomalyDocuments/getPhoto?id=${payload}`);

            yield put({
                type: GET_ANOMALY_PHOTOS_SUCCESS,
                payload: res
            })

        } catch (e) {
            yield put({
                type: GET_ANOMALY_PHOTOS_ERROR
            })
        }
};

export const getAnomaliesPhotosSaga = function*({payload}) {

        try {
            const {id, isStopper} = payload;

            const res = yield postman.get(`/anomaly/shipping/${isStopper ? 'stopper/' : ''}photos?id=${id}`);

            yield put({
                type: GET_ANOMALIES_PHOTOS_SUCCESS,
                payload: res.anomalies
            })

        } catch (e) {
            yield put({
                type: GET_ANOMALIES_PHOTOS_ERROR
            })
        }
};

export const downloadAnomalyPhotoSaga = function*({payload}) {

        try {
            const {link, id, isStopper} = payload;

            const res = yield id ? downloader.get(`/anomaly/shipping/${isStopper ? 'stopper/' : ''}photos/${id}/downloadById`, { responseType: 'blob' })
                : downloader.post(`/anomaly/shipping/${isStopper ? 'stopper/' : ''}photos/downloadByExternalLink?link=${link}`, null, { responseType: 'blob' });
            downloadFile(res);

            yield put({
                type: DOWNLOAD_ANOMALY_PHOTO_SUCCESS,
            })

        } catch (e) {
            yield put({
                type: DOWNLOAD_ANOMALY_PHOTO_ERROR
            })
        }
};

export const downloadAnomalyPhotosArchiveSaga = function*({payload}) {

        try {
            const {id, isStopper} = payload;

            const res = yield downloader.get(`/anomaly/shipping/${isStopper ? 'stopper/' : ''}photos/${id}/downloadArhive`, { responseType: 'blob' })
            downloadFile(res);

            yield put({
                type: DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_SUCCESS,
            })

        } catch (e) {
            yield put({
                type: DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_ERROR
            })
        }
};

export const newAnomalyPhotoSaga = function*({payload}) {

        try {
            const {id, document, callbackSuccess} = payload;
            const res = yield postman.post(`/anomalyDocuments/addPhoto`, null, {
                params: {
                    entityId: id,
                    fileId: document.fileId,
                    fileName: document.name
                }
            });
            res && callbackSuccess && callbackSuccess();
            yield put({
                type: NEW_ANOMALY_PHOTOS_SUCCESS,
            });

        } catch (e) {
            yield put({
                type: NEW_ANOMALY_PHOTOS_ERROR
            })
        }
};

export const newAnomalySaga = function*({payload}) {

        try {
            const {id, data, type, callbackSuccess} = payload;
            const res = yield postman.post(`/shippings/${type === 'shippingAnomalyType' ? 'addShippingAnomaly' : 'addStopperAnomaly'}?id=${id}`, data);
            res && callbackSuccess && callbackSuccess(res.result);

            yield put({
                type: NEW_ANOMALY_SUCCESS,
            });

        } catch (e) {
            yield put({
                type: NEW_ANOMALY_ERROR
            })
        }
};

export const deleteAnomalyPhotoSaga = function*({payload}) {

        try {
            const {id, callbackSuccess} = payload;
            const res = yield postman.delete(`/anomalyDocuments/Delete?documentId=${id}`);
            res && callbackSuccess && callbackSuccess();
            yield put({
                type: DELETE_ANOMALY_PHOTO_SUCCESS,
            });

        } catch (e) {
            yield put({
                type: DELETE_ANOMALY_PHOTO_ERROR
            })
        }
};

export const deleteAnomalySaga = function*({payload}) {

        try {
            const {id, callbackSuccess} = payload;
            const res = yield postman.delete(`/shippings/deleteAnomaly?id=${id}`);
            res && callbackSuccess && callbackSuccess();
            yield put({
                type: DELETE_ANOMALY_SUCCESS,
            });

        } catch (e) {
            yield put({
                type: DELETE_ANOMALY_ERROR
            })
        }
};

export const getAnomaliesSaga = function*({payload}) {

        try {
            const {id, type, callbackSuccess} = payload;
            const res = yield postman.get(`/shippings/${type === 'shippingAnomalyType' ? 'getShippingAnomalies' : 'getStopperAnomalies'}?id=${id}`);
            res && callbackSuccess && callbackSuccess();
            yield put({
                type: type === 'shippingAnomalyType' ? GET_ANOMALIES_SHIPPING_SUCCESS : GET_ANOMALIES_CLIENT_SUCCESS,
                payload: type === 'shippingAnomalyType' ? res.result : {
                    id: id,
                    anomalies: res.result
                }
            });

        } catch (e) {
            yield put({
                type: GET_ANOMALIES_ERROR
            })
        }
};

export const shurlSaga = function*({payload}) {

        try {
            const res = yield postman.get(`/shurl/${payload}`);

            yield put({
                type: SHURL_LINK_SUCCESS,
                payload: res
            });

        } catch (e) {
            yield put({
                type: SHURL_LINK_ERROR
            })
        }
};

export const getPaymentTermsSaga = function*({payload}) {
        const {ids, name} = payload;
        try {
            const res = yield postman.post(`/${name}/getPaymentTerms`, {ids});

            yield put({
                type: GET_PAYMENTS_TERMS_SUCCESS,
                payload: res
            });

        } catch (e) {
            yield put({
                type: GET_PAYMENTS_TERMS_ERROR
            })
        }
};

function* getCardDefaultValueSaga({ payload }) {
    try {
        const result = yield postman.get(`/${payload}/defaults`);

        yield put({
            type: GET_DEFAULT_FORM_DATA_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_DEFAULT_FORM_DATA_ERROR,
        });
    }
}

function* saveDeliveryTypeSaga({ payload }) {
    try {
        const {id, deliveryType, callbackSuccess} = payload;
        const res = yield postman.post(`/${SHIPPINGS_GRID}/sendToQualityDepartment`, {
            id, deliveryType
        });
        if (res.isError) {
            toast.error(<span dangerouslySetInnerHTML={{ __html: res.message }} />);
            yield put({
                type: SET_DELIVERY_TYPE_ERROR,
                payload: res.errors,
            });
        } else {
            yield put({
                type: SET_DELIVERY_TYPE_SUCCESS,
            });
            callbackSuccess && callbackSuccess();
        }
    } catch (e) {
        yield put({
            type: SET_DELIVERY_TYPE_ERROR,
        });
    }
}

export function* saga() {
    yield all([
        takeEvery(OPEN_GRID_CARD_REQUEST, openGridCardSaga),
        takeEvery(CREATE_DRAFT_REQUEST, createDraftSaga),
        takeEvery(GET_CARD_CONFIG_REQUEST, getCardConfigSaga),
        takeEvery(GET_GRID_CARD_REQUEST, getCardSaga),
        takeEvery(AUTOUPDATE_AUCTION_INFO_REQUEST, autoUpdateAuctionInfoSaga),
        takeEvery(STOP_AUTOUPDATE_AUCTION_INFO_REQUEST, stopAutoUpdateAuctionInfoSaga),
        takeEvery(EDI_GRID_CARD_REQUEST, editCardSaga),
        takeEvery(REG_TC_REQUEST, regTCCardSaga),
        takeEvery(IS_UNIQUE_NUMBER_REQUEST, isUniqueNumberSaga),
        takeEvery(ASSIGN_AUCTION_WINNER_REQUEST, assignAuctionWinnerSaga),
        takeEvery(AUCTION_SAVE_PRICE_REQUEST, auctionSavePriceSaga),
        takeEvery(REG_TC_CONFIGURATION_REQUEST, regTcConfigSaga),
        takeEvery(SET_DRIVER_INN_REQUEST, setDriverInnSaga),
        takeEvery(SET_DRIVER_LICENSE_REQUEST, setDriverLicenseSaga),
        takeEvery(CHECK_DRIVER_REQUEST, checkDriverSaga),
        takeEvery(GET_ANOMALY_PHOTOS_REQUEST, getAnomalyPhotosSaga),
        takeEvery(NEW_ANOMALY_PHOTOS_REQUEST, newAnomalyPhotoSaga),
        takeEvery(NEW_ANOMALY_REQUEST, newAnomalySaga),
        takeEvery(DELETE_ANOMALY_PHOTO_REQUEST, deleteAnomalyPhotoSaga),
        takeEvery(DELETE_ANOMALY_REQUEST, deleteAnomalySaga),
        takeEvery(GET_ANOMALIES_REQUEST, getAnomaliesSaga),
        takeEvery(SET_REQUIRED_FIELDS_REQUEST, setRequiredFieldsSaga),
        takeEvery(CHECK_REQUIRED_FIELDS_REQUEST, checkRequiredFieldsSaga),
        takeEvery(GET_ANOMALIES_PHOTOS_REQUEST, getAnomaliesPhotosSaga),
        takeEvery(DOWNLOAD_ANOMALY_PHOTO_REQUEST, downloadAnomalyPhotoSaga),
        takeEvery(DOWNLOAD_ANOMALY_PHOTOS_ARCHIVE_REQUEST, downloadAnomalyPhotosArchiveSaga),
        takeEvery(SHURL_LINK_REQUEST, shurlSaga),
        takeEvery(SET_USER_PHONE_REQUEST, setUserPhoneSaga),
        takeEvery(GET_PAYMENTS_TERMS_REQUEST, getPaymentTermsSaga),
        takeEvery(SET_PAYMENT_TERM_REQUEST, setPaymentTermSaga),
        takeEvery(GET_DEFAULT_FORM_DATA_REQUEST, getCardDefaultValueSaga),
        takeEvery(SET_DELIVERY_TYPE_REQUEST, saveDeliveryTypeSaga),
        takeEvery(IS_EXIST_MODEL_REQUEST, checkModelSaga),
        takeEvery(SET_MODEL_REQUEST, setModelSaga),
    ]);
}
