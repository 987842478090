import React, {Component, useState} from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router-dom';
import { Button, Checkbox, Loader, Table } from 'semantic-ui-react';
import BodyCell from './body_cell';
import {connect, useSelector} from 'react-redux';
import { checkForEditingRequest } from '../../../ducks/gridColumnEdit';
import { invokeMassUpdateRequest } from '../../../ducks/gridActions';
import _ from 'lodash';
import CellValue from '../../ColumnsValue';
import {ORDERS_GRID, REGISTRATION_TC_GRID, SHIPPINGS_GRID} from '../../../constants/grids';
import CustomCheckbox from "../../BaseComponents/CustomCheckbox";
import {LINK_TYPE, SELECT_WITH_COMMENT_TYPE} from "../../../constants/columnTypes";
import qs from "qs";

import {columnsFormSelector} from "../../../ducks/gridList";
import {backlightsColorsStyle} from "../../../utils/rows";

const RowGrid = ({row, indexRow, props, rowNumber, handleCheck, registryNumberClick, unloadRegistries}) => {
    const {
        columns = [],
        goToCard,
        actions,
        isShowActions,
        selectedRows,
        loadList,
        disabledCheck,
        name,
        t,
        checkForEditing,
        invokeMassUpdate,
        actionBtns,
        isReport,
        editableColumns,
        copyDisabled,
        reportWithCheckbox = false,
        isRefresh,
        openCardByCellClick,
        checkIsAvailable
    } = props;

    const location = useLocation();

    const isBacklight = () => {
        const backlightModes = [
            '0', // Перевозки документы сданы
            '1' //Перевозки документы не сданы
        ];
        const {mode} = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};
        return row.fmComments && row.fmComments !== '' && backlightModes.includes(mode);
    }

    const colors = {
        shippingManifestError: '#ff00005e',
    }
    const backlightsColors = () => backlightsColorsStyle(row, colors)

    const linkAction = (column, row) => {
        switch (column.name) {
            case 'registryNumber': return ()=>registryNumberClick(row['registryForPaymentId']);
            case 'formedRegistryLink': return ()=>unloadRegistries([row.id]);
            default: return goToCard;
        }
    }

    const formCols = useSelector(state => columnsFormSelector(state, name));

    const editColumns = (column) => {
        if (column.type !== SELECT_WITH_COMMENT_TYPE) return null;
        const columnSelect = formCols.find(c => c.name === column.name.replace('Grid', ''));
        const columnComment = formCols.find(c => c.name === column.name.replace('Grid', 'Comment'));
        return [columnSelect, columnComment];
    }

    const dontMatchWithBJBacklights = row.backlights && row.backlights.includes('dontMatchWithBJ');
    const backlights = row.backlights && row.backlights.length && !dontMatchWithBJBacklights;

    return <Table.Row
        key={row.id+indexRow}
        className={`grid-row${
            selectedRows.has(row.id) ? ' grid-row-selected' : ''
        }${row.highlightForConfirmed || backlights ? ' grid-row-marker' : ''}${(isRefresh && isBacklight()) ? ' grid-row-fm-comment' : ''} ${dontMatchWithBJBacklights ? ' grid-row-backlights' : ''}`}
        data-grid-id={row.id}
    >
        {(!isReport || reportWithCheckbox) && <Table.Cell
            key={row.id + 'checkbox'}
            className={`small-column${isReport ? '_20' : ''} ${reportWithCheckbox ? 'report-with-checkbox report-with-checkbox_white column-facet__first' : ''}`}
            onClick={e => {
                e.stopPropagation();
            }}
        >
            {!backlightsColors() && <div className={`${row.highlightForConfirmed || backlights ? 'grid-marker' : ''}`}/>}
            <CustomCheckbox
                checked={!!selectedRows.has(row.id)}
                disabled={disabledCheck(row) || (checkIsAvailable && !row.isAvailable)}
                onChange={() => {
                    handleCheck(row);
                }}
            />
        </Table.Cell>}
        {columns &&
        columns.map((column, indexColumn) => (
            <BodyCell
                isAvailable={checkIsAvailable ? row.isAvailable : true}
                editColumns={editColumns(column)}
                className={indexColumn === 0 && 'column-facet__first'}
                key={`cell_${row.id}_${column.name}`}
                value={
                    row[column.name] && typeof row[column.name] === 'object' && !Array.isArray(row[column.name])
                        ? row[column.name].value
                        : row[column.name]
                }
                valueText={
                    row[column.name] && typeof row[column.name] === 'object' && !Array.isArray(row[column.name])
                        ? row[column.name].name
                        : null
                }
                status={row.status || row.state}
                rowId={column.type === LINK_TYPE ? (column.source === SHIPPINGS_GRID || isReport) && column.source !== name ? row.shippingId : row.id : row.id}
                rowNumber={rowNumber(name, row)}
                column={column}
                indexRow={indexRow}
                indexColumn={indexColumn}
                loadList={loadList}
                gridName={name}
                goToCard={isRefresh ? linkAction(column, row) : goToCard}
                t={t}
                checkForEditing={checkForEditing}
                invokeMassUpdate={invokeMassUpdate}
                isReport={isReport}
                isRefresh={isRefresh}
                editableColumns={editableColumns}
                copyDisabled={copyDisabled}
                name={name}
                openCardByCellClick={openCardByCellClick}
                style={backlightsColors()}
            />
        ))}
        {isShowActions ? (
            actionBtns ? <Table.Cell
                className={'actions-btn'}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {
                    actionBtns && actionBtns(row.id)
                }
            </Table.Cell> : <Table.HeaderCell
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {
                    actionBtns && actionBtns(row.id)
                }
                {actions &&
                actions(row).map(action => (
                    <Button
                        key={row.id + action.name}
                        actionname={action.name}
                        actionbuttonname={action.buttonName}
                        rowid={row.id}
                        disabled={action.disabled}
                        className="grid-action-btn"
                        loading={
                            action.loadingId &&
                            action.loadingId.includes(row.id)
                        }
                        onClick={e =>
                            action.action(e, {
                                action,
                                row,
                                loadList,
                            })
                        }
                        size="mini"
                    >
                        {action.buttonName}
                    </Button>
                ))}
            </Table.HeaderCell>
        ) : null}
    </Table.Row>
};

class Result extends Component {
    handleCheck = row => {
        const { selectedRows, setSelected, onlyOneCheck } = this.props;
        let newSelectedRows;
        if (onlyOneCheck) {
            newSelectedRows = new Set();
            if (!selectedRows.has(row.id)) {
                newSelectedRows.add(row.id);
            }
            setSelected(newSelectedRows);
        } else {
            newSelectedRows = new Set(selectedRows);
            newSelectedRows[!selectedRows.has(row.id) ? 'add' : 'delete'](row.id);
            setSelected(newSelectedRows);
        }
    };

    rowNumber = (name, row) => {
        switch (name) {
            case SHIPPINGS_GRID:
                return row.shippingNumber;
            case REGISTRATION_TC_GRID:
                return row.number;
            case ORDERS_GRID:
                return row.orderNumber;
            default:
                return row.number;
        }
    }

    render() {
        const {
            rows = [],
            progress,
            isReport,
            registryNumberClick,
            unloadRegistries,
            openCardByCellClick,
            checkIsAvailable,
            useBigLoader
        } = this.props;

        return (
            <Table.Body style={{paddingLeft: isReport ? '5px' : 0}}>
                {rows && rows.map((row, indexRow) => <RowGrid
                    key={indexRow}
                    row={row}
                    indexRow={indexRow}
                    props={this.props}
                    rowNumber={this.rowNumber}
                    handleCheck={this.handleCheck}
                    registryNumberClick={registryNumberClick}
                    unloadRegistries={unloadRegistries}
                    openCardByCellClick={openCardByCellClick}
                    checkIsAvailable={checkIsAvailable}
                />)}
                <div className="table-bottom-loader">
                    <Loader active={!useBigLoader && progress && rows.length} />
                </div>
            </Table.Body>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkForEditing: params => {
            dispatch(checkForEditingRequest(params));
        },
        invokeMassUpdate: params => {
            dispatch(invokeMassUpdateRequest(params));
        },
    };
};

export default withTranslation()(
    connect(
        null,
        mapDispatchToProps,
    )(Result),
);
