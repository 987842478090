import React from 'react';
import {useTranslation} from 'react-i18next';
import './style.scss';
import {Button, Icon, Image, Message, Popup} from "semantic-ui-react";
import logo from '../../img/logo_white_background.png'

const Banner = ({id, title, btnTitle, text, url, onClose, onClick}) => {
    const {t} = useTranslation();

    const handleDismiss = () => {
        onClose(id);
    }

    const handleClick = () => {
        onClick(id);
        url && window.open(url);
    }

    return (
        <Message className='banner'>
            <Message.Content className='banner__info'>
                <div className="banner__close">
                    <Popup
                        content={t('close_banner')}
                        position="bottom right"
                        trigger={<Icon name="inverted close" size="big" onClick={handleDismiss}/>}
                    />
                </div>

                <div className='banner__image'>
                    <Image className='banner__logo' src={logo}/>
                </div>

                <div className='banner__data'>
                    <div className='banner__text'>
                        <div className='banner__header'>{title}</div>
                        <div>{text}</div>
                    </div>
                    {btnTitle && <div className='banner__info banner__btn'>
                        <Button size='large' onClick={handleClick}>
                            {btnTitle}
                        </Button>
                    </div>}
                </div>
            </Message.Content>
        </Message>
    );
};

export default Banner;
