import { Space } from 'antd-mobile';
import { List, Modal } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledSpace = styled(Space)`
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 14px;

    & > .adm-space-item:first-child {
        flex-grow: 1;
    }

    & > .adm-space-item:last-child {
        margin-right: 0;
    }
`;

export const StyledModal = styled(Modal)`
    && {
        height: 95%;
        display: flex !important;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
    }
`;

export const StyledModalHeader = styled(Modal.Header)`
    text-align: center;
    font-size: 16px;
    padding: 25px 0;
`;

export const StyledModalContent = styled(Modal.Content)`
    flex-grow: 1;
`;

export const StyledLabelContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 6px;

    padding: 0 20px;
`;

export const StyledListItem = styled(List.Item)`
    padding: 0 !important;
    margin-bottom: 8px;
    border: 1px solid #E6E6E6;
    border-radius: 6px;
`;

export const StyledListContent = styled(List.Content)`
    display: block;
    width: -webkit-fill-available;
`;

export const StyledListHeader = styled(List.Header)`
    display: flex !important;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    padding: 16px 10px 16px 20px;
`;

export const StyledListDescription = styled(List.Description)`
    border-top: 1px solid #E6E6E6;
`;
