import React from 'react';
import {Icon} from "semantic-ui-react";
import {REGISTRATION_TC_GRID} from "../../constants/grids";
import {REG_LINK} from "../../router/links";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const SupportContacts = ({text, isLoginPage}) => {
    const {t} = useTranslation();
    const location = useLocation();

    const contact = () => {
        const isRTC = location.pathname.includes(REGISTRATION_TC_GRID) || location.pathname.includes(REG_LINK);
        let email = isRTC ? 'tender-transport@fmlogistic.com' : 'ru-hmk-tra-utk@fmlogistic.com';
        return <a href={`mailto:${email}`}>{email}</a>
    }

    return (
        <div className={`header-support ${isLoginPage ? 'login-page' : ''}`}>
            <Icon name="question circle"/>
            <div className="header-support_contacts">
                <div>
                    {text && <div>{t(text)}</div>}
                    {contact()}
                    <div>{t('support_work_time')}</div>
                </div>
            </div>
        </div>
    );
};

export default SupportContacts;