import styled from 'styled-components';
import { Menu, Button } from 'semantic-ui-react';

export const StyledMenu = styled(Menu)`
    background-color: rgb(1, 69, 149);
    height: 100%;
    display: flex !important;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: stretch;

    & .item {
        color: white;
        font-size: 14px;
        font-weight: 400 !important;

        .header {
            font-weight: inherit;
        }

        .menu .item {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;

            color: white  !important;
            padding: 10px 0 !important;
            font-size: 14px !important;

            & > i.icons {
                margin-right: 16px
            }
        }
    }
`;

export const StyledButton = styled(Button)`
    margin: 0 20px 35px 20px!important;
    padding: 18px !important;
    color: white !important;
    background-color: #1458A3 !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    border-radius: 14px !important;
`;

export const StyledMenuContainer = styled.div`
    flex-grow: 1;
`;
