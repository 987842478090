import React, {useState} from 'react';
import {Button, Form} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import FormField from "../customGrid/components/formField";
import {EMAIL_TYPE, PHONE_NUMBER_TYPE, TEXT_TYPE} from "../../constants/columnTypes";
import {TC_FORM_NO_LOGGED_LINK} from "../../router/links";
import {editProgressSelector, errorSelector, regTCRequest} from "../../ducks/gridCard";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

const SignUpForm = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => editProgressSelector(state));
    const errors = useSelector(state => errorSelector(state));

    const [form, setForm] = useState({});

    const handleChange = (e, {value, name}) => {
        setForm(form => ({
            ...form,
            [name]: value
        }));
    }

    const registration = (params) => {
        dispatch(regTCRequest({
            isNew: true,
            params,
            callbackSuccess: (_, res) => {
                const url = TC_FORM_NO_LOGGED_LINK.replace(':id', res.id);
                history.push(url);
            }
        }));
    };

    const onSubmit = () => {
        registration(form)
    }

    return (
        <>
            <div className="login-descr">{t('registrationTcRequest.welcome')}</div>
            <div className="login-form">
                <Form>
                    <FormField
                        error={errors}
                        noLabel
                        name='fullName'
                        type={TEXT_TYPE}
                        form={form}
                        isRequired
                        onChange={handleChange}
                        placeholder={t('registrationTcRequest.fullName')}
                    />
                    <FormField
                        error={errors}
                        noLabel
                        name='phone'
                        type={PHONE_NUMBER_TYPE}
                        form={form}
                        isRequired
                        onChange={handleChange}
                        placeholder={t('registrationTcRequest.phone')}
                    />
                    <FormField
                        error={errors}
                        noLabel
                        name='email'
                        type={EMAIL_TYPE}
                        form={form}
                        isRequired
                        onChange={handleChange}
                        placeholder={t('registrationTcRequest.email')}
                    />
                    <div
                        className="login-buttons login-center"
                        style={{paddingTop: '5px !important'}}
                    >
                        <Button
                            onClick={onSubmit}
                            type="submit"
                            floated="right"
                            primary
                            loading={loading}
                            disabled={loading}
                        >
                            {t('next')}
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default SignUpForm;