import React, {useEffect} from 'react'
import {Dimmer, Form, Grid, Loader, Segment} from 'semantic-ui-react'
import { useDispatch, useSelector } from "react-redux"

import DocumentTC from "../customGrid/components/regTransportCompanyTabs/document";
import {
    clearDocuments,
    documentsTemplatesByParamsSelector,
    documentsTemplatesLoadingSelector,
    downloadDocumentRequest,
    getDocumentsRequest,
    getDocumentTypesRequest,
    templatesByParams
} from "../../ducks/documents";

const DocumentsWithTemplates = ({ name, form = {}, error, updateHistory }) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (form.id) {
            dispatch(templatesByParams({grid: name, form}));
            dispatch(getDocumentTypesRequest())
        }
    }, [(form.fieldNamesForTemplatesChanges || []).map(n => form[n]).toString()])

    const documentTemplates = useSelector(state => documentsTemplatesByParamsSelector(state))
    const loading = useSelector(state => documentsTemplatesLoadingSelector(state))

    const handleDownload = id => {
        dispatch(downloadDocumentRequest({ id }))
    }

    const getDocuments = () => {
        form.id && dispatch(getDocumentsRequest({gridName: name, cardId: form.id}));
    };

    useEffect(() => {
        getDocuments();
        return () => {
            dispatch(clearDocuments());
        };
    }, [form.id]);

    return (
        <Form className="tabs-card">
            <Segment vertical>
                <Dimmer active={loading} inverted>
                    <Loader size="small" />
                </Dimmer>
                <Grid doubling stackable columns={3}>
                    <Grid.Row>
                        {documentTemplates.map((doc, i) => (
                            <DocumentTC
                                key={i}
                                getDocs={getDocuments}
                                handleDownload={handleDownload}
                                typeId={doc.typeId}
                                id={form.id}
                                fileId={doc.fileId}
                                label={doc.name}
                                text={doc.description}
                                img={doc.file !== '' ? doc.file : null}
                                gridName={name}
                                updateHistory={updateHistory}
                                disabled={doc.isReadOnly}
                            />
                        ))}
                    </Grid.Row>
                </Grid>
            </Segment>
        </Form>
    );
};

export default DocumentsWithTemplates;
