import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {isAllowedFullEditSelector} from "../../../../ducks/profile";
import {NUMBER_TYPE} from "../../../../constants/columnTypes";

const AdditionalServices = ({additionalServices = [], onChange}) => {
    const {t} = useTranslation();

    const isAllowedFullEdit = useSelector(state => isAllowedFullEditSelector(state));

    const handleChange = (e, {name, value, idService}) => {
        let newAdditionalServices = [...additionalServices];
        let index = newAdditionalServices.findIndex(v => v.id === idService);
        newAdditionalServices[index] = {
            ...newAdditionalServices[index],
            [name]: value
        }
        onChange(e, {name: 'additionalServices', value: newAdditionalServices});
    };

    return (
        <Grid style={{paddingTop: '20px' }}>
            <Grid.Row columns={4}>
                <Grid.Column width="10"><label>{t('additionalService')}</label></Grid.Column>
                <Grid.Column width="3"><label>{t('quantity')}</label></Grid.Column>
                <Grid.Column width="3"><label>{t('cost')}</label></Grid.Column>
            </Grid.Row>
        {additionalServices.map((additionalService, index) => (
            <Grid.Row key={index}>
                <Grid.Column width="10">
                    <Form.Field isReadOnly>
                        <Form.Input disabled fluid value={additionalService.name} />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width="3">
                    <Form.Field>
                        <Form.Input
                            idService={additionalService.id}
                            type={NUMBER_TYPE}
                            name={'quantity'}
                            onChange={handleChange}
                            disabled={!isAllowedFullEdit}
                            fluid
                            value={additionalService.quantity}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column width="3">
                    <Form.Field>
                        <Form.Input
                            idService={additionalService.id}
                            type={NUMBER_TYPE}
                            name={'cost'}
                            onChange={handleChange}
                            disabled={!isAllowedFullEdit}
                            fluid
                            value={additionalService.cost}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        ))}
        </Grid>
    );
};

export default AdditionalServices;
