import React from 'react';

export const GavelIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_556_1892)">
            <path d="M18.5759 7.95102C17.9624 7.33746 17.0612 7.22715 16.3484 7.56836L11.5789 2.79875C11.9252 2.07164 11.7982 1.1734 11.1971 0.572228C10.4343 -0.190702 9.19289 -0.190702 8.43 0.572228L5.66289 3.3393C4.90004 4.10215 4.90004 5.34348 5.66289 6.10633C6.26254 6.70606 7.15937 6.83594 7.88941 6.48817L8.89117 7.48992L0.582106 15.799C-0.182543 16.5636 -0.18266 17.8013 0.582106 18.566C1.34675 19.3307 2.58441 19.3308 3.34918 18.566L11.6582 10.257L12.6599 11.2586C12.3135 11.9857 12.4405 12.8839 13.0417 13.4852C13.4112 13.8547 13.9026 14.0583 14.4252 14.0583C14.9479 14.0583 15.4392 13.8548 15.8087 13.4852L18.5758 10.7181C19.3387 9.9552 19.3387 8.71395 18.5759 7.95102ZM7.50765 5.18399C7.25336 5.43824 6.83957 5.43817 6.58527 5.18399C6.33101 4.92965 6.33101 4.51594 6.58527 4.26164L9.35234 1.49457C9.6066 1.24031 10.0203 1.2402 10.2747 1.49457C10.529 1.74887 10.529 2.16262 10.2747 2.41692L7.50765 5.18399ZM2.42683 17.6436C2.17191 17.8985 1.75941 17.8985 1.50449 17.6436C1.24961 17.3887 1.24957 16.9761 1.50449 16.7213L9.81355 8.41219L10.7359 9.33457L2.42683 17.6436ZM8.89117 5.64516L9.3523 5.18395L10.7359 3.80039L15.3477 8.41223L13.503 10.257L8.89117 5.64516ZM17.6536 9.79578L14.8865 12.5629C14.6316 12.8178 14.2191 12.8178 13.9641 12.5629C13.7098 12.3086 13.7098 11.8948 13.9641 11.6405L16.7312 8.8734C16.9861 8.61848 17.3987 8.61852 17.6536 8.8734C17.9085 9.12828 17.9085 9.54082 17.6536 9.79578Z" fill="white" />
            <path d="M18.0348 16.0868H12.8172C11.7383 16.0868 10.8606 16.9645 10.8606 18.0434V19.3478C10.8606 19.708 11.1526 20 11.5128 20H19.3392C19.6994 20 19.9914 19.708 19.9914 19.3478V18.0434C19.9914 16.9645 19.1137 16.0868 18.0348 16.0868ZM18.687 18.6956H12.165V18.0434C12.165 17.6838 12.4576 17.3912 12.8172 17.3912H18.0348C18.3944 17.3912 18.687 17.6838 18.687 18.0434V18.6956H18.687Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_556_1892">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const TruckIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0217 8.04347C18.3459 8.04347 18.6087 7.78068 18.6087 7.4565V5.1087C18.6087 4.78456 18.3459 4.52174 18.0217 4.52174H17.4347V2.76087C17.4347 1.78992 16.6448 1 15.6739 1H3.9348C2.96386 1 2.17394 1.78992 2.17394 2.76087V4.52174H1.58697C1.26283 4.52174 1 4.78453 1 5.1087V7.45654C1 7.78068 1.26279 8.04351 1.58697 8.04351H2.17394V12.0571C1.49068 12.2994 1.00004 12.9522 1.00004 13.7174V16.0652C1.00004 16.3894 1.26283 16.6522 1.587 16.6522H2.17397V17.8261C2.17397 18.4734 2.70057 19 3.34787 19H4.52177C5.16907 19 5.69567 18.4734 5.69567 17.8261V16.6522H13.913V17.8261C13.913 18.4734 14.4396 19 15.0869 19H16.2608C16.9081 19 17.4347 18.4734 17.4347 17.8261V16.6522H18.0217C18.3459 16.6522 18.6087 16.3894 18.6087 16.0652V13.7174C18.6087 12.9522 18.118 12.2993 17.4348 12.0571V8.04347H18.0217ZM2.17394 5.69564H4.52177V6.86954H2.17394V5.69564ZM4.52174 8.04347V11.9565H3.34784V8.04347H4.52174ZM2.17394 15.4782V13.7174C2.17394 13.3937 2.43726 13.1304 2.7609 13.1304H4.52177V15.4782C4.25202 15.4782 2.42699 15.4782 2.17394 15.4782ZM4.52174 17.8261H3.34784V16.6522H4.52198L4.52251 17.826C4.52247 17.826 4.52226 17.8261 4.52174 17.8261ZM13.913 15.4782H5.69564V9.60869H13.913V15.4782ZM5.69564 8.43476V7.4565V5.1087C5.69564 4.78506 5.95896 4.52174 6.2826 4.52174H9.21737V8.43476H5.69564ZM13.913 7.4565V8.43476H10.3913V4.52174H13.3261C13.6497 4.52174 13.913 4.78506 13.913 5.1087V7.4565ZM14.986 4.52174C14.7437 3.83855 14.0913 3.34784 13.3261 3.34784H6.2826C5.51739 3.34784 4.865 3.83858 4.6227 4.52174H3.34784V2.76087C3.34784 2.43722 3.61116 2.1739 3.9348 2.1739H15.6739C15.9976 2.1739 16.2609 2.43722 16.2609 2.76087V4.52174H14.986ZM15.0869 8.04347H16.2608V11.9565H15.0869V8.04347ZM16.2608 17.8261H15.0869V16.6522H16.2611L16.2616 17.826C16.2616 17.826 16.2614 17.8261 16.2608 17.8261ZM17.4347 15.4782C17.1777 15.4782 15.3856 15.4782 15.0869 15.4782V13.1304H16.8478C17.1714 13.1304 17.4347 13.3937 17.4347 13.7174V15.4782ZM15.0869 6.86957V5.69567H17.4348V6.86957H15.0869Z" fill="white" />
        <path d="M12.1521 10.7826H7.45644C7.1323 10.7826 6.86948 11.0454 6.86948 11.3696C6.86948 11.6937 7.13227 11.9565 7.45644 11.9565H12.1521C12.4762 11.9565 12.739 11.6937 12.739 11.3696C12.739 11.0454 12.4763 10.7826 12.1521 10.7826Z" fill="white" />
        <path d="M12.1521 13.1304H7.45644C7.1323 13.1304 6.86948 13.3932 6.86948 13.7173C6.86948 14.0415 7.13227 14.3043 7.45644 14.3043H12.1521C12.4762 14.3043 12.739 14.0415 12.739 13.7173C12.739 13.3932 12.4763 13.1304 12.1521 13.1304Z" fill="white" />
    </svg>
);

export const TrailerIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0077 2.5H1.98518C1.57517 2.5 1.24004 2.83514 1.24004 3.24515V13.9125C1.24004 14.3226 1.57517 14.6577 1.98518 14.6577H2.23832C2.00658 15.1426 1.87466 15.6845 1.87466 16.255C1.87466 18.2979 3.54679 19.9736 5.59327 19.9736C7.67541 19.9736 9.31189 18.3014 9.31189 16.255C9.31189 15.6702 9.17284 15.1141 8.9304 14.6185H15.298V18.4833H14.2962C13.8862 18.4833 13.551 18.8184 13.551 19.2284C13.551 19.6384 13.8862 19.9736 14.2962 19.9736H17.7545C18.1645 19.9736 18.4997 19.6384 18.4997 19.2284C18.4997 18.8184 18.1645 18.4833 17.7545 18.4833H16.7883V14.6185H17.9791C18.3892 14.6185 18.7243 14.2833 18.7599 13.9125V3.24515C18.7493 2.83514 18.4141 2.5 18.0077 2.5ZM5.59327 18.5189C4.33116 18.5189 3.32574 17.5171 3.32574 16.2514C3.32574 14.9857 4.32759 13.9839 5.59327 13.9839C6.85539 13.9839 7.86081 14.9857 7.86081 16.2514C7.86081 17.5171 6.85539 18.5189 5.59327 18.5189ZM17.2625 13.1317H7.60054C7.0194 12.7574 6.32773 12.5363 5.58971 12.5363C4.82673 12.5363 4.11367 12.7681 3.52183 13.1674C3.51827 13.1674 3.51114 13.1674 3.50757 13.1674H2.69112V3.95108H17.2625V13.1317Z" fill="white" />
    </svg>
);

export const MapIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_556_1972)">
            <path d="M17.75 10.9686C17.75 10.0989 17.75 7.99741 17.514 7.67005C17.278 7.34269 16.8655 7.20519 16.0405 6.93019L15.25 6.66666M17.75 14.0242C17.75 15.0999 17.75 15.6377 17.4669 15.9833C17.3707 16.1007 17.254 16.1995 17.1225 16.2751C16.735 16.4975 16.2045 16.4091 15.1434 16.2322C14.0964 16.0577 13.5729 15.9705 13.054 16.0139C12.8719 16.0292 12.691 16.0563 12.5125 16.0953C12.0038 16.2064 11.5249 16.4458 10.5671 16.9247C9.31737 17.5497 8.69245 17.8621 8.02732 17.9584C7.82697 17.9874 7.62479 18.0017 7.42235 18.0013C6.75025 18.0001 6.09317 17.7811 4.77902 17.343L4.45939 17.2365C3.63436 16.9615 3.22185 16.824 2.9859 16.4966C2.74995 16.1692 2.74995 15.7344 2.74995 14.8647V14.1667M2.74995 10.7567C2.74995 9.37424 2.74995 8.68308 3.15696 8.31131C3.22819 8.24626 3.3067 8.18968 3.39093 8.14268C3.82017 7.90319 4.38805 8.05106 5.43588 8.39583" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M14.625 9.37608C15.0296 8.41124 15.25 7.3841 15.25 6.41692C15.25 3.79342 13.0114 1.66666 10.25 1.66666C7.48853 1.66666 5.24995 3.79342 5.24995 6.41692C5.24995 9.01983 6.84578 12.0572 9.33562 13.1434C9.91604 13.3967 10.5839 13.3967 11.1643 13.1434C11.9595 12.7965 12.6635 12.2506 13.2535 11.5833" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M10.2499 8.33333C11.1704 8.33333 11.9166 7.58713 11.9166 6.66666C11.9166 5.74618 11.1704 4.99999 10.2499 4.99999C9.32947 4.99999 8.58328 5.74618 8.58328 6.66666C8.58328 7.58713 9.32947 8.33333 10.2499 8.33333Z" stroke="white" strokeWidth="1.5" />
        </g>
        <defs>
            <clipPath id="clip0_556_1972">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const HeadsetIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10 1.25C5.58172 1.25 2 4.83172 2 9.25V13.25C2 14.6307 3.11929 15.75 4.5 15.75H5.5C6.88071 15.75 8 14.6307 8 13.25V10.5C8 9.11929 6.88071 8 5.5 8H4.5C4.1786 8 3.87137 8.06065 3.58912 8.17113C4.10303 5.09474 6.7777 2.75 10 2.75C13.2223 2.75 15.897 5.09474 16.4109 8.17113C16.1286 8.06065 15.8214 8 15.5 8H14.5C13.1193 8 12 9.11929 12 10.5V13.25C12 14.6307 13.1193 15.75 14.5 15.75H15.5C15.7775 15.75 16.0444 15.7048 16.2939 15.6213C15.7473 17.2926 14.1754 18.5 12.3214 18.5H12C12 17.6716 11.3284 17 10.5 17H9.5C8.67157 17 8 17.6716 8 18.5C8 19.3284 8.67157 20 9.5 20H12.3214C15.4576 20 18 17.4576 18 14.3214V9.25C18 4.83172 14.4183 1.25 10 1.25ZM15.5 9.5C16.0523 9.5 16.5 9.94772 16.5 10.5V13.25C16.5 13.8023 16.0523 14.25 15.5 14.25H14.5C13.9477 14.25 13.5 13.8023 13.5 13.25V10.5C13.5 9.94772 13.9477 9.5 14.5 9.5H15.5ZM3.5 10.5V13.25C3.5 13.8023 3.94772 14.25 4.5 14.25H5.5C6.05228 14.25 6.5 13.8023 6.5 13.25V10.5C6.5 9.94772 6.05228 9.5 5.5 9.5H4.5C3.94772 9.5 3.5 9.94772 3.5 10.5Z" fill="white" />
    </svg>
);

export const CheckCircleIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.08105 3.79127C9.36403 3.39473 10.7383 3.40536 12.015 3.82173C12.4088 3.95016 12.8322 3.73503 12.9606 3.34123C13.089 2.94743 12.8739 2.52408 12.4801 2.39565C10.9087 1.88318 9.21721 1.87009 7.6381 2.35817C6.05899 2.84625 4.66985 3.81153 3.6617 5.12128C2.65356 6.43103 2.07591 8.02093 2.00818 9.67236C1.94046 11.3238 2.38599 12.9557 3.28352 14.3436C4.18104 15.7315 5.48648 16.8072 7.02032 17.423C8.55417 18.0387 10.2411 18.1642 11.8491 17.7821C13.4572 17.4 14.9074 16.5291 16.0003 15.2893C17.0933 14.0494 17.7753 12.5014 17.9526 10.8581C17.997 10.4463 17.6992 10.0764 17.2874 10.032C16.8755 9.98753 16.5057 10.2854 16.4612 10.6972C16.3172 12.0323 15.7631 13.29 14.8751 14.2974C13.9871 15.3047 12.8089 16.0123 11.5024 16.3227C10.1959 16.6331 8.82533 16.5312 7.57913 16.0309C6.33293 15.5307 5.2723 14.6566 4.54309 13.529C3.81388 12.4014 3.4519 11.0756 3.50692 9.73382C3.56195 8.39209 4.03127 7.10035 4.85036 6.03621C5.66944 4.97208 6.79807 4.18782 8.08105 3.79127Z" fill="white" />
        <path d="M18.1242 5.23748C18.4171 4.94459 18.4171 4.46972 18.1242 4.17682C17.8313 3.88393 17.3564 3.88393 17.0635 4.17682L10.3559 10.8845C10.0386 11.2018 9.52412 11.2018 9.20682 10.8845L6.87735 8.55497C6.58446 8.26207 6.10958 8.26207 5.81669 8.55496C5.52379 8.84785 5.52379 9.32273 5.81668 9.61562L8.14616 11.9451C9.04925 12.8482 10.5134 12.8482 11.4165 11.9451L18.1242 5.23748Z" fill="white" />
    </svg>
);

export const TimeClockIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5778 9.00012C4.05878 5.88578 6.75094 3.50146 9.99997 3.50146C13.5891 3.50146 16.4986 6.411 16.4986 10.0001C16.4986 13.5892 13.5891 16.4987 10 16.4987V17.9987C14.4175 17.9987 17.9986 14.4176 17.9986 10.0001C17.9986 5.58257 14.4175 2.00146 9.99997 2.00146C5.9211 2.00146 2.55532 5.05456 2.06324 9.00012H3.5778Z" fill="white" />
        <path d="M1.5 10.2501C1.08579 10.2501 0.75 10.5859 0.75 11.0001C0.75 11.4143 1.08579 11.7501 1.5 11.7501H3.5C3.91421 11.7501 4.25 11.4143 4.25 11.0001C4.25 10.5859 3.91421 10.2501 3.5 10.2501H1.5Z" fill="white" />
        <path d="M0.25 14.0001C0.25 13.5859 0.585786 13.2501 1 13.2501H5.5C5.91421 13.2501 6.25 13.5859 6.25 14.0001C6.25 14.4143 5.91421 14.7501 5.5 14.7501H1C0.585786 14.7501 0.25 14.4143 0.25 14.0001Z" fill="white" />
        <path d="M0.75 17.0001C0.75 16.5859 1.08579 16.2501 1.5 16.2501H8C8.41421 16.2501 8.75 16.5859 8.75 17.0001C8.75 17.4143 8.41421 17.7501 8 17.7501H1.5C1.08579 17.7501 0.75 17.4143 0.75 17.0001Z" fill="white" />
        <path d="M10.4791 6.44299C10.4791 6.02878 10.1433 5.69299 9.72906 5.69299C9.31485 5.69299 8.97906 6.02878 8.97906 6.44299V9.56634C8.97906 10.3159 9.39822 11.0026 10.0649 11.3452L12.7576 12.7289C13.126 12.9183 13.5781 12.7731 13.7675 12.4047C13.9568 12.0362 13.8116 11.5841 13.4432 11.3948L10.7505 10.0111C10.5839 9.9254 10.4791 9.75374 10.4791 9.56634V6.44299Z" fill="white" />
    </svg>
);

export const CardIdIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_556_1957)">
            <path d="M19.3571 3.75H2.64286C2.27345 3.75 1.91917 3.89675 1.65796 4.15796C1.39675 4.41917 1.25 4.77345 1.25 5.14286V16.2857C1.25 16.6551 1.39675 17.0094 1.65796 17.2706C1.91917 17.5318 2.27345 17.6786 2.64286 17.6786H19.3571C19.7266 17.6786 20.0808 17.5318 20.342 17.2706C20.6033 17.0094 20.75 16.6551 20.75 16.2857V5.14286C20.75 4.77345 20.6033 4.41917 20.342 4.15796C20.0808 3.89675 19.7266 3.75 19.3571 3.75ZM19.3571 16.2857H2.64286V5.14286H19.3571V16.2857Z" fill="white" />
            <path d="M11.25 8.75H15.625C15.7908 8.75 15.9497 8.68415 16.0669 8.56694C16.1842 8.44973 16.25 8.29076 16.25 8.125C16.25 7.95924 16.1842 7.80027 16.0669 7.68306C15.9497 7.56585 15.7908 7.5 15.625 7.5H11.25C11.0842 7.5 10.9253 7.56585 10.8081 7.68306C10.6908 7.80027 10.625 7.95924 10.625 8.125C10.625 8.29076 10.6908 8.44973 10.8081 8.56694C10.9253 8.68415 11.0842 8.75 11.25 8.75Z" fill="white" />
            <path d="M11.25 11.25H13.75C13.9158 11.25 14.0747 11.1842 14.1919 11.0669C14.3092 10.9497 14.375 10.7908 14.375 10.625C14.375 10.4592 14.3092 10.3003 14.1919 10.1831C14.0747 10.0658 13.9158 10 13.75 10H11.25C11.0842 10 10.9253 10.0658 10.8081 10.1831C10.6908 10.3003 10.625 10.4592 10.625 10.625C10.625 10.7908 10.6908 10.9497 10.8081 11.0669C10.9253 11.1842 11.0842 11.25 11.25 11.25Z" fill="white" />
            <path d="M4.375 13.125C4.54076 13.125 4.69973 13.0592 4.81694 12.9419C4.93415 12.8247 5 12.6658 5 12.5C5 12.325 5.55 11.9437 5.8 11.875H5.86875C6.12685 11.8196 6.36472 11.6942 6.55625 11.5125C6.76182 11.5605 6.97568 11.5605 7.18125 11.5125C7.37278 11.6942 7.61065 11.8196 7.86875 11.875H7.9125C8.18125 11.975 8.7125 12.3562 8.7375 12.5312C8.7375 12.697 8.80335 12.856 8.92056 12.9732C9.03777 13.0904 9.19674 13.1562 9.3625 13.1562C9.52826 13.1562 9.68723 13.0904 9.80444 12.9732C9.92165 12.856 9.9875 12.697 9.9875 12.5312C9.9875 11.425 8.50625 10.7437 8.2875 10.6562C8.64226 10.0869 8.80442 9.41859 8.75 8.75C8.72579 8.26054 8.52047 7.79757 8.17395 7.45105C7.82743 7.10453 7.36446 6.89921 6.875 6.875C6.38554 6.89921 5.92257 7.10453 5.57605 7.45105C5.22953 7.79757 5.02421 8.26054 5 8.75C4.95234 9.40816 5.11424 10.0645 5.4625 10.625C5.23125 10.7125 3.75 11.3938 3.75 12.5C3.75 12.6658 3.81585 12.8247 3.93306 12.9419C4.05027 13.0592 4.20924 13.125 4.375 13.125ZM6.875 8.125C7.03311 8.14671 7.17977 8.21954 7.29262 8.33238C7.40546 8.44523 7.47829 8.59189 7.5 8.75C7.54174 9.00663 7.52339 9.26945 7.4464 9.5178C7.3694 9.76614 7.23585 9.99324 7.05625 10.1813C7.03245 10.2051 7.0042 10.224 6.9731 10.2368C6.942 10.2497 6.90866 10.2564 6.875 10.2564C6.84134 10.2564 6.808 10.2497 6.7769 10.2368C6.7458 10.224 6.71755 10.2051 6.69375 10.1813C6.51374 9.99352 6.37992 9.76644 6.3029 9.51801C6.22588 9.26959 6.20777 9.00664 6.25 8.75C6.27171 8.59189 6.34454 8.44523 6.45738 8.33238C6.57023 8.21954 6.71689 8.14671 6.875 8.125Z" fill="white" />
        </g>
        <defs>
            <clipPath id="clip0_556_1957">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
