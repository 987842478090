import { createSelector } from 'reselect';
import { downloader, postman } from '../utils/postman';
import { all, put, takeEvery } from 'redux-saga/effects';
import {toast} from "react-toastify";
import downloadFile, {downloadBase64File} from "../utils/downloadFile";

//*  TYPES  *//

const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

const GET_DOCUMENT_TYPES_REQUEST = 'GET_DOCUMENT_TYPES_REQUEST';
const GET_DOCUMENT_TYPES_SUCCESS = 'GET_DOCUMENT_TYPES_SUCCESS';
const GET_DOCUMENT_TYPES_ERROR = 'GET_DOCUMENT_TYPES_ERROR';

const GET_DOCUMENTS_REQUEST = 'GET_DOCUMENTS_REQUEST';
const GET_DOCUMENTS_SUCCESS = 'GET_DOCUMENTS_SUCCESS';
const GET_DOCUMENTS_ERROR = 'GET_DOCUMENTS_ERROR';

const ADD_DOCUMENT_REQUEST = 'ADD_DOCUMENT_REQUEST';
const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
const ADD_DOCUMENT_ERROR = 'ADD_DOCUMENT_ERROR';

const EDIT_DOCUMENT_REQUEST = 'EDIT_DOCUMENT_REQUEST';
const EDIT_DOCUMENT_SUCCESS = 'EDIT_DOCUMENT_SUCCESS';
const EDIT_DOCUMENT_ERROR = 'EDIT_DOCUMENT_ERROR';

const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

const DOWNLOAD_DOCUMENT_REQUEST = 'DOWNLOAD_DOCUMENT_REQUEST';
const DOWNLOAD_DOCUMENT_SUCCESS = 'DOWNLOAD_DOCUMENT_SUCCESS';
const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';

const DOWNLOAD_ALL_DOCUMENTS_REQUEST = 'DOWNLOAD_ALL_DOCUMENTS_REQUEST';
const DOWNLOAD_ALL_DOCUMENTS_SUCCESS = 'DOWNLOAD_ALL_DOCUMENTS_SUCCESS';
const DOWNLOAD_ALL_DOCUMENTS_ERROR = 'DOWNLOAD_ALL_DOCUMENTS_ERROR';

const GET_DOCS_TEMPLATES_REQUEST = 'GET_DOCS_TEMPLATES_REQUEST';
const GET_DOCS_TEMPLATES_SUCCESS = 'GET_DOCS_TEMPLATES_SUCCESS';
const GET_DOCS_TEMPLATES_ERROR = 'GET_DOCS_TEMPLATES_ERROR';

const GET_DOCS_TEMPLATES_BY_PARAMS_REQUEST = 'GET_DOCS_TEMPLATES_BY_PARAMS_REQUEST';
const GET_DOCS_TEMPLATES_BY_PARAMS_SUCCESS = 'GET_DOCS_TEMPLATES_BY_PARAMS_SUCCESS';
const GET_DOCS_TEMPLATES_BY_PARAMS_ERROR = 'GET_DOCS_TEMPLATES_BY_PARAMS_ERROR';

const API_DOWNLOAD_REQUEST = 'API_DOWNLOAD_REQUEST';
const API_DOWNLOAD_SUCCESS = 'API_DOWNLOAD_SUCCESS';
const API_DOWNLOAD_ERROR = 'API_DOWNLOAD_ERROR';

const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';

//*  INITIAL STATE  *//

const initial = {
    progress: false,
    uploadProgress: false,
    addProgress: false,
    downloadFilesProgress: false,
    downloadFileProgress: false,
    documentTypes: [],
    documentTemplates: [],
    documents: [],
    documentTemplatesByParams: [],
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    switch (type) {
        case API_DOWNLOAD_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case API_DOWNLOAD_ERROR:
        case API_DOWNLOAD_SUCCESS:
            return {
                ...state,
                progress: false,
            };
        case GET_DOCUMENTS_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                documents: payload,
                progress: false,
            };
        case GET_DOCUMENTS_ERROR:
            return {
                ...state,
                progress: false,
                documents: [],
            };
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                uploadProgress: true,
            };
        case UPLOAD_FILE_ERROR:
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadProgress: false,
            };
        case GET_DOCUMENT_TYPES_SUCCESS:
            return {
                ...state,
                documentTypes: payload,
            };
        case ADD_DOCUMENT_REQUEST:
            return {
                ...state,
                addProgress: true,
            };
        case ADD_DOCUMENT_SUCCESS:
        case ADD_DOCUMENT_ERROR:
            return {
                ...state,
                addProgress: false,
            };
        case CLEAR_DOCUMENTS:
            return {
                ...state,
                documents: [],
            };
        case GET_DOCS_TEMPLATES_REQUEST:
        case GET_DOCS_TEMPLATES_BY_PARAMS_REQUEST:
            return {
                ...state,
                documentTemplatesLoading: true
            };
        case GET_DOCS_TEMPLATES_SUCCESS:
            return {
                ...state,
                documentTemplates: payload,
                documentTemplatesLoading: false
            };
        case GET_DOCS_TEMPLATES_BY_PARAMS_SUCCESS:
            return {
                ...state,
                documentTemplatesByParams: payload,
                documentTemplatesLoading: false
            };
        case GET_DOCS_TEMPLATES_BY_PARAMS_ERROR:
            return {
                ...state,
                documentTemplatesByParams: [],
                documentTemplatesLoading: false
            };
        case DOWNLOAD_ALL_DOCUMENTS_REQUEST:
            return {
                ...state,
                downloadFilesProgress: true
            };
        case DOWNLOAD_ALL_DOCUMENTS_SUCCESS:
        case DOWNLOAD_ALL_DOCUMENTS_ERROR:
            return {
                ...state,
                downloadFilesProgress: false
            };
        case GET_DOCS_TEMPLATES_ERROR:
            return {
                ...state,
                documentTemplatesLoading: false
            };
        case DOWNLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                downloadFileProgress: true
            };
        case DOWNLOAD_DOCUMENT_SUCCESS:
        case DOWNLOAD_DOCUMENT_ERROR:
            return {
                ...state,
                downloadFileProgress: false
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const uploadFileRequest = payload => {
    return {
        type: UPLOAD_FILE_REQUEST,
        payload,
    };
};

export const getDocumentTypesRequest = payload => {
    return {
        type: GET_DOCUMENT_TYPES_REQUEST,
        payload,
    };
};

export const addDocumentRequest = payload => {
    return {
        type: ADD_DOCUMENT_REQUEST,
        payload,
    };
};

export const getDocumentsRequest = payload => {
    return {
        type: GET_DOCUMENTS_REQUEST,
        payload,
    };
};

export const editDocumentRequest = payload => {
    return {
        type: EDIT_DOCUMENT_REQUEST,
        payload,
    };
};

export const deleteDocumentRequest = payload => {
    return {
        type: DELETE_DOCUMENT_REQUEST,
        payload,
    };
};

export const downloadDocumentRequest = payload => {
    return {
        type: DOWNLOAD_DOCUMENT_REQUEST,
        payload,
    };
};

export const clearDocuments = () => {
    return {
        type: CLEAR_DOCUMENTS,
    };
};

export const getTemplatesDocuments = (payload) => {
    return {
        type: GET_DOCS_TEMPLATES_REQUEST,
        payload
    };
};

export const downloadAllDocuments = (payload) => {
    return {
        type: DOWNLOAD_ALL_DOCUMENTS_REQUEST,
        payload
    };
};

export const downloadApi = (payload) => {
    return {
        type: API_DOWNLOAD_REQUEST,
        payload
    };
};

export const templatesByParams = (payload) => {
    return {
        type: GET_DOCS_TEMPLATES_BY_PARAMS_REQUEST,
        payload
    };
};

//*  SELECTORS *//

const stateSelector = state => state.documents;

export const uploadProgressSelector = createSelector(stateSelector, state => state.uploadProgress);
export const downloadFilesProgressSelector = createSelector(stateSelector, state => state.downloadFilesProgress);
export const downloadFileProgressSelector = createSelector(stateSelector, state => state.downloadFileProgress);

export const documentTypesSelector = createSelector(
    stateSelector,
    state =>
        state.documentTypes &&
        state.documentTypes.map(x => ({
            key: x.id,
            value: x.id,
            text: x.name,
        })),
);

export const documentsSelector = createSelector(
    [stateSelector, (state, id) => id],
    (state, id) => state.documents.filter(d=>id ? d.typeId === id : true));

export const documentsTemplatesSelector = createSelector(
    [stateSelector, (state, value) => value],
    (state, value) => {
        let res = state.documentTemplates;
        return res.filter(t=>t.companyOPF.toUpperCase() === value.toUpperCase());
    }
);
export const documentsTemplatesLoadingSelector = createSelector(stateSelector, state => state.documentTemplatesLoading);
export const documentsTemplatesByParamsSelector = createSelector(stateSelector, state => state.documentTemplatesByParams);
export const progressSelector = createSelector(stateSelector, state => state.progress);

//*  SAGA  *//

function* uploadFileSaga({ payload }) {
    try {
        const { form, fileName, callbackSuccess, isBase64 } = payload;
        const result = yield postman.post(`/files/${isBase64 ? 'base64' : 'upload'}`, form, {
            headers: {
                'Content-Type': isBase64 ? 'application/json-patch+json' : 'multipart/form-data',
            },
        });

        yield put({
            type: UPLOAD_FILE_SUCCESS,
            payload: {
                id: result.id,
                fileName,
            },
        });

        callbackSuccess(result.id);
    } catch (e) {
        yield put({
            type: UPLOAD_FILE_ERROR,
            payload: e,
        });
    }
}

function* getDocumentTypesSaga({ payload }) {
    try {
        const result = yield postman.post(`/documentTypes/search`, {filter: {}});

        yield put({
            type: GET_DOCUMENT_TYPES_SUCCESS,
            payload: result.items,
        });
    } catch (e) {
        yield put({
            type: GET_DOCUMENT_TYPES_ERROR,
            payload: e,
        });
    }
}

function* addDocumentSaga({ payload }) {
    try {
        const { gridName, cardId, document, callbackSuccess, noAuth } = payload;
        const result = yield postman.post(`/${gridName}${noAuth ? '/form' : ''}/${cardId}/documents`, document);

        yield put({
            type: ADD_DOCUMENT_SUCCESS,
        });

        callbackSuccess();
    } catch (e) {
        yield put({
            type: ADD_DOCUMENT_ERROR,
            payload: e,
        });
    }
}

function* getDocumentsSaga({ payload }) {
    try {
        const { gridName, cardId, noAuth } = payload;
        const result = yield postman.get(`/${gridName}${noAuth ? '/form' : ''}/${cardId}/documents`);

        yield put({
            type: GET_DOCUMENTS_SUCCESS,
            payload: result,
        });
    } catch (e) {
        yield put({
            type: GET_DOCUMENTS_ERROR,
            payload: e,
        });
    }
}

function* editDocumentSaga({ payload }) {
    try {
        const { gridName, cardId, id, document, callbackSuccess, noAuth } = payload;
        const result = yield postman.put(`/${gridName}${noAuth ? '/form' : ''}/${cardId}/documents/${id}`, document);

        yield put({
            type: EDIT_DOCUMENT_SUCCESS,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_DOCUMENT_ERROR,
            payload: e,
        });
    }
}

function* deleteDocumentSaga({ payload }) {
    try {
        const { gridName, cardId, id, callbackSuccess, noAuth } = payload;
        const result = yield postman.delete(`/${gridName}${noAuth ? '/form' : ''}/${cardId}/documents/${id}`);

        yield put({
            type: DELETE_DOCUMENT_SUCCESS,
        });
        callbackSuccess();
    } catch (e) {
        yield put({
            type: DELETE_DOCUMENT_ERROR,
            payload: e,
        });
    }
}

function* downloadDocumentSaga({ payload }) {
    try {
        const { id } = payload;
        const res = yield downloader.get(`/files/${id}/DownloadAsync`, { responseType: 'blob' });
        const { data } = res;
        let headerLine = res.headers['content-disposition'].split('; ')[2];
        // console.log('headerLine', headerLine.split('; ')[2]);
        /*  let startFileNameIndex = headerLine[2].indexOf("''");
          /!*let endFileNameIndex = headerLine[2].lastIndexOf('"');*!/*/
        let filename = decodeURI(headerLine.substring(17));
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([data], { type: data.type }));
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        yield put({
            type: DOWNLOAD_DOCUMENT_SUCCESS,
        });
    } catch (e) {
        yield put({
            type: DOWNLOAD_DOCUMENT_ERROR,
            payload: e,
        });
    }
}

function* templatesDocumentsSaga({ payload }) {
    try {
        const { isRegTC = false, grid, id } = payload;
        const res = yield postman.get(!isRegTC ? `/${grid}/templates/${id}` : `/${grid}/form/templates/${id}`);

        yield put({
            type: GET_DOCS_TEMPLATES_SUCCESS,
            payload: res
        });
    } catch (e) {
        yield put({
            type: GET_DOCS_TEMPLATES_ERROR,
            payload: e,
        });
    }
}

function* templatesDocumentsByParamsSaga({ payload }) {
    try {
        const { grid, form } = payload;
        const res = yield postman.post(`${grid}/getCustomDocumentTemplates`, form);

        yield put({
            type: GET_DOCS_TEMPLATES_BY_PARAMS_SUCCESS,
            payload: res
        });
    } catch (e) {
        yield put({
            type: GET_DOCS_TEMPLATES_BY_PARAMS_ERROR,
            payload: e,
        });
    }
}

function* downloadFilesSaga({ payload }) {
    try {
        const result = yield downloader.get(`registrationTCRequests/getAllDocuments/${payload.id}`, {
            responseType: 'blob',
        });
        downloadFile(result);
        yield put({type: DOWNLOAD_ALL_DOCUMENTS_SUCCESS})
    } catch (e) {
        yield put({
            type: DOWNLOAD_ALL_DOCUMENTS_ERROR
        })
    }
}

function* downloadApiSaga({ payload }) {
    try {
        const result = yield downloader.get(payload, {
            responseType: 'blob',
        });
        downloadFile(result);
        yield put({type: API_DOWNLOAD_SUCCESS});

    } catch (e) {
        yield put({
            type: API_DOWNLOAD_ERROR
        })
    }
}

export function* saga() {
    yield all([
        takeEvery(UPLOAD_FILE_REQUEST, uploadFileSaga),
        takeEvery(GET_DOCUMENT_TYPES_REQUEST, getDocumentTypesSaga),
        takeEvery(ADD_DOCUMENT_REQUEST, addDocumentSaga),
        takeEvery(GET_DOCUMENTS_REQUEST, getDocumentsSaga),
        takeEvery(EDIT_DOCUMENT_REQUEST, editDocumentSaga),
        takeEvery(DELETE_DOCUMENT_REQUEST, deleteDocumentSaga),
        takeEvery(DOWNLOAD_DOCUMENT_REQUEST, downloadDocumentSaga),
        takeEvery(GET_DOCS_TEMPLATES_REQUEST, templatesDocumentsSaga),
        takeEvery(GET_DOCS_TEMPLATES_BY_PARAMS_REQUEST, templatesDocumentsByParamsSaga),
        takeEvery(DOWNLOAD_ALL_DOCUMENTS_REQUEST, downloadFilesSaga),
        takeEvery(API_DOWNLOAD_REQUEST, downloadApiSaga),
    ]);
}
