import { all, put, takeEvery } from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { postman } from '../utils/postman';

//*  TYPES  *//
const GET_SECURITY_CASES_REQUEST = 'GET_SECURITY_CASES_REQUEST';
const GET_SECURITY_CASES_SUCCESS = 'GET_SECURITY_CASES_SUCCESS';
const GET_SECURITY_CASES_ERROR = 'GET_SECURITY_CASES_ERROR';

const CLEAR_SECURITY_CASES = 'CLEAR_SECURITY_CASES';

//*  INITIAL STATE  *//

const initial = {
    data: [],
    progress: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload }) => {
    console.log('exporting security cases: ' + type);
    switch (type) {
        case GET_SECURITY_CASES_REQUEST:
            return {
                ...state,
                progress: true,
            };
        case GET_SECURITY_CASES_SUCCESS:
            return {
                ...state,
                progress: false,
                data: payload,
            };
        case GET_SECURITY_CASES_ERROR:
            return {
                ...state,
                progress: false,
                data: [],
            };
        case CLEAR_SECURITY_CASES:
            return {
                ...state,
                data: [],
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getSecurityCasesRequest = payload => {
    return {
        type: GET_SECURITY_CASES_REQUEST,
        payload,
    };
};

export const clearSecurityCases = () => {
    return {
        type: CLEAR_SECURITY_CASES,
    };
};

//*  SELECTORS *//

const stateSelector = state => state.securityCasesList;

export const securityCasesSelector = createSelector(stateSelector, state => { console.log("cases: "); console.log(state); return state.data; });

export const progressSelector = createSelector(stateSelector, state => state.progress);

//*  SAGA  *//

function* getSecurityCasesSaga({ payload }) {
    try {
        const result = yield postman.get(`/case/${payload}`);
        console.log('security cases saga');
        console.log(result);
        yield put({
            type: GET_SECURITY_CASES_SUCCESS,
            payload: result.entries,
        });
    } catch (e) {
        yield put({
            type: GET_SECURITY_CASES_ERROR,
            payload: e,
        });
    }
}

export function* saga() {
    console.log('security cases saga()');
    yield all([takeEvery(GET_SECURITY_CASES_REQUEST, getSecurityCasesSaga)]);
}
