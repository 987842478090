import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Confirm, Grid, Icon} from 'semantic-ui-react';
import Anomaly from "./anomaly";
import {deleteAnomaly} from "../../../../ducks/gridCard";
import {useDispatch} from "react-redux";

const Anomalies = ({id, name, form = [], onChange, settings = {}, error, columnsConfig, typeName, callbackSuccess, disabled = false, isDelivery}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [confirmation, setConfirmation] = useState({open: false});
    const [rows, setRows] = useState(form);

    const addAnomaly = () => {
        setRows(rows => {
            return [...rows, {isNew: true}]
        });
    }

    useEffect(() => {
        form && setRows(form);
    }, [form]);

    const onConfirm = (row, index) => {
        !row.isNew ? dispatch(deleteAnomaly({
            id: row.id,
            callbackSuccess: () => setRows(rows.filter((r, idx) => idx !== index))
        })) : setRows(rows.filter((r, idx) => idx !== index));
        setConfirmation({open: false});
    }

    const onDeleteAnomaly = (row, index) => {
        setConfirmation({
            open: true,
            content: t('delete-anomaly'),
            onCancel: () => setConfirmation({open: false}),
            onConfirm: () => onConfirm(row, index),
        })
    }

    const updateRowsList = (index, row) => {
        setRows(rows => {
            let newRows = [...rows];
            newRows[index] = row;
            return newRows;
        });
        callbackSuccess && callbackSuccess();
    };

    return (
        <Grid>
            <Grid.Row>
                {!disabled && <Grid.Column>
                    <a style={{cursor: 'pointer'}} onClick={addAnomaly}>
                        <Icon name='plus'/>
                        {t('addAnomaly')}
                    </a>
                    <em className='anomaly-info-text'>({t('anomalyPhotosInfo')})</em>
                </Grid.Column>}
            </Grid.Row>
            {rows.map((r, index) =>
                <Anomaly
                    disabled={disabled}
                    id={id}
                    key={index+id}
                    columnsConfig={columnsConfig}
                    settings={settings}
                    anomaly={r}
                    typeName={typeName}
                    isDelivery={isDelivery}
                    callbackSuccess={(row) => updateRowsList(index, row)}
                    onDeleteAnomaly={() => onDeleteAnomaly(r, index)}
                />
            )}
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </Grid>
    );
};

export default Anomalies;
