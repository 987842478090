import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import { ConfigProvider, SafeArea } from 'antd-mobile';
import { Confirm, Dimmer, Loader } from 'semantic-ui-react';
import { ConnectedRouter } from 'connected-react-router';

import ruRU from 'antd-mobile/es/locales/ru-RU';

import 'semantic-ui-css/semantic.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-resizable/css/styles.css';

import {
    getUserProfile,
    userNameSelector,
} from '../ducks/profile';
import {
    isAuthSelector,
    liabilityInsurancePolicyShow,
    liabilityInsurancePolicyViewSelector,
} from '../ducks/login';
import { getVersion } from '../ducks/params';
import { history } from '../store/configureStore';
import ToastPortalContainer from '../components/ToastContainer';
import Header from '../components/Header';
import ModalInfo from '../components/ModalInfo';
import HeaderMobile from '../components/Header/mobile';
import { DICTIONARY_CARD_LINK } from '../router/links';
import MainRouteMobile from '../router/mobile';
import MainRoute from '../router';
import AuctionRulesModal from './auctionRules';

import '../style/main.scss';

const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userName = useSelector((state) => userNameSelector(state));
    const isAuth = useSelector((state) => isAuthSelector(state));
    const insurancePolicyReminder = useSelector(
        (state) => liabilityInsurancePolicyViewSelector(state),
    );

    const getProfile = () => {
        if (!userName) {
            dispatch(getUserProfile());
        }
    };

    useEffect(() => {
        getProfile();
        dispatch(getVersion());
    }, []);

    return (
        <>
            <ConnectedRouter history={history}>
                {userName || !isAuth ? (
                    <>
                        <BrowserView>
                            <Header />

                            <MainRoute />
                        </BrowserView>

                        <MobileView>
                            <ConfigProvider locale={ruRU}>
                                <SafeArea position="top" />

                                {isAuth ? <HeaderMobile /> : null}

                                <MainRouteMobile />

                                <SafeArea position="bottom" />
                            </ConfigProvider>
                        </MobileView>
                    </>
                ) : (
                    <Dimmer active inverted>
                        <Loader size="huge">Loading</Loader>
                    </Dimmer>
                )}
            </ConnectedRouter>

            <ToastPortalContainer />

            <ModalInfo />

            <AuctionRulesModal />

            <Confirm
                open={insurancePolicyReminder}
                header={t('liability-insurance-policy-modal-title')}
                content={t('liability-insurance-policy-modal-content')}
                cancelButton={t('enterLater')}
                confirmButton={t('addPolicy')}
                onCancel={() => dispatch(liabilityInsurancePolicyShow(false))}
                onConfirm={() => {
                    dispatch(liabilityInsurancePolicyShow(false));
                    history.push(`${DICTIONARY_CARD_LINK.replace(':name', 'transportCompanies').replace(':id', insurancePolicyReminder)}`);
                }}
            />
        </>
    );
};

export default App;
