import React, {useEffect, useRef, useState} from 'react';
import {useYMaps, YMaps} from '@pbe/react-yandex-maps';
import {YANDEX_MAPS_KEY} from "../../constants/settings";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {colors} from "./index";

function Map({style, mapState, mapData, setShipping, routeData, setRouteData, isTransportCompanyUser}) {
    const {t} = useTranslation();

    const [stateMap, setMap] = useState(null);
    const [els, setEls] = useState(null);
    const mapRef = useRef(null);
    const ymaps = useYMaps([
        'Map',
        'Placemark',
        "Clusterer",
        'templateLayoutFactory',
        'layout.PieChart',
        'multiRouter.MultiRoute'
    ]);

    const onClickPoint = (pointData) => {
        setShipping(pointData);
        pointData.pointType !== 2 && setRouteData(pointData)
    }

    const stopperByType = (mD) => {
        if (mD.stoppers && mD.stoppers.length) {
            switch (mD.pointType) {
                case 0:
                case 1:
                    return mD.stoppers[0];
                case 2:
                    return mD.stoppers[mD.stoppers.length - 1];
            }
        }
    }

    const setEl = (p) => {
        const el = document.getElementById('bData_' + p.properties._data.shipping.id)
        if (el) {
            el.onclick = () => onClickPoint(p.properties._data.shipping)
            el.children[0].style.outline = `2px solid ${p.properties._data.color}`;
            el.children[0].style.backgroundColor = p.properties._data.color;
        } else setTimeout(() => setEl(p), 10)
    }

    useEffect(() => {
        if (els && els.values) {
            els.values.map(p => {
                setEl(p)
            })
        }
    }, [els])

    const blue = colors.blue;
    const orange = colors.orange;
    const green = colors.green;

    const colorByType = (mD) => {
        switch (mD.pointType) {
            case 0:
            case 1:
                return blue;
            case 2:
                return orange;
        }
    }

    const balloonTemplate = (data) => {
        return `<div>${data.map(p => {
                return `<div class='b-point' id=${'bData_' + p.properties._data.shipping.id}><div class='circle-point'></div><div>${p.properties._data.iconCaption || ''}</div></div>`
            }
        ).join('')}</div>`
    }

    useEffect(() => {
        if (!ymaps || !mapRef.current) {
            return;
        }

        if (stateMap) stateMap.destroy();
        let map = new ymaps.Map(mapRef.current, mapState, {maxZoom: 18});
        setMap(map);

        if (!routeData) {
            let clusterer = new ymaps.Clusterer({
                clusterIconLayout: 'default#pieChart',
                clusterIconPieChartRadius: 25,
                clusterIconPieChartCoreRadius: 15,
                clusterIconPieChartStrokeWidth: 1,
                hasBalloon: false,
                clusterDisableClickZoom: true,
            })
            clusterer.events
                .add('click', (e) => {
                    const cluster = e.get('target');
                    if (e.get('target').options._name === 'cluster') {
                        map.balloon.open(cluster.geometry._coordinates, balloonTemplate(e.get('target').properties._data.geoObjects))
                        setEls({values: e.get('target').properties._data.geoObjects});
                    }
                })
            let points = [];
            mapData.forEach(mD => {
                const stopper = stopperByType(mD);
                const color = colorByType(mD);
                let p = new ymaps.Placemark(
                    [stopper.latitude, stopper.longitude],
                    {
                        shipping: mD,
                        color,
                        iconCaption: `№${mD.shippingNumber} ${mD.pointType !== 2 ? `- ${mD.cost} ₽` : (isTransportCompanyUser ? '' : `- ${mD.codeFmTransortCompany} | ${mD.codeFmVehicleType}`)}`,
                    }, {
                        preset: `islands#blackCircleDotIconWithCaption`,
                        iconCaptionMaxWidth: '200',
                        iconColor: color
                    })

                p.events
                    .add('click', function () {
                        onClickPoint(mD)
                    })

                points.push(p)
            })
            clusterer.add(points);
            map.geoObjects.add(clusterer);
            map.geoObjects.getBounds() && map.setBounds(map.geoObjects.getBounds())
            const zoom = map.getZoom() - 0.4;
            const maxZoom = 13;
            map.setZoom(zoom < maxZoom ? zoom : maxZoom);
        } else {
            const stoppers = routeData.stoppers || [];

            const finishColor = routeData.pointType === 2 ? orange : green;
            const coordinates = stoppers.map(stopper =>
                    [stopper.latitude, stopper.longitude]
                )

            let multiRoute = new ymaps.multiRouter.MultiRoute({
                referencePoints: coordinates,
                params: {
                    results: 2
                }
            }, {
                wayPointStartIconColor: blue,
                wayPointStartIcon: 'blue',
                wayPointFinishIconColor: finishColor,

                boundsAutoApply: true,
                zoomMargin: 12
            });
            multiRoute.model.events
                .add("requestfail", (event) => {
                    toast.error(t('route_building_error'), event.get("error").message);
                    setRouteData(null)
                });
            map.geoObjects.add(multiRoute);
            const points = multiRoute.getWayPoints();
            points.options.set('preset', `islands#blackCircleDotIconWithCaption`);
            map.geoObjects.add(new ymaps.Placemark(
                coordinates[0],
                {
                    iconCaption: routeData.cost,
                }, {
                    preset: `islands#blackCircleDotIconWithCaption`,
                    iconCaptionMaxWidth: '200',
                    iconColor: blue
                }));
        }

    }, [mapData, ymaps, routeData])

    return <div ref={mapRef} style={style}/>;
}

const YMap = ({mapData, setShipping, routeData, setRouteData, isTransportCompanyUser}) => {

    const mapState = {
        center: [55.7522, 37.6156],
        zoom: 16
    };

    const mapStyle = {
        width: '100%',
        height: "100%",
    };

    return (
        <YMaps
            query={{
                apikey: YANDEX_MAPS_KEY
            }}
        >
            <Map
                isTransportCompanyUser={isTransportCompanyUser}
                style={mapStyle}
                mapState={mapState}
                mapData={mapData}
                setShipping={setShipping}
                routeData={routeData}
                setRouteData={setRouteData}
            />
        </YMaps>
    );
}

export default YMap;