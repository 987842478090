import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Button, Confirm, Form, Grid, Icon } from "semantic-ui-react"
import Date from "../../components/BaseComponents/Date"
import MultiSelect from "../../components/BaseComponents/MultiSelectInfinityScroll"
import { dateToString } from "../../utils/dateTimeFormater"
import { useTranslation } from "react-i18next"
import List from "../customGrid/list"
import { PERSONAL_CABINET_LATENESS } from "../../constants/personalCabinetPages"
import Select_new from "../../components/BaseComponents/Select_new"
import { useDispatch, useSelector } from "react-redux"
import { latesDeleteDocsRequest } from "../../ducks/personalCabinet"
import { SHIPPINGS_GRID } from "../../constants/grids"
import Documents from "../customGrid/components/shared/documents"
import { clearFiltersData, filterSettingsSelector, getDataForReportRequest } from "../../ducks/reports"
import qs from "qs"
import { progressSelector } from "../../ducks/gridList"

const controlNames = {
    dateType: 'dateType',
    startDate: 'startDate',
    endDate: 'endDate'
}

const Lateness = ({ params: globalParams }) => {

    const { t } = useTranslation()

    const initialParams = {
        startDate: dateToString(),
        endDate: dateToString(),
        dateType: { value: "firstLoad", name: t("firstLoad") },
        transportCompanies: [],
        shippingNumbers: [],
        drivers: [],
        vehicleNumbers: [],
        fromWhere: [],
        whereTo: [],
    }

    let [params, setParams] = useState(initialParams)
    let [paramsOpen, setParamsOpen] = useState(true)
    let [click, setClick] = useState(false)
    let [height, setHeight] = useState(0)
    let [trigger, setTrigger] = useState(false)
    let [ids, setIds] = useState(null)

    const loading = useSelector(state => progressSelector(state))
    const filterSettings = useSelector(state => filterSettingsSelector(state))

    const dispatch = useDispatch()

    const location = useLocation();
    const { mode } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {}

    useEffect(() => {
        mode && dispatch(
            getDataForReportRequest({ gridName: PERSONAL_CABINET_LATENESS }),
        )
        return () => dispatch(clearFiltersData())
    }, [mode])

    useEffect(() => {
        setParams(params => {
            return {
                ...params,
                dateType: filterSettings.dateType,
                startDate: globalParams.startDate ? globalParams.startDate : (filterSettings.shippings ? null : dateToString()),
                endDate: globalParams.endDate ? globalParams.endDate : (filterSettings.shippings ? null : dateToString()),
                transportCompanies: globalParams.company ? [JSON.parse(globalParams.company)] : (filterSettings.transportCompanies || []),
                shippingNumbers: filterSettings.shippingNumbers || [],
                drivers: filterSettings.drivers || [],
                vehicleNumbers: filterSettings.vehicleNumbers || [],
                fromWhere: filterSettings.fromWhere || [],
                whereTo: filterSettings.whereTo || [],
            }
        })

    }, [filterSettings, globalParams])

    const deleteDocs = (ids) => {
        ids.length && dispatch(latesDeleteDocsRequest({
            ids, callback: () => {
                setIds(null)
                setTrigger(tr => !tr)
            }
        }))
    }

    const handleChangeParams = (e, { name, value }) => {
        setParams(params => {
            const newParams = {
                ...params,
                [name]: value,
            }

            if (name === controlNames.dateType) {
                if (value && !params.dateType) {
                    newParams[controlNames.startDate] = dateToString()
                    newParams[controlNames.endDate] = dateToString()
                } else if (!value) {
                    newParams[controlNames.startDate] = null
                    newParams[controlNames.endDate] = null
                }
            }

            return newParams
        })
    }

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight)
    }))

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container)
        else if (ro.current) {
            ro.current.disconnect()
            setHeight(0)
        }
    }, [ro.current])

    const generateReport = () => {
        setClick(true)
        setTrigger(tr => !tr)
    }

    const btn = (id) => {
        return <Documents
            id={id}
            isBtn
            btnIcon={'upload'}
            btnPopupText={'addDoc'}
            cardId={id}
            gridName={PERSONAL_CABINET_LATENESS}
            isEditPermissions={true}
            btnPopupPosition={'top right'}
            updateHistory={() => setTrigger(!trigger)}
        />
    }

    return (
        <div className="container personal-cabinet__page">
            <div className="report">
                <div ref={resize} className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'} />
                            </div>
                        </div>
                    </div>
                    {paramsOpen && mode && <Form>
                        <Grid>
                            <Grid.Row columns={5}>
                                <Grid.Column>
                                    <Select_new
                                        isTranslate
                                        name={controlNames.dateType}
                                        value={params.dateType}
                                        source={'documentReturnDateType'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.startDate}
                                        value={params.startDate}
                                        isDisabled={!params.startDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name={controlNames.endDate}
                                        value={params.endDate}
                                        isDisabled={!params.endDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="transportCompanies"
                                        source="transportCompanies/all"
                                        isDisabled={filterSettings.transportCompanies && filterSettings.transportCompanies.length && params.transportCompanies && params.transportCompanies.length}
                                        textValue={(!params.transportCompanies || params.transportCompanies.length === 0) && t('All')}
                                        value={params.transportCompanies}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="shippingNumbers"
                                        text='shippingIds'
                                        source="shippings"
                                        textValue={(!params.shippingNumbers || params.shippingNumbers.length === 0) && t('All')}
                                        value={params.shippingNumbers}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={5}>
                                <Grid.Column>
                                    <MultiSelect
                                        name="drivers"
                                        text='driverIds'
                                        source="transportCompanyDrivers"
                                        textValue={(!params.drivers || params.drivers.length === 0) && t('All')}
                                        value={params.drivers}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="vehicleNumbers"
                                        text='vehicleIds'
                                        source="vehicles"
                                        textValue={(!params.vehicleNumbers || params.vehicleNumbers.length === 0) && t('All')}
                                        value={params.vehicleNumbers}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="fromWhere"
                                        source="zones"
                                        textValue={(!params.fromWhere || params.fromWhere.length === 0) && t('All')}
                                        value={params.fromWhere}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="whereTo"
                                        source="zones"
                                        textValue={(!params.whereTo || params.whereTo.length === 0) && t('All')}
                                        value={params.whereTo}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <Button className='report_button' primary disabled={loading}
                                        onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <List
                    className='lates'
                    filters={params}
                    isReport
                    topBlockHeight={height}
                    nameReport={PERSONAL_CABINET_LATENESS}
                    trigger={trigger}
                    clickReport={click}
                    actionBtns={btn}
                    cardLink={`/grid/${SHIPPINGS_GRID}/:id`}
                    editableColumns={['tcComments', 'fmComments']}
                    isShowActions={filterSettings.isAbleFileUpload}
                    infinityScroll
                    noRepresentation
                    noModes
                    noFooter
                    reportWithCheckbox
                    customBtns={[{
                        name: 'deleteDocuments',
                        onClick: (ids) => setIds(ids),
                        icon: 'delete',
                        disabled: false
                    }]}

                />
            </div>
            <Confirm
                open={ids}
                content={t('delete-docs-confirm')}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('delete')}
                onCancel={() => setIds(null)}
                onConfirm={() => {
                    deleteDocs(ids)
                }}
            />
        </div>
    )
}

export default Lateness
