import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormField, Form, Input, Message,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
    errorSelector,
    getLoginPageRequest,
    loginPageSelector,
    loginRequest,
    progressSelector,
} from '../../../../ducks/login';
import Logo from '../../logo';
import {
    StyledLogo,
    StyledLoginWrapper,
    StyledLogin,
    StyledLoginTitle,
    StyledLoginButton,
    StyledLoginLink,
} from './styles';

const LoginMobile = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector((state) => loginPageSelector(state)) || {};
    const error = useSelector((state) => errorSelector(state));
    const loginProgress = useSelector((state) => progressSelector(state, 'login_progress'));
    const { form: formElements = {} } = page;
    const { inputs = [], login_btn = {} } = formElements;

    const [form, setForm] = useState({});

    useEffect(() => {
        dispatch(getLoginPageRequest());
    }, []);

    const handleChange = (e, { name, value }) => {
        const values = {
            ...form,
            [name]: value,
        };
        setForm(values);
    };

    const handleFormSubmit = () => {
        const params = {
            form: {
                ...form,
                language: i18n.language,
            },
            api: login_btn.api,
        };
        dispatch(loginRequest(params));
    };

    return (
        <StyledLoginWrapper>
            <StyledLogin>
                <StyledLogo>
                    <Logo />
                </StyledLogo>

                <div style={{ width: '100%' }}>
                    <StyledLoginTitle>{t('forTransportCompanies')}</StyledLoginTitle>

                    <Form error={!!error} onSubmit={handleFormSubmit}>
                        {inputs.map((input) => (
                            <FormField key={input.name}>
                                <Input
                                    error={!!error}
                                    fluid
                                    icon={input.icon}
                                    iconPosition="left"
                                    name={input.name}
                                    value={form[input.name]}
                                    placeholder={t(input.name)}
                                    type={input.type}
                                    onChange={handleChange}
                                />
                            </FormField>
                        ))}

                        <Message
                            error
                            header={t('accessError')}
                            content={t('UserNotFound')}
                        />

                        <StyledLoginButton
                            primary
                            fluid
                            loading={loginProgress}
                            size="big"
                            icon="log out"
                            type="submit"
                            content={t('sign-in')}
                        />
                    </Form>

                    <StyledLoginLink href="mailto:ru-hmk-tra-utk@fmlogistic.com">
                        {t('contactSupport')}
                    </StyledLoginLink>
                </div>
            </StyledLogin>
        </StyledLoginWrapper>
    );
};

export default LoginMobile;
