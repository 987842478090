import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { debounce } from 'throttle-debounce';
import qs from 'qs';
import './style.scss';
import Filter from './components/filter';
import HeaderSearchGrid from './components/header';
import InfiniteScrollTable from '../InfiniteScrollTable';

import Result from './components/result';
import { PAGE_SIZE } from '../../constants/settings';
import {Confirm, Dropdown, Form, Loader, Pagination, Segment} from 'semantic-ui-react';
import Footer from './components/footer';
import { withRouter } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import getGridDefaultColumnWidth from "../../utils/getGridDefaultColumnWidth";
import {setUrlSearchParam} from "../../utils/urlParamsHelper";
import {options} from "../../constants/paginationOptions";
import {FREE_TRANSPORTS_GRID, REGISTRATION_TC_GRID, SHIPPINGS_GRID} from "../../constants/grids";
import PhoneNumberModal from "../../containers/customGrid/components/shippingTabs/phoneNumberModal";
import PaymentTermModal from "../../containers/customGrid/components/shippingTabs/paymentTermModal";
import {PERSONAL_CABINET_MEETING_PROTOCOLS} from "../../constants/personalCabinetPages";
import DeliveryTypeModal from "../../containers/customGrid/components/shippingTabs/deliveryTypeModal";
import {reportsWithResizableCols} from "../../constants/reportType";

const initState = () => ({
    page: 1,
    fullText: '',
    selectedRows: new Set(),
    columns: [],
    width: 0,
    pageSize: PAGE_SIZE
});

class SuperGrid extends Component {
    constructor(props) {
        super(props);

        window.scrollTo(0, 0);

        this.idleTimer = null;

        this.state = {
            ...initState(),
        };
    }

    getDefaultColWidth = (item) => {
        const { width } = this.state;
        const { columns, t, name } = this.props;

        return getGridDefaultColumnWidth(
            width,
            columns.length,
            reportsWithResizableCols.includes(name) && t(item.displayNameKey)
        );
    }

    changeRepresentation = (isReload = false, scrollTop = false, toFirstPage = false) => {
        const { columns } = this.props;

        const newColumns = columns.map(item => ({
            ...item,
            width: item.width || this.getDefaultColWidth(item),
        }));

        this.setState(state => ({ columns: newColumns, page: toFirstPage ? 1 : state.page}), () => {
            this.loadList(false, isReload, scrollTop ? () => this.container.scrollTop = 0 : null);
        });
    };

    pageLoading = (isConcat, isReload, scrollTop, callback, preFilter = {}) => {
        const { getRepresentations, name, isReport, noRepresentation, preFilters } = this.props;
        preFilter = {...preFilter, ...preFilters};
        const onlyPreFilters = preFilters.clearOldFilters;
        getRepresentations({
            key: name,
            noRepresentation,
            callBackFunc: columns => {
                const newColumns = columns.map(item => ({
                    ...item,
                    filter: onlyPreFilters ? (preFilter && preFilter[item.name]) : ((preFilter && preFilter[item.name]) || item.filter),
                    width: item.width || this.getDefaultColWidth(item),
                }));
                this.setState(
                    {
                        columns: newColumns,
                        page: (callback && !isReload) ? 1 : this.state.page
                    },
                    () => {
                        (isReport || callback) ? (callback && callback()) : this.props.autoUpdateStart(this.mapData(isConcat, isReload, scrollTop));
                    },
                );
            },
        });
    };

    handleVisibilityChange = () => {
        const { autoUpdateStop, autoUpdateStart, startConfigUpdate, stopConfigUpdate } = this.props;

        if (document.hidden) {
            autoUpdateStop();
            stopConfigUpdate();
        } else {
            autoUpdateStart(this.mapData(false, true));
            startConfigUpdate();
        }
    };

    setMode() {
        const { location = {}, history, modes } = this.props;
        const { state = {} } = location;

        if (state) {
            this.setState(
                {
                    fullText: state.filter && state.filter.search,
                    page: state.skip && state.take ? parseInt(state.skip / state.take) : 1,
                },
                () => {
                    let search = location.search || state.search;
                    const {mode} = qs.parse(search, { ignoreQueryPrefix: true }) || {};
                    if (modes && modes.length && (mode === undefined || !modes.find(item => item.mode.toString() === mode))) {
                        search = setUrlSearchParam(location, 'mode', modes[0].mode).search;
                    }
                    history.replace({pathname: location.pathname, state: null, search});
                    this.pageLoading(false, true, () => {
                        if (state.scroll) this.container.scrollTop = state.scroll;
                    });
                },
            );
        } else {
            this.pageLoading();
        }
    }

    componentDidMount() {
        !this.props.isReport && document.addEventListener('visibilitychange', this.handleVisibilityChange);
        this.timer = null;
        const { startConfigUpdate } = this.props;

        !this.props.noRepresentation && startConfigUpdate();

        const width = this.container.offsetWidth - 65;
        this.setState({ width });

        this.setMode();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedRows } = this.state;
        const newSelectedRow = new Set(selectedRows);

        if (prevProps.zonesFilter !== this.props.zonesFilter) {
            this.setState({
                ...(this.props.zonesFilter || {})
            })
        }

        if ((prevProps.rows !== this.props.rows) || (([...selectedRows].toString() !== [...prevState.selectedRows].toString()) && !prevState.selectedRows.size)) {
            const rowsIds = this.props.allIds && this.props.allIds.length ? this.props.allIds : this.props.rows.map(item => item.id);

            for (let item of selectedRows) {
                if (!rowsIds.includes(item)) {
                    newSelectedRow.delete(item);
                }
            }

            this.setSelected(newSelectedRow);
        }

        if (prevState.selectedRows.size && !selectedRows.size && this.props.allIds) {
            this.props.clearAllIds([]);
        }

        if (this.props.location !== prevProps.location) {
            this.props.clearAllIds([]);
            this.setSelected(new Set())
            const {infinityScroll = true} = this.props;
            const {mode, registryNumber = null} = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};
            if (!mode){
                this.setMode();
            } else {
                const {mode: extMode} = qs.parse(prevProps.location.search, {ignoreQueryPrefix: true}) || {};
                if (extMode !== undefined && mode !== extMode) {
                    this.pageLoading(false, false, null, () => this.loadList(false, true, () => {
                        if (infinityScroll) {
                            this.container.scrollTop = 0;
                        }
                    }), {registryNumber});
                }
            }
        }

        if(this.props.trigger !== prevProps.trigger && prevProps.trigger !== null) {
            const isReload = this.props.trigger === null; //установленный в null trigger обновит текущую страницу без сброса номера страницы, изменение null на другое значение будет проигнорировано, чтобы не плодить лишние запросы
            const {infinityScroll = true} = this.props;
            const {registryNumber = null} = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};

            this.pageLoading(false, isReload, null, () => this.loadList(false, isReload, () => {
                if (infinityScroll && !isReload) {
                    this.container.scrollTop = 0;
                }
            }), {registryNumber});
        }

        if(this.props.location.search !== prevProps.location.search) {
            if (!this.props.infinityScroll) this.getPage(1, true);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        this.props.clearGridInfo();
    }

    mapData = (isConcat, isReload, scrollTop, registriesNumbers) => {
        const { columns, page, fullText, fromWhere = [], whereTo = [], vehicleTypes = [], pageSize } = this.state;
        const { extParams, defaultFilter, name, modes, isReport, filters: reportFilters, infinityScroll, isRefresh, noModes, preFilters = {} } = this.props;
        let filters = {};
        let sort = {};

        columns.forEach(column => {
            filters = {
                ...filters,
                [column.name]: column.filter,
            };

            if (column.sort === true || column.sort === false) {
                sort = {
                    name: column.name,
                    desc: column.sort,
                };
            }
        });

        let pSize = !infinityScroll ? pageSize : PAGE_SIZE;

        if (registriesNumbers) reportFilters['registriesNumbers'] = registriesNumbers;

        let params = {
            filter: {
                filter: {
                    ...preFilters,
                    ...filters,
                    search: fullText,
                    ...defaultFilter,
                    fromWhere: (fromWhere || []).map(z => z.value),
                    whereTo: (whereTo || []).map(z => z.value),
                    vehicleTypes: (vehicleTypes || []).map(z => z.value)
                },
                take: isReload ? page * pSize : pSize,
                skip: isReload ? 0 : (page - 1) * pSize,
                sort,
            },
            ...extParams,
            name,
            isConcat,
            scrollTop,
            isReport,
            reportFilters,
            isRefresh,
            noModes
        };

        const {mode} = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};

        if (mode !== undefined && modes.find(item => item.mode.toString() === mode.toString())) {
            params = {
                ...params,
                filter: {
                    ...params.filter,
                    filter: {
                        ...params.filter.filter,
                        gridMode: mode
                    }
                },
            }
        }

        return params;
    };

    loadList = (isConcat, isReload, scrollTop) => {
        const { autoUpdateStop, autoUpdateStart, isReport, getList, click, infinityScroll = true, noFooter } = this.props;
        const { selectedRows } = this.state;

        scrollTop && scrollTop();

        if (!isReport) {
            autoUpdateStop();
            autoUpdateStart(this.mapData(isConcat, isReload, scrollTop));
        } else {
            click && getList(this.mapData(isConcat, infinityScroll ? isReload : false, infinityScroll ? scrollTop : false));
        }

        if (selectedRows.size) {
            !noFooter && this.props.getActions({ name: this.props.name, ids: Array.from(selectedRows) });
        }
    };

    unloadRegistriesClick = (rows) => {
        this.props.unloadRegistries(this.mapData(null, null, null, Array.isArray(rows) ? rows : Array.from(this.state.selectedRows)));
    };

    nextPage = () => {
        const { totalCount, rows = [] } = this.props;

        if (rows.length < totalCount) {
            this.setState(
                prevState => ({
                    page: prevState.page + 1,
                }),
                () => this.loadList(true),
            );
        }
    };

    getPage = (pageNum, notLoad = false) => {
        const { totalCount, rows = [] } = this.props;
        if (rows.length < totalCount) {
            this.setState({
                    page: pageNum,
                },
                () => this.loadAndResetContainerScroll(notLoad)
            );
        }
    };

    setFilter = (e, { name, value }) => {
        this.setState(prevState => {
            const nextColumns = [...prevState.columns];
            let index = nextColumns.findIndex(item => item.name === name);
            nextColumns[index] = {
                ...nextColumns[index],
                filter: value,
            };

            return {
                ...prevState,
                columns: [...nextColumns],
                page: 1,
                selectedRows: new Set(),
            };
        }, this.debounceSetFilterApiAndLoadList);
    };

    setSort = sort => {
        this.setState(prevState => {
            const nextColumns = prevState.columns.map(column => ({
                ...column,
                sort: null,
            }));
            if (sort) {
                let index = nextColumns.findIndex(item => item.name === sort.name);
                nextColumns[index] = {
                    ...nextColumns[index],
                    sort: sort.desc,
                };
            }

            return {
                ...prevState,
                columns: [...nextColumns],
                page: 1,
            };
        }, this.debounceSetFilterApiAndLoadList);
    };

    setSelected = item => {
        const { noFooter } = this.props;

        this.setState(
            {
                selectedRows: item,
            },
            () => {
                !noFooter && item && item.size && this.props.getActions({ name: this.props.name, ids: Array.from(item) });
            },
        );
    };

    setSelectedAll = () => {
        const { selectedRows } = this.state;
        const { allIds = [], getAllIds, name } = this.props;
        let newSelectedRows = new Set();

        if (selectedRows.size) {
            newSelectedRows = new Set();
            this.setSelected(newSelectedRows);
        } else if (allIds && allIds.length) {
            newSelectedRows = new Set(allIds);
            this.setSelected(newSelectedRows);
        } else {
            getAllIds({
                name,
                filter: this.mapData().filter,
                callbackSuccess: ids => {
                    newSelectedRows = new Set(ids);
                    this.setSelected(newSelectedRows);
                },
            });
        }
    };

    changeFullTextFilter = (e, { value }) => {
        this.setState({ fullText: value, page: 1, selectedRows: new Set() }, this.setFilterApiAndLoadList);
    };

    clearFilters = () => {
        this.setState(prevState => {
            const { columns } = prevState;

            return {
                ...prevState,
                columns: columns.map(item => ({
                    ...item,
                    filter: '',
                })),
                page: 1,
                selectedRows: new Set(),
                fromWhere: [],
                whereTo: [],
                vehicleTypes: []
            };
        }, [SHIPPINGS_GRID, FREE_TRANSPORTS_GRID].includes(this.props.name) ? () => {
            this.props.setZonesFilter({
                key: this.props.name,
                value: {
                    fromWhere: [],
                    whereTo: [],
                    vehicleTypes: []
                },
                callbackSuccess: this.setFilterApiAndLoadList
            })
        } : this.setFilterApiAndLoadList);
    };

    clearSelectedRows = () => {
        this.setState(
            {
                selectedRows: new Set(),
            },
            () => this.loadList(false, true),
        );
    };

    setFilterApiAndLoadList = () => {
        this.editRepresentations();
        this.loadAndResetContainerScroll();
    };

    debounceSetFilterApiAndLoadList = debounce(300, this.setFilterApiAndLoadList);

    loadAndResetContainerScroll = (notLoad) => {
        !notLoad && this.loadList();
        if (this.container && this.container.scrollTop) {
            this.container.scrollTop = 0;
        }
    };

    resizeColumn = (size, index) => {
        const { columns } = this.state;

        clearTimeout(this.timer);
        this.setState(prevState => {
            const nextColumns = [...prevState.columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width,
            };
            return {
                columns: nextColumns,
            };
        });

        let sum = 0;

        columns.forEach(item => {
            sum = sum + item.width + columns.length + 50;
        });

        this.timer = setTimeout(() => {
            this.props.representationName && this.editRepresentations();
        }, 2000);
    };

    editRepresentations = () => {
        const { editRepresentation, representationName, name, editDefaultRepresentations, representationsIsLocked } = this.props;
        const { columns } = this.state;

        if (representationName && !representationsIsLocked) {
            editRepresentation({
                key: name,
                name: representationName,
                oldName: representationName,
                value: columns,
                callbackSuccess: () => {
                    //getRepresentations({key: name});
                },
            });
        } else {
            const {mode} = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }) || {};

            editDefaultRepresentations({
                key: name,
                value: columns,
                mode,
            })
        }
    };

    handleGoToCard = (isEdit, id, source, newWindow = false, value) => {
        const { history, cardLink, newLink, name, location, isReport, cardLinkFunc } = this.props;

        let data = isReport ? cardLink.replace(':name', source).replace(':id', id) : {
            pathname: isEdit
                ? cardLink.replace(':name', source).replace(':id', id)
                : newLink.replace(':name', source),
            state: {
                ...this.mapData().filter,
                search: location.search,
                scroll: this.container.scrollTop,
                pathname: history.location.pathname,
            },
        };

        const open = () => newWindow ? window.open(data) : history.push(data);

        cardLinkFunc ? cardLinkFunc(value, open) : open();
    };

    onAction = e => {};

    onActive = e => {
        console.log('user is active', e);
        console.log('time remaining', this.idleTimer.getRemainingTime());

        const { autoUpdateStart } = this.props;
        autoUpdateStart(this.mapData(false, true));
    };

    onIdle = e => {
        console.log('user is idle');
        console.log('last active', this.idleTimer.getLastActiveTime());

        const { autoUpdateStop } = this.props;
        autoUpdateStop();
    };

    registryNumberClick = (number) => {
        const { location = {}, history } = this.props;
        history.replace({
            ...setUrlSearchParam(setUrlSearchParam(setUrlSearchParam(location, 'registryNumber', number), 'clearOldFilters', true), 'mode', 4)
        });
    }

    setZoneRequestAndLoad = debounce(500, () => {
        const {setZonesFilter, name: gridName} = this.props;
        const {fromWhere = [], whereTo = [], vehicleTypes = []} = this.state;

        setZonesFilter({
            key: gridName,
            value: {
                fromWhere,
                whereTo,
                vehicleTypes
            },
            callbackSuccess: this.loadAndResetContainerScroll
        })
    });

    setZoneFilter = (e, {name, value}) => {
        this.setState({
            [name]: value, page: 1
        }, this.setZoneRequestAndLoad);
    }

    render() {
        const { fullText, selectedRows, columns, width, fromWhere = null, whereTo = null, vehicleTypes = null, page } = this.state;
        const {
            totalCount: count = 0,
            rows = [],
            progress,
            catalogsFromGrid,
            actions,
            isShowActions,
            confirmation = {},
            closeConfirmation = () => {},
            groupActions,
            isCreateBtn,
            extGrid,
            onlyOneCheck,
            checkAllDisabled,
            disabledCheck,
            storageRepresentationItems,
            name,
            representationName,
            modes,
            t,
            bannerHeight = 0,
            isReport,
            click,
            actionBtns,
            editableColumns,
            copyDisabled = true,
            infinityScroll = true,
            reportWithCheckbox = false,
            generateBtn,
            isRefresh,
            filters,
            representationsIsLocked,
            noRepresentation,
            noModes,
            noFooter,
            customBtns,
            openCardByCellClick,
            checkIsAvailable,
            useBigLoader
        } = this.props;

        const noPrint = isReport || [PERSONAL_CABINET_MEETING_PROTOCOLS, REGISTRATION_TC_GRID, FREE_TRANSPORTS_GRID].includes(name);
        const isResizable = !isReport || reportsWithResizableCols.includes(name);

        return (
            <>
                {!isReport && <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={1000 * 60 * 5}
                />}
                <Loader active={(useBigLoader && progress) || (!useBigLoader && progress && !rows.length)} size="huge" className="table-loader">
                    Loading
                </Loader>
                <div ref={(headerElement) => { this.headerElement = headerElement}}>
                    <HeaderSearchGrid
                        newTypeLayout={reportWithCheckbox}
                        isReport={isReport}
                        isCreateBtn={isCreateBtn}
                        goToCard={this.handleGoToCard}
                        name={name}
                        loadList={this.loadList}
                        unloadRegistries={this.unloadRegistriesClick}
                        width={width}
                        pageLoading={(!isReport || click) && this.changeRepresentation}
                        searchValue={fullText}
                        searchOnChange={this.changeFullTextFilter}
                        counter={count}
                        storageRepresentationItems={storageRepresentationItems}
                        disabledClearFilter={!columns.find(column => column.filter) && (!fromWhere || fromWhere.length === 0) && (!whereTo || whereTo.length === 0) && (!vehicleTypes || vehicleTypes.length === 0)}
                        representationName={representationName}
                        clearFilter={this.clearFilters}
                        filter={this.mapData()}
                        setSelected={this.setSelected}
                        setFilter={this.setZoneFilter}
                        modes={modes}
                        selectedRows={selectedRows}
                        fromWhere={fromWhere}
                        whereTo={whereTo}
                        vehicleTypes={vehicleTypes}
                        generateBtn={generateBtn}
                        isRefresh={isRefresh}
                        representationsIsLocked={representationsIsLocked}
                        noRepresentation={noRepresentation}
                        noModes={noModes}
                        customBtns={customBtns}
                        noPrint={noPrint}
                    />
                </div>
                <div
                    className={`scroll-grid-container${extGrid ? ' grid_small' : ''} ${(isReport && !click) ? 'grid_empty' : ''}`}
                    style={{height: `calc(100vh - ${(this.headerElement ? this.headerElement.clientHeight : 0)+ 93 + bannerHeight + (!infinityScroll ? 70 : 0)}px)`}}
                    ref={instance => {
                        this.container = instance;
                    }}
                >
                    {(!isReport || click) && <InfiniteScrollTable
                        className={`grid-table ${!isResizable ? 'grid-table_report' : ''}`}
                        unstackable
                        celled
                        selectable={false}
                        columns={columns}
                        fixed
                        headerRow={
                            <Filter
                                isResizable={isResizable}
                                actionBtns={actionBtns}
                                columns={columns}
                                indeterminate={!!(selectedRows.size && selectedRows.size !== count)}
                                all={!!(selectedRows.size && selectedRows.size === count)}
                                catalogs={catalogsFromGrid}
                                isShowActions={isShowActions}
                                gridName={name}
                                checkAllDisabled={checkAllDisabled || onlyOneCheck}
                                setFilter={this.setFilter}
                                setSort={this.setSort}
                                filters={this.mapData()}
                                setSelectedAll={this.setSelectedAll}
                                resizeColumn={this.resizeColumn}
                                isReport={isReport}
                                reportWithCheckbox={reportWithCheckbox}
                                reportFilters={filters}
                            />
                        }
                        context={this.container}
                        onBottomVisible={this.nextPage}
                        isReport={isReport}
                        scrollOff={!infinityScroll}
                    >
                        <Result
                            useBigLoader={useBigLoader}
                            isReport={isReport}
                            isRefresh={isRefresh}
                            reportWithCheckbox={reportWithCheckbox}
                            columns={columns}
                            rows={rows}
                            progress={progress}
                            name={name}
                            goToCard={this.handleGoToCard}
                            actions={actions}
                            actionBtns={actionBtns}
                            onlyOneCheck={onlyOneCheck}
                            loadList={this.loadList}
                            disabledCheck={disabledCheck}
                            selectedRows={selectedRows}
                            setSelected={this.setSelected}
                            isShowActions={isShowActions}
                            editableColumns={editableColumns}
                            copyDisabled={copyDisabled}
                            registryNumberClick={this.registryNumberClick}
                            unloadRegistries={this.unloadRegistriesClick}
                            openCardByCellClick={openCardByCellClick}
                            checkIsAvailable={checkIsAvailable}
                        />
                    </InfiniteScrollTable>}
                    {(selectedRows.size && !noFooter) ? (
                        <Footer
                            gridName={name}
                            groupActions={groupActions}
                            selectedRows={selectedRows}
                            load={this.loadList}
                        />
                    ) : null}
                </div>
                {
                    !infinityScroll && click && <div>
                        <Segment className="grid__pagination">
                            <Form.Field inline>
                                <label>{t('numberOfRows')}</label>
                                <Dropdown
                                    className='report__pagination-select'
                                    options={options}
                                    selection
                                    text={this.state.pageSize}
                                    onChange={(e, { value }) => {
                                        this.setState({pageSize: value},() => this.getPage(1))
                                    }}
                                />
                            </Form.Field>
                            <Pagination
                                activePage={page}
                                onPageChange={(e, { activePage }) => this.getPage(activePage)}
                                totalPages={Math.ceil(count/this.state.pageSize)}
                            />
                        </Segment>
                    </div>
                }
                <Confirm
                    dimmer="blurring"
                    open={confirmation.open}
                    onCancel={closeConfirmation}
                    onConfirm={confirmation.onConfirm}
                    cancelButton={t('cancelConfirm')}
                    content={confirmation.content}
                />
                <PhoneNumberModal callbackSuccess={this.loadList}/>
                <DeliveryTypeModal callbackSuccess={this.loadList}/>
                <PaymentTermModal callbackSuccess={this.loadList} name={name}/>
            </>
        );
    }
}

SuperGrid.propTypes = {
    totalCount: PropTypes.number,
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    progress: PropTypes.bool,
    loadList: PropTypes.func,
    autoUpdateStart: PropTypes.func,
    autoUpdateStop: PropTypes.func,
    clearGridInfo: PropTypes.func,
    unloadRegistries: PropTypes.func,
};
SuperGrid.defaultProps = {
    loadList: () => {},
    autoUpdateStart: () => {},
    autoUpdateStop: () => {},
    confirmation: {},
    closeConfirmation: () => {},
    clearStore: () => {},
    getLookupList: () => {},
    getAllIds: () => {},
    disabledCheck: () => {},
    clearGridInfo: () => {},
};

export default withTranslation()(withRouter(SuperGrid));
