import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Divider, Form, Grid} from 'semantic-ui-react';
import {useDispatch, useSelector} from 'react-redux';
import {columnsTypesConfigSelector} from '../../../../ducks/representations';
import {SHIPPINGS_GRID} from '../../../../constants/grids';
import Anomalies from "./anomalies";
import {anomaliesShippingSelector, getAnomalies} from "../../../../ducks/gridCard";

const AnomaliesShipping = ({form = {}, onChange, settings = {}, error, updateHistory, disabled}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};
    const anomalies = useSelector(state => anomaliesShippingSelector(state)) || [];

    const getShippingAnomalies = () => {
        dispatch(getAnomalies({
            id: form.id,
            type: 'shippingAnomalyType'
        }));
    };

    useEffect(()=>{
        form.id && getShippingAnomalies();
    }, [form.id]);

    const callbackSuccess = () => {
        //getShippingAnomalies();
        updateHistory();
    }

    return (
        <Form className="tabs-card">
            <Anomalies disabled={disabled} id={form.id} name={'shippingAnomalies'} typeName={'shippingAnomalyType'} form={anomalies} onChange={onChange} settings={settings} columnsConfig={columnsConfig} callbackSuccess={callbackSuccess}/>
        </Form>
    );
};

export default AnomaliesShipping;
