import React, {forwardRef} from 'react';
import {useTranslation} from "react-i18next";
import {Form, Input} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {formatDate, parseDate} from "../../utils/dateTimeFormater";

const Dates = ({
                   value,
                   name,
                   onChange,
                   isDisabled,
                   noLabel,
                   text,
                   placeholder,
                   className,
                   isRequired,
                   error,
                   inline
               }) => {
    const getClassNames = () => {
        const classNames = [];

        if (error) {
            classNames.push('input-error');
        }

        if (className) {
            classNames.push(className);
        }

        return classNames.join(' ');
    };

    const {t} = useTranslation();

    const onChangeDates = (date, e) => {
        let newValues;
        const newDate = formatDate(date);
        (value || []).includes(newDate) ? newValues = value.filter(v => v.toString() !== newDate.toString()) : newValues = [...(value || []), newDate];
        onChange(e, {name, value: newValues})
    }

    const DatesInput = forwardRef(({value: vle, onClick}, ref) => (
        <div onClick={onClick} ref={ref}>
            <Input
                placeholder={placeholder || getDefPlaceholder()}
                value={(value || []).join(', ')}/>
        </div>
    ));

    const getDefPlaceholder = () => `${t("dateInputPlaceholder")}, ...`

    return (
        <Form.Field className='dates-input' error={error}>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{__html: `${t(text || name)}${isRequired ? ' *' : ''}`}}/>
                </label>
            ) : null}
            <DatePicker
                inline={inline}
                disabled={isDisabled || false}
                className={getClassNames()}
                dateFormat="dd.MM.yyyy"
                locale={localStorage.getItem('i18nextLng')}
                onChange={(date, e) => onChangeDates(date, e)}
                highlightDates={(value || []).map(v => parseDate(v || ''))}
                shouldCloseOnSelect={false}
                customInput={<DatesInput/>}
            />
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{__html: error}}/>
            ) : null}
        </Form.Field>
    );
};
export default Dates;