import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, Link } from 'react-router-dom';
import {
    NavBar, Popup, Button as SButton,
} from 'antd-mobile';
import { LeftOutline } from 'antd-mobile-icons';
import {
    Menu,
    Segment,
    Header,
    Divider,
    Icon,
    Button,
} from 'semantic-ui-react';

import DutyShiftModal from '../../../containers/customDictionary/dutyShiftModal';
import {
    userNameSelector,
    roleSelector,
} from '../../../ducks/profile';
import { logoutRequest } from '../../../ducks/login';
import {
    GavelIcon,
    TruckIcon,
    TrailerIcon,
    MapIcon,
    HeadsetIcon,
    CheckCircleIcon,
    TimeClockIcon,
    CardIdIcon,
} from './icons';
import { StyledMenu, StyledButton, StyledMenuContainer } from './style';

const backArrow = (
    <SButton
        shape="rounded"
        fill="none"
        style={{
            '--background-color': 'rgba(65, 131, 196, 0.3)',
            '--text-color': '#fff',
            width: '42px',
            height: '42px',
        }}
    >
        <LeftOutline />
    </SButton>
);

const AsideContent = ({ onClose }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const userName = useSelector((state) => userNameSelector(state));
    const userRole = useSelector((state) => roleSelector(state));
    const [activeItem, setActiveItem] = useState(location?.pathname);
    const [openDutyShiftModal, setOpenDutyShiftModal] = useState(false);

    const handleItemClick = (e, { name }) => {
        setActiveItem(name);

        onClose();
    };

    const logOut = () => {
        dispatch(logoutRequest());
    };

    const handleDutyClick = (e) => {
        e.preventDefault();
        setOpenDutyShiftModal(true);
    };

    const panes = [
        {
            name: 'shippings',
            sublings: [
                { name: 'auctionsAndNew', icon: <GavelIcon />, to: '/grid/shippings?mode=1' },
                { name: 'ShippingGridMode.Assigned', icon: <CheckCircleIcon />, to: '/grid/shippings?mode=2' },
                { name: 'ShippingGridMode.InProgress', icon: <TimeClockIcon />, to: '/grid/shippings?mode=3' },
            ],
        },
        {
            name: 'dictionaries',
            sublings: [
                { name: 'vehicles', icon: <TruckIcon />, to: '/dictionary/vehicles' },
                { name: 'trailers', icon: <TrailerIcon />, to: '/dictionary/trailers' },
                { name: 'transportCompanyDrivers', icon: <CardIdIcon />, to: '/dictionary/transportCompanyDrivers' },
            ],
        },
        {
            name: 'information',
            sublings: [
                { name: 'deliveryAddresses', icon: <MapIcon />, to: '/dictionary/deliveryAddresses' },
                {
                    name: 'dutyShift', icon: <HeadsetIcon />, to: '', onClick: handleDutyClick,
                },
            ],
        },
    ];

    return (
        <>
            <StyledMenu
                fluid
                inverted
                vertical
                borderless
                size="massive"
                animation="overlay"
                style={{ backgroundColor: '#014595', height: '100%' }}
            >
                <Menu.Header>
                    <Segment clearing style={{ backgroundColor: '#043570' }}>
                        <Header as="h2" floated="left" inverted>
                            <Header.Content>
                                {userName}
                                <Header.Subheader>{userRole}</Header.Subheader>
                            </Header.Content>
                        </Header>

                        <Button
                            circular
                            secondary
                            style={{ backgroundColor: '#4183C4', height: '42px', width: '42px' }}
                            icon
                            floated="right"
                            onClick={logOut}
                        >
                            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.0278 17.9594L15.1941 17.0285C15.9572 16.8589 16.5 16.1786 16.5 15.3938V3.6062C16.5 2.82136 15.9572 2.14111 15.195 1.97151L11.0287 1.04064C9.98719 0.807923 9 1.60407 9 2.67627V16.3247C9 17.3959 9.98719 18.1921 11.0278 17.9594Z" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M11.8123 8.5188V10.4032" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1.5 13.2485V15.1725C1.5 16.2136 2.33906 17.0568 3.375 17.0568H6.1875" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1.5 5.7111V3.82674C1.5 2.78563 2.33906 1.94238 3.375 1.94238H6.1875" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1.5 9.47974H6.1875" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.31274 11.3642L6.18774 9.47982L4.31274 7.59546" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </Button>
                    </Segment>
                </Menu.Header>

                <StyledMenuContainer>
                    {panes.map((pane) => (
                        <Menu.Item>
                            <Menu.Header>{t(pane.name)}</Menu.Header>

                            <Menu.Menu style={{ margin: '0 10px' }}>
                                {pane.sublings.map((subling) => (
                                    <Menu.Item
                                        as={Link}
                                        name={subling.to}
                                        to={subling.to}
                                        fitted="horizontally"
                                        active={activeItem === subling.to}
                                        onClick={subling?.onClick || handleItemClick}
                                    >
                                        <Icon.Group>
                                            {subling.icon}
                                        </Icon.Group>

                                        {t(subling.name)}
                                    </Menu.Item>
                                ))}

                                <Divider fitted />
                            </Menu.Menu>
                        </Menu.Item>
                    ))}
                </StyledMenuContainer>

                <StyledButton
                    content={t('close')}
                    size="big"
                    onClick={onClose}
                />
            </StyledMenu>

            <DutyShiftModal
                open={openDutyShiftModal}
                onClose={() => setOpenDutyShiftModal(false)}
            />
        </>
    );
};

const HeaderMobile = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [visibleCloseRight, setVisibleCloseRight] = useState(false);

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <NavBar
                style={{
                    background: '#014595', color: '#fff', '--height': '56px', '--adm-font-size-10': '14px',
                }}
                backArrow={backArrow}
                right={(
                    <div onClick={() => setVisibleCloseRight(true)}>
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_556_10996)">
                                <path d="M4.66667 21H23.3333C23.975 21 24.5 20.475 24.5 19.8333C24.5 19.1917 23.975 18.6667 23.3333 18.6667H4.66667C4.025 18.6667 3.5 19.1917 3.5 19.8333C3.5 20.475 4.025 21 4.66667 21ZM4.66667 15.1667H23.3333C23.975 15.1667 24.5 14.6417 24.5 14C24.5 13.3583 23.975 12.8333 23.3333 12.8333H4.66667C4.025 12.8333 3.5 13.3583 3.5 14C3.5 14.6417 4.025 15.1667 4.66667 15.1667ZM3.5 8.16667C3.5 8.80833 4.025 9.33333 4.66667 9.33333H23.3333C23.975 9.33333 24.5 8.80833 24.5 8.16667C24.5 7.525 23.975 7 23.3333 7H4.66667C4.025 7 3.5 7.525 3.5 8.16667Z" fill="white" />
                            </g>

                            <defs>
                                <clipPath id="clip0_556_10996">
                                    <rect width="28" height="28" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                )}
                onBack={handleBack}
            >
                {t('shippings')}
            </NavBar>

            <Popup
                position="left"
                visible={visibleCloseRight}
                bodyStyle={{ width: '100vw' }}
                onClose={() => {
                    setVisibleCloseRight(false);
                }}
            >
                <AsideContent onClose={() => setVisibleCloseRight(false)} />
            </Popup>
        </>
    );
};

export default HeaderMobile;
