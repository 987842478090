import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Tab, TabPane, MenuItem, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import qs from 'qs';

const StyledTab = styled(Tab)`
  & > .ui.secondary.menu {
    overflow-x: auto;
    overflow-y: hidden;
    margin: 0;
    height: 48px;

    &::-webkit-scrollbar {
      display: none;
    }

    & > .item.active {
        background: #1C78D3 !important;
    }

    & > .item {
      font-size: 12px;
      background: #E0E1E2;
      align-self: end;
      margin: 0 4px 0 0;

      &:first-child {
        margin: 0 4px 0 14px;
      }

      &:last-child {
        margin: 0 14px 0 0;
      }

      & > .label {
        background: #E42E24 !important;
      }
    }

    & > .item.active {
      background: #4183C4;
      color: white;
    }
  }
`;

const MyTabs = ({ children, onTabChange, counters }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState();

    const panes = [
        {
            menuItem: t('allShippings'),
            render: () => <TabPane as="div" attached={false}>{children}</TabPane>,
        },
        {
            menuItem: (
                <MenuItem key="auctions">
                    <Label size="mini" circular floating>
                        {counters[1]}
                    </Label>

                    {t('auctions')}
                </MenuItem>
            ),
            render: () => <TabPane as="div" attached={false}>{children}</TabPane>,
        },
        {
            menuItem: (
                <MenuItem key="nominated">
                    <Label size="mini" circular floating>
                        {counters[2]}
                    </Label>

                    {t('ShippingGridMode.Assigned')}
                </MenuItem>
            ),
            render: () => <TabPane as="div" attached={false}>{children}</TabPane>,
        },
        {
            menuItem: t('ShippingGridMode.InProgress'),
            render: () => <TabPane as="div" attached={false}>{children}</TabPane>,
        },
        {
            menuItem: t('ShippingGridMode.Finished'),
            render: () => <TabPane as="div" attached={false}>{children}</TabPane>,
        },
    ];

    const handleChange = (e, data) => {
        setActiveIndex(data.activeIndex);

        onTabChange(data);
    };

    useEffect(() => {
        const { mode } = qs.parse(location.search, { ignoreQueryPrefix: true }) || {};

        if (mode && mode !== activeIndex) {
            setActiveIndex(mode);
            onTabChange({ activeIndex: mode });
        }
    }, [location]);

    return (
        <StyledTab
            style={{ height: '48px' }}
            activeIndex={activeIndex}
            renderActiveOnly
            menu={{ secondary: true }}
            panes={panes}
            onTabChange={handleChange}
        />
    );
};

export default MyTabs;
