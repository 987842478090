import { createSelector } from 'reselect';
import { all, put, call, select, takeEvery } from 'redux-saga/effects';
import { columnsFormSelector, columnsGridSelector } from './gridList';
import { postman } from '../utils/postman';
import { RETURN_COST_TYPE, SOLD_TO_TYPE } from '../constants/columnTypes';
import qs from 'qs';

export const defaultRepresentationName = '62f1c8ca-cdf6-4d2e-ae96-43725b448535';

//*  TYPES  *//
const GET_REPRESENTATIONS_REQUEST = 'GET_REPRESENTATIONS_REQUEST';
const GET_REPRESENTATIONS_SUCCESS = 'GET_REPRESENTATIONS_SUCCESS';
const GET_REPRESENTATIONS_ERROR = 'GET_REPRESENTATIONS_ERROR';

const SAVE_REPRESENTATION_REQUEST = 'SAVE_REPRESENTATION_REQUEST';
const SAVE_REPRESENTATION_SUCCESS = 'SAVE_REPRESENTATION_SUCCESS';
const SAVE_REPRESENTATION_ERROR = 'SAVE_REPRESENTATION_ERROR';

const EDIT_REPRESENTATION_REQUEST = 'EDIT_REPRESENTATION_REQUEST';
const EDIT_REPRESENTATION_SUCCESS = 'EDIT_REPRESENTATION_SUCCESS';
const EDIT_REPRESENTATION_ERROR = 'EDIT_REPRESENTATION_ERROR';

const EDIT_ZONES_FILTER_REQUEST = 'EDIT_ZONES_FILTER_REQUEST';
const EDIT_ZONES_FILTER_SUCCESS = 'EDIT_ZONES_FILTER_SUCCESS';
const EDIT_ZONES_FILTER_ERROR = 'EDIT_ZONES_FILTER_ERROR';

const EDIT_DEFAULT_REPRESENTATION_REQUEST = 'EDIT_DEFAULT_REPRESENTATION_REQUEST';
const EDIT_DEFAULT_REPRESENTATION_SUCCESS = 'EDIT_DEFAULT_REPRESENTATION_SUCCESS';
const EDIT_DEFAULT_REPRESENTATION_ERROR = 'EDIT_DEFAULT_REPRESENTATION_ERROR';

const DELETE_REPRESENTATION_REQUEST = 'DELETE_REPRESENTATION_REQUEST';
const DELETE_REPRESENTATION_SUCCESS = 'DELETE_REPRESENTATION_SUCCESS';
const DELETE_REPRESENTATION_ERROR = 'DELETE_REPRESENTATION_REQUEST';

const SET_REPRESENTATION = 'SET_REPRESENTATION';

const LOCK_REPRESENTATION = 'LOCK_REPRESENTATION';

const REPRESENTATIONS_KEY = 'representations';
const REPRESENTATION_KEY = 'representation';

const CHANGE_IS_OPEN = 'CHANGE_IS_OPEN';

//*  INITIAL STATE  *//

const initial = {
    list: {},
    representation: JSON.parse(localStorage.getItem(REPRESENTATION_KEY)) || {},
    open: false,
};

//*  REDUCER  *//

export default (state = initial, { type, payload = {} }) => {
    const { gridName, name, value, oldName } = payload;

    switch (type) {
        case GET_REPRESENTATIONS_SUCCESS:
            return {
                ...state,
                list: payload,
            };
        case SET_REPRESENTATION:
            return {
                ...state,
                representation: {
                    ...state.representation,
                    [gridName]: value,
                },
            };
        case EDIT_REPRESENTATION_SUCCESS:
        case EDIT_DEFAULT_REPRESENTATION_SUCCESS:
        case SAVE_REPRESENTATION_SUCCESS:
        case DELETE_REPRESENTATION_SUCCESS:
        case EDIT_ZONES_FILTER_SUCCESS:
            return {
                ...state,
                list: payload,
            };
        case CHANGE_IS_OPEN:
            return {
                ...state,
                open: payload,
            };
        case LOCK_REPRESENTATION:
            return {
                ...state,
                locked: {
                    ...state.locked,
                    [payload.name]: payload.value
                },
            };
        default:
            return state;
    }
};

//*  ACTION CREATORS  *//

export const getRepresentationsRequest = payload => {
    return {
        type: GET_REPRESENTATIONS_REQUEST,
        payload,
    };
};

export const saveRepresentationRequest = payload => {
    return {
        type: SAVE_REPRESENTATION_REQUEST,
        payload,
    };
};

export const editRepresentationRequest = payload => {
    return {
        type: EDIT_REPRESENTATION_REQUEST,
        payload,
    };
};

export const editDefaultRepresentationsRequest = payload => {
    return {
        type: EDIT_DEFAULT_REPRESENTATION_REQUEST,
        payload,
    };
};

export const setRepresentationRequest = payload => {
    return {
        type: SET_REPRESENTATION,
        payload,
    };
};

export const lockRepresentation = payload => {
    return {
        type: LOCK_REPRESENTATION,
        payload,
    };
};

export const deleteRepresentationRequest = payload => {
    return {
        type: DELETE_REPRESENTATION_REQUEST,
        payload,
    };
};

export const changeIsOpen = payload => {
    return {
        type: CHANGE_IS_OPEN,
        payload,
    };
};

export const editZonesFilter = payload => {
    return {
        type: EDIT_ZONES_FILTER_REQUEST,
        payload,
    };
};

//*  SELECTORS *//

export const stateSelector = state => state.representations;

export const openSelector = createSelector(
    stateSelector,
    state => state.open,
);

export const representationsSelector = createSelector(
    stateSelector,
    state => state.list,
);

export const representationsIsLockedSelector = createSelector(
    [stateSelector, (state, name) => name],
    (state, name) => {
        return state.locked && state.locked[name];
    }
);

export const representationNameSelector = createSelector(
    [stateSelector, (state, name) => name, state => representationsSelector(state)],
    (state, gridName, list) => {
        const name = state.representation[gridName];

        return list[name] ? name : null;
    },
);

export const representationSelector = createSelector(
    [
        stateSelector,
        (state, name) => name,
        (state, name, source) => columnsGridSelector(state, source || name),
        state => state.router.location.search,
    ],
    (state, gridName, columnList, search) => {
        const { mode } = qs.parse(search, { ignoreQueryPrefix: true }) || {};

        const representationName =
            state.representation && !(state.locked && state.locked[gridName]) && state.representation[gridName];

        const representation = representationName ? state.list[representationName] : [];

        const actualRepresentation = [];

        representation &&
            representation.forEach(item => {
                const actualItem = columnList.find(column => column.name === item.name);
                if (actualItem) {
                    actualRepresentation.push({
                        ...actualItem,
                        width: item.width,
                        filter: item.filter,
                        sort: item.sort,
                    });
                }
            });
        return actualRepresentation;
    },
);

export const zonesFilterSelector = createSelector(
    stateSelector,
    (state) => {
        return state.list[`${defaultRepresentationName}_zones`] || {};
    },
);

export const representationFromGridSelector = createSelector(
    [
        stateSelector,
        (state, name) => name,
        (state, name, source) => columnsGridSelector(state, source || name),
        (state, name, source) => representationSelector(state, name, source),
        state => state.router.location.search,
        (state, name, source, mode) => mode,
    ],
    (state, gridName, list, representation, search, modeDefault) => {
        const { mode = modeDefault } = qs.parse(search, { ignoreQueryPrefix: true });

        if (representation && representation.length) {
            return representation;
        }

        let defaultRepresentationSettings = {};

        state.list[`${defaultRepresentationName}_${mode}`] && state.list[`${defaultRepresentationName}_${mode}`].map(item=> defaultRepresentationSettings[item.name] = {
            filter: item.filter,
            sort: item.sort
        });

        return list
            .filter(item => {
                    const defaultReprs = item.defaultRepresentations.concat(item.defaultRepresentationsOperationalReport);
                    return Boolean(item.isDefault && defaultReprs.find(i => i.mode == mode))
                },
            )
            .sort((a, b) => {
                const defaultReprsA = a.defaultRepresentations.concat(a.defaultRepresentationsOperationalReport);
                const defaultReprsB = b.defaultRepresentations.concat(b.defaultRepresentationsOperationalReport);
                const aOrder = defaultReprsA.find(i => i.mode == mode).order;
                const bOrder = defaultReprsB.find(i => i.mode == mode).order;

                if (aOrder > bOrder) {
                    return 1;
                }
                if (aOrder < bOrder) {
                    return -1;
                }

                return 0;
            })
            .map(item => {
                return {
                    ...item,
                    ...defaultRepresentationSettings[item.name]
                }
            });
    },
);

export const columnsTypesConfigSelector = createSelector(
    columnsFormSelector,
    columns => {
        let config = {};

        columns.forEach(column => {
            config = {
                ...config,
                [column.name]: {
                    type: column.name === 'soldTo' ? SOLD_TO_TYPE : column.type,
                    source: column.source,
                    sourceAdditionalParams: column.sourceAdditionalParameters,
                    displayNameKey: column.displayNameKey,
                    isReadOnly: column.isReadOnly,
                    isRequired: column.isRequired,
                    maxLength: column.maxLength,
                },
            };
        });

        return {
            ...config,
            orderCosts: {
                type: RETURN_COST_TYPE,
            },
        };
    },
);

//*  SAGA  *//

function* getRepresentationsSaga({ payload }) {
    try {
        const { key, callBackFunc, noRepresentation } = payload;
        const result = yield !noRepresentation && postman.get(`/userSettings/${key}`);

        yield put({
            type: GET_REPRESENTATIONS_SUCCESS,
            payload: result.value ? JSON.parse(result.value) : {},
        });
        const columns = yield select(state => representationFromGridSelector(state, key));

        callBackFunc && callBackFunc(columns);
    } catch (e) {
        yield put({
            type: GET_REPRESENTATIONS_ERROR,
            payload: e,
        });
    }
}

function* saveRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name, value } = payload;
        yield call(() => getRepresentationsSaga({payload: {key}}))
        const list = yield select(representationsSelector);

        const params = {
            ...list,
            [name]: value.map(item => ({
                ...item,
            })),
        };

        const result = yield postman.post(`/userSettings/${key}`, {
            value: JSON.stringify(params),
        });

        yield put({
            type: SAVE_REPRESENTATION_SUCCESS,
            payload: params,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: SAVE_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* editRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name, value, oldName } = payload;

        if(!value || !value.length) {
            return yield put({
                type: EDIT_REPRESENTATION_ERROR
            });
        }

        yield call(() => getRepresentationsSaga({payload: {key}}))
        const list = yield select(representationsSelector);

        let params = {};

        Object.keys(list).forEach(keyList => {
            if (keyList !== oldName) {
                params = {
                    ...params,
                    [keyList]: list[keyList],
                };
            }
        });

        params = {
            ...params,
            [name]: value,
        };

        const result = yield postman.post(`/userSettings/${key}`, {
            value: JSON.stringify(params),
        });

        yield put({
            type: EDIT_REPRESENTATION_SUCCESS,
            payload: params,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* editDefaultRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name, value, oldName, mode } = payload;
        yield call(() => getRepresentationsSaga({payload: {key}}))
        const list = yield select(representationsSelector);

        let params = {};

        Object.keys(list).forEach(keyList => {
            if (keyList !== `${defaultRepresentationName}_${mode}`) {
                params = {
                    ...params,
                    [keyList]: list[keyList],
                };
            }
        });

        params = {
            ...params,
            [`${defaultRepresentationName}_${mode}`]: value,
        };

        const result = yield postman.post(`/userSettings/${key}`, {
            value: JSON.stringify(params),
        });

        yield put({
            type: EDIT_REPRESENTATION_SUCCESS,
            payload: params,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* editZonesFilterSaga({ payload }) {
    try {
        const { callbackSuccess, key, value } = payload;
        yield call(() => getRepresentationsSaga({payload: {key}}))
        const list = yield select(representationsSelector);

        let params = {};

        const name = `${defaultRepresentationName}_zones`

        Object.keys(list).forEach(keyList => {
            if (keyList !== name) {
                params = {
                    ...params,
                    [keyList]: list[keyList],
                };
            }
        });

        params = {
            ...params,
            [name]: value,
        };

        const result = yield postman.post(`/userSettings/${key}`, {
            value: JSON.stringify(params),
        });

        yield put({
            type: EDIT_ZONES_FILTER_SUCCESS,
            payload: params,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: EDIT_ZONES_FILTER_ERROR,
            payload: e,
        });
    }
}

function* deleteRepresentationSaga({ payload }) {
    try {
        const { callbackSuccess, key, name } = payload;
        yield call(() => getRepresentationsSaga({payload: {key}}))
        const list = yield select(representationsSelector);

        let params = {};

        Object.keys(list).forEach(keyList => {
            if (keyList !== name) {
                params = {
                    ...params,
                    [keyList]: list[keyList],
                };
            }
        });

        const result = yield postman.post(`/userSettings/${key}`, {
            value: JSON.stringify(params),
        });

        yield put({
            type: DELETE_REPRESENTATION_SUCCESS,
            payload: params,
        });

        callbackSuccess && callbackSuccess();
    } catch (e) {
        yield put({
            type: DELETE_REPRESENTATION_ERROR,
            payload: e,
        });
    }
}

function* setRepresentationSaga({ payload }) {
    try {
        yield put(
            getRepresentationsRequest({
                key: payload.gridName,
            }),
        );
        const { callbackSuccess } = payload;
        const state = yield select(state => state.representations.representation);
        localStorage.setItem(REPRESENTATION_KEY, JSON.stringify(state));

        setTimeout(() => {
            callbackSuccess && callbackSuccess();
        }, 500);
    } catch (e) {
        console.log('___error', e);
    }
}

export function* saga() {
    yield all([
        takeEvery(SAVE_REPRESENTATION_REQUEST, saveRepresentationSaga),
        takeEvery(EDIT_REPRESENTATION_REQUEST, editRepresentationSaga),
        takeEvery(EDIT_DEFAULT_REPRESENTATION_REQUEST, editDefaultRepresentationSaga),
        takeEvery(DELETE_REPRESENTATION_REQUEST, deleteRepresentationSaga),
        takeEvery(SET_REPRESENTATION, setRepresentationSaga),
        takeEvery(GET_REPRESENTATIONS_REQUEST, getRepresentationsSaga),
        takeEvery(EDIT_ZONES_FILTER_REQUEST, editZonesFilterSaga),
    ]);
}
