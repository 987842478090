import React, {
    useMemo, useCallback, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Button, Confirm, Modal, Popup,
} from 'semantic-ui-react';

import CardLayout from '../../components/CardLayout';
import FormField from '../../components/BaseComponents';
import {
    canDeleteSelector,
    cardProgressSelector,
    cardSelector,
    clearDictionaryCard,
    columnsFromCardSelector as columnsSelector,
    deleteDictionaryEntryRequest,
    errorSelector,
    getCardRequest,
    isReadOnlyFieldsSelector,
    getDictionaryCardDefaultValueRequest,
    isVisibleFieldsSelector,
    progressSelector,
    saveDictionaryCardRequest,
    setNotificationsSelector,
    trackChangesSelector,
} from '../../ducks/dictionaryView';
import { customFormsSelector } from '../../ducks/profile';
import Documents from '../customGrid/components/shared/documents';
import { DICTIONARY_CARD_LINK, PARAMS_TRANSPORT_COMPANY_SETTING_LINK } from '../../router/links';
import VehicleInformation from './vehicle';
import History from '../customGrid/components/shared/history';
import { clearHistory, getHistoryRequest } from '../../ducks/history';
import DocumentsWithTemplates from './documents';
import { goBack } from '../../utils/urlParamsHelper';

const Content = ({
    columns, error, form, handleChange, dictionaryName,
}) => {
    const { t } = useTranslation();

    const isVisibleFields = useSelector((state) => isVisibleFieldsSelector(state, dictionaryName));

    const isReadOnlyFields = useSelector((state) => isReadOnlyFieldsSelector(state, dictionaryName));

    const isReadOnly = (column) => {
        if (dictionaryName === 'deliveryAddresses') {
            if (column.name === 'resetPassword') return !form.deliveryPointType || (form.deliveryPointType && form.deliveryPointType.value !== 'postMachine');
        }
        return false;
    };

    const dictionariesWithSpecialColumns = ['fMContacts'];
    const columnsWithValueAll = ['transportCompanyId'];

    return (
        <div className="ui form dictionary-edit">
            {columns.map((column) => {
                if (isVisibleFields[column.name] && !form[isVisibleFields[column.name]]) return null;

                return (
                    <FormField
                        {...column}
                        fieldClassName={column.name === 'noticeBody' ? 'code-field' : ''}
                        rows={column.name === 'noticeBody' ? 20 : undefined}
                        isReadOnly={(isReadOnlyFields[column.name] && form[isReadOnlyFields[column.name]]) || column.isReadOnly || isReadOnly(column)}
                        noScrollColumn={column}
                        key={column.name}
                        error={error[column.name]}
                        value={form[column.name]}
                        textValue={(dictionariesWithSpecialColumns.includes(dictionaryName) && columnsWithValueAll.includes(column.name)) && t('All')}
                        onChange={handleChange}
                    />
                );
            })}
        </div>
    );
};

const CardNew = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        match = {},
        history,
        location = {},
        load,
        isModal,
        openModal,
        onClose: onCloseModal,
    } = props;
    const { params = {} } = match;
    const { name, id } = params;
    const { state = {} } = location;
    const { defaultForm = {}, columns: propsColumns, prevLocation } = state;

    const [form, setForm] = useState({ ...defaultForm });
    const [confirmation, setConfirmation] = useState({ open: false });
    const [notChangeForm, setNotChangeForm] = useState(true);

    const columns = useSelector(
        (state) => (propsColumns || columnsSelector(state, name)),
    );

    const customForms = useSelector((state) => customFormsSelector(state, name));
    const trackChanges = useSelector((state) => trackChangesSelector(state, name));

    const canAttachDocuments = customForms.indexOf('documents') !== -1 || customForms.includes('document');
    const withHistory = customForms.includes('history') && id;

    const canDelete = useSelector((state) => canDeleteSelector(state, name));
    const canSetNotifications = useSelector((state) => setNotificationsSelector(state, name));
    const loading = useSelector((state) => cardProgressSelector(state));
    const progress = useSelector((state) => progressSelector(state));
    const card = useSelector((state) => cardSelector(state));
    const error = useSelector((state) => errorSelector(state));

    const getHistory = () => {
        dispatch(getHistoryRequest({ id }));
    };

    useEffect(() => {
        id ? fetchData() : dispatch(getDictionaryCardDefaultValueRequest(name));
        withHistory && getHistory();
        return () => {
            dispatch(clearDictionaryCard());
            withHistory && dispatch(clearHistory());
        };
    }, []);

    useEffect(() => {
        setForm((form) => ({
            ...card,
            ...form,
        }));
    }, [card]);

    const fetchData = () => {
        dispatch(getCardRequest({
            id,
            name,
            redirectCallback: onClose,
        }));
    };

    const onOpenModal = () => {};

    const title = useMemo(
        () => (id ? `${t(name)}: ${t('edit_record')}` : `${t(name)}: ${t('new_record')}`),
        [name, id],
    );

    const handleSave = (isNotClose) => {
        let params = {
            ...form,
        };

        if (name === 'fMContacts' && !form.transportCompanyId) params.transportCompanyId = [];

        if (id) {
            params = {
                ...params,
                id,
            };
        }

        dispatch(
            saveDictionaryCardRequest({
                params,
                name,
                changedProps: trackChanges ? changedFields : null,
                callbackSuccess: (id, lookup, isClose = false) => {
                    if (isNotClose && !isClose) {
                        // setNotChangeForm(true);
                        history.replace({ pathname: DICTIONARY_CARD_LINK.replace(':name', name).replace(':id', id), state: { ...location.state } });
                    } else {
                        load && load(form);
                        onClose(lookup);
                    }
                },
            }),
        );
    };

    const getActionsFooter = useCallback(() => (
        <>
            <Button color="grey" onClick={handleClose}>
                {t('CancelButton')}
            </Button>
            <Button
                color="blue"
                disabled={notChangeForm || progress}
                loading={progress}
                onClick={() => handleSave(canAttachDocuments && !id)}
            >
                {canAttachDocuments && !id ? t('addDocuments') : t('SaveButton')}
            </Button>
        </>
    ), [form, notChangeForm, progress, canAttachDocuments, id]);

    const handleDelete = () => {
        setConfirmation({
            open: true,
            content: t('Delete dictionary entry', { name: t(name) }),
            onCancel: confirmClose,
            onConfirm: () => {
                dispatch(
                    deleteDictionaryEntryRequest({
                        name,
                        id,
                        callbackSuccess: () => onClose(),
                    }),
                );
            },
        });
    };

    const handleOpenParamsSetting = () => {
        history.push(PARAMS_TRANSPORT_COMPANY_SETTING_LINK.replace(':id', id));
    };

    const columnsBySection = (name = '', columns = []) => columns.filter((col) => (col.customSectionPlace || '').toUpperCase() === name.toUpperCase());

    const getActionsHeader = useCallback(() => (
        <div>
            {(canSetNotifications && id) && (
                <Popup
                    content={t('Set up notifications')}
                    position="bottom right"
                    trigger={
                        <Button icon="envelope outline" onClick={handleOpenParamsSetting} content={t('Set up notifications')} />
                    }
                />
            )}
            {canDelete && id ? (
                <Popup
                    content={t('delete')}
                    position="bottom right"
                    trigger={
                        <Button icon="trash alternate outline" onClick={handleDelete} content={t('delete')} />
                    }
                />
            ) : null}
        </div>
    ), []);

    const additionalInformationIsOpen = (name) => {
        switch (name) {
        case 'transportCompanyDrivers':
            return form.isRegular === false;
        case 'vehicles':
        case 'trailers':
            return form.transportCompanyOwnershipType === 'loan' || (form.transportCompanyOwnershipType === 'attracted' && form.carrierOwnershipType === 'loan');
        default: return true;
        }
    };

    useEffect(() => {
        if (customForms.indexOf('additionalInformation') && !additionalInformationIsOpen(name)) {
            columnsBySection('additionalInformation', columns).forEach((c) => {
                form[c.name] && handleChange(null, { name: c.name, value: null });
            });
        }
    }, [additionalInformationIsOpen(name)]);

    const getContent = useCallback(() => {
        let items = [
            customForms.indexOf('carrier') && {
                menuItem: 'information',
                render: () => (
                    <VehicleInformation
                        columns={columns.filter((col) => !col.customSectionPlace)}
                        error={error}
                        form={form}
                        dictionaryName={name}
                        onChange={handleChange}
                    />
                ),
                isOpen: true,
            },
        ];

        customForms.includes('additionalInformation') && items.push({
            menuItem: 'additionalData',
            render: () => (
                <Content
                    columns={columnsBySection('additionalInformation', columns)}
                    error={error}
                    form={form}
                    dictionaryName={name}
                    handleChange={handleChange}
                />
            ),
            disabled: !additionalInformationIsOpen(name),
            isOpen: additionalInformationIsOpen(name),
        });

        customForms.forEach((customForm) => {
            const onlyOneDoc = ['insuranceDocuments', 'document'];
            const documentWithTemplates = 'documentWithTemplates';
            if (!['carrier', 'additionalInformation'].includes(customForm)) {
                items = [
                    ...items,
                    {
                        menuItem: customForm,
                        render: () => {
                            switch (customForm) {
                            case documentWithTemplates:
                                return (
                                    <DocumentsWithTemplates
                                        form={form}
                                        name={name}
                                        cardId={id}
                                        isEditPermissions
                                        updateHistory={() => {
                                            setNotChangeForm(false);
                                        }}
                                    />
                                );
                            case 'document':
                            case 'documents':
                            case 'insuranceDocuments':
                                return (
                                    <Documents
                                        onlyOne={onlyOneDoc.includes(customForm)}
                                        gridName={name}
                                        cardId={id}
                                        isEditPermissions
                                        updateHistory={() => {
                                            setNotChangeForm(false);
                                        }}
                                    />
                                );
                            case 'history':
                                return <History />;
                            default:
                                return null;
                            }
                        },
                        isOpen: customForm === documentWithTemplates ? !!form.id : !!id,
                        disabled: customForm === documentWithTemplates ? !form.id : !id,
                    },
                ];
            }
        });

        return items;
    }, [form, error, columns, name, id]);

    const [changedFields, setChangedFields] = useState([]);

    const handleChange = useCallback(
        (event, { name, value }) => {
            if (notChangeForm) {
                setNotChangeForm(false);
            }
            setForm((form) => ({
                ...form,
                [name]: value,
            }));
            if (trackChanges) setChangedFields((fs) => (changedFields.includes(name) ? fs : [...fs, name]));
        },
        [notChangeForm, form, trackChanges, changedFields],
    );

    const confirmClose = () => {
        setConfirmation({ open: false });
    };

    const onClose = (lookup = {}) => {
        const { state = {} } = location;

        const autocompleteNull = (!lookup || Object.keys(lookup).length === 0) ? {
            autocompleteItems: null,
            name: null,
        } : null;

        isModal
            ? onCloseModal()
            : state.pathname ? history.replace({
                pathname: state.pathname,
                state: {
                    ...state,
                    lookup,
                    ...autocompleteNull,
                    pathname: state.gridLocation
                        ? state.gridLocation
                        : state.pathname,
                },
            }) : goBack(history, location, id);
    };

    const handleClose = () => {
        if (notChangeForm) {
            onClose();
        } else {
            setConfirmation({
                open: true,
                content: t('confirm_close_dictionary'),
                onCancel: confirmClose,
                onConfirm: () => onClose(),
            });
        }
    };

    console.log('customForms', customForms);

    return (
        <div className="edit-card">
            {isModal ? (
                <Modal
                    dimmer="blurring"
                    open={openModal}
                    closeOnDimmerClick={false}
                    onOpen={onOpenModal}
                    onClose={onCloseModal}
                    closeIcon
                >
                    <Modal.Header>{title}</Modal.Header>
                    <Modal.Description>
                        {/* <Loader size="huge" active={loading}>
                            Loading
                        </Loader> */}
                        <Content
                            columns={columns}
                            error={error}
                            form={form}
                            dictionaryName={name}
                            handleChange={handleChange}
                        />
                    </Modal.Description>
                    <Modal.Actions>{getActionsFooter()}</Modal.Actions>
                </Modal>
            // ) : canAttachDocuments ? (
            ) : customForms && customForms.length ? (
                <CardLayout
                    title={title}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    content={getContent}
                    onClose={handleClose}
                    loading={loading}
                />
            ) : (
                <CardLayout
                    title={title}
                    actionsFooter={getActionsFooter}
                    actionsHeader={getActionsHeader}
                    onClose={handleClose}
                    loading={loading}
                >
                    <Content
                        columns={columns}
                        error={error}
                        form={form}
                        dictionaryName={name}
                        handleChange={handleChange}
                    />
                </CardLayout>
            )}

            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={confirmation.onCancel}
                cancelButton={t('cancelConfirm')}
                confirmButton={t('Yes')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </div>
    );
};

export default CardNew;
