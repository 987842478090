import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import {Button, Form, Grid, Popup, Table, Icon, Pagination, Segment, Select, Dropdown} from 'semantic-ui-react';
import Date from '../../components/BaseComponents/Date';
import {dateToString} from '../../utils/dateTimeFormater';
import {
    exportProgressSelector,
    getReportRequest,
    progressSelector,
    reportExportToExcelRequest,
    reportSelector, totalCountSelector,
} from '../../ducks/reports';
import {OPERATIONAL_REPORT_TYPE} from '../../constants/reportType';
import {useTranslation} from 'react-i18next';
import Block from '../../components/CardLayout/components/block';
import CellValue from '../../components/ColumnsValue';
import FacetField from '../../components/FilterComponents';
import MultiSelect from "../../components/BaseComponents/MultiSelect";
import {
    getRepresentationsRequest,
    representationFromGridSelector,
    representationNameSelector,
    representationsSelector,
    setRepresentationRequest
} from "../../ducks/representations";
import State from "../../components/BaseComponents/State";
import IconCustom from "../../components/CustomIcon";

import {GRID_CARD_LINK} from "../../router/links";
import {options} from "../../constants/paginationOptions";

const OperationalReport = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const initialParams = {
        startDate: dateToString(),
        endDate: dateToString(),
        dateType: "shippingCreate",
        transportCompanyIds: [],
        planers: [],
        primaryTripStatus: [],
        tarifficationType: []
    };

    let [params, setParams] = useState(initialParams);
    let [paramsOpen, setParamsOpen] = useState(true);
    let [notChangeForm, setNotChangeForm] = useState(true);
    let [click, setClick] = useState(false);
    let [filter, setFilter] = useState({});
    let [sort, setSort] = useState({});

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(25);

    let [height, setHeight] = useState(0);

    useEffect(() => {
        let el = document.getElementById('params');
        if (el) {
            setHeight(el.getBoundingClientRect().height);
        }
    }, [params, paramsOpen])

    const report = useSelector(reportSelector);
    const totalCount = useSelector(totalCountSelector);
    const exportProgress = useSelector(exportProgressSelector);
    const loading = useSelector(progressSelector);
    const representations = useSelector(state => representationsSelector(state, OPERATIONAL_REPORT_TYPE));
    const representationName = useSelector(state => representationNameSelector(state, OPERATIONAL_REPORT_TYPE));
    const representationColumns = useSelector(state => representationFromGridSelector(state, OPERATIONAL_REPORT_TYPE, 'shippings', 5));

    useEffect(() => {
        if(!notChangeForm) {
            getReport();
            document.getElementsByClassName('card-content-block_open')[0].scrollTo(0,0);
        }
    }, [filter, sort, representationName, page, count]);

    useEffect(() => {
        dispatch(
            getRepresentationsRequest({
                key: OPERATIONAL_REPORT_TYPE
            }),
        );
    }, []);

    const mapDataForGenerateReport = () => {

        const filtersAll = {}
        representationColumns.forEach(v => filtersAll[v.name] = filter[v.name] || "");
        const filterData = {
            ...filtersAll,
            columns: representationColumns.map(v => v.name),
            gridMode: 5
        };
        return {
            gridName: OPERATIONAL_REPORT_TYPE,
            params: {
                columns: representationColumns.map(v => v.name),
                ...params,
                filter: filterData,
                transportCompanyIds: [...(params.transportCompanyIds || []).map(v => v.value)],
                planers: [...(params.planers || []).map(v => v.name)],
                primaryTripStatus: [...(params.primaryTripStatus || []).map(v => v.value)],
                tarifficationType: [...(params.tarifficationType || []).map(v => v.value)],
                sort,
                skip: count * (page - 1),
                take: count
            },
        };
    };

    const getReport = () => {
        dispatch(getReportRequest(mapDataForGenerateReport()));
    };

    const handleChangeParams = (e, {name, value}) => {

        const newParams = {
            ...params,
            [name]: value,
        };
        setParams(newParams);
        notChangeForm && setNotChangeForm(false);
    };

    const handleChangeFilter = (e, {name, value}) => {
        setFilter(filter => {
            let newFilter = {...filter};
            if (value) {
                newFilter = {
                    ...filter,
                    [name]: value,
                };
            } else {
                delete newFilter[name];
            }

            return newFilter;
        });
    };

    const clearFilter = () => {
        setFilter({});
    };

    const exportExcel = () => {
        dispatch(reportExportToExcelRequest(mapDataForGenerateReport()));
    };

    const generateReport = () => {
        setClick(true);
        setFilter({});
        setSort({});
    };

    const location = useLocation();
    const history = useHistory();

    const goToCard = (isEdit, id, source) => {
        history.push({
            pathname: GRID_CARD_LINK.replace(':name', source).replace(':id', id),
            state: {
                search: location.search,
                pathname: history.location.pathname,
            },
        });
    };

    const item = {
        menuItem: 'report',
        render: () => (
            <Table className='report_table-data' celled>
                <Table.Header>
                    <Table.Row>
                        {click && representationColumns.map((column, i) => (
                            <Table.HeaderCell key={'table-header' + column.name} className={`${i === 0 && 'column-facet__first'}`}>
                                <FacetField
                                    key={'facet' + column.name}
                                    notWrapHeader
                                    index={i}
                                    gridName={OPERATIONAL_REPORT_TYPE}
                                    name={column.name}
                                    displayNameKey={column.displayNameKey}
                                    sort={sort && sort.name === column.name ? sort.desc : null}
                                    setSort={setSort}
                                    type={column.type}
                                    value={filter[column.name]}
                                    filters={{filter: mapDataForGenerateReport().params}}
                                    setFilter={handleChangeFilter}
                                    source={column.source}
                                    notFilter={column.notFilterOperationalReport || column.notFilter}
                                    notSortAndFilter={column.notSortAndFilter}
                                    noSort={column.noSort}
                                    dataForRequest
                                />
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {click && report.map((row, indexRow) => (
                        <Table.Row key={row.id}>
                            {representationColumns.map((column, indexColumn) => (
                                <Table.Cell key={`${row.id}_${column.name}`} className={`${indexColumn === 0 && 'column-facet__first'}`}>
                                    <CellValue
                                        {...column}
                                        indexRow={indexRow}
                                        indexColumn={indexColumn}
                                        value={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].value
                                                : row[column.name]
                                        }
                                        valueText={
                                            row[column.name] && typeof row[column.name] === 'object'
                                                ? row[column.name].name
                                                : null
                                        }
                                        rowId={row.id}
                                        t={t}
                                        goToCard={goToCard}
                                    />
                                </Table.Cell>
                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        ),
        actions: () => [
            <Popup
                key='exportToExcel'
                content={t('exportToExcel')}
                position="bottom right"
                trigger={
                    <Button
                        icon="download"
                        disabled={!report.length || exportProgress}
                        loading={exportProgress}
                        onClick={exportExcel}
                    />
                }
            />,
            <Popup
                key='reset_filters'
                content={t('reset_filters')}
                position="bottom right"
                trigger={
                    <Button
                        icon
                        className={`clear-filter-btn`}
                        onClick={clearFilter}
                        disabled={!Object.keys(filter).length}
                    >
                        <IconCustom name="clear-filter"/>
                    </Button>
                }
            />,
        ],
    };

    const getRepresentations = callBackFunc => {
        dispatch(getRepresentationsRequest({key: OPERATIONAL_REPORT_TYPE, callBackFunc}));
    };

    const changeRepresentation = (key) => {
        dispatch(
            setRepresentationRequest({
                gridName: OPERATIONAL_REPORT_TYPE,
                value: key,
            }),
        );
    };

    const representation = {
        name: OPERATIONAL_REPORT_TYPE,
        representationName: representationName,
        representations: representations,
        getRepresentations: getRepresentations,
        changeRepresentation: changeRepresentation,
        sourceGridName: 'shippings',
    }

    const pagination = <Segment className='report__pagination' basic>
            <Form.Field inline>
                <label>{t('numberOfRows')}</label>
                <Dropdown
                    className='report__pagination-select'
                    options={options}
                    selection
                    text={count}
                    onChange={(e, { value }) => {
                        setCount(value);
                        setPage(1);
                    }}
                />
            </Form.Field>
            <Pagination
                activePage={page}
                onPageChange={(e, { activePage })=>setPage(activePage)}
                totalPages={Math.ceil(totalCount/count)}
            />
        </Segment>

    return (
        <div className="container">
            <div className="report">
                <div className="report_params" id='params'>
                    <div className="card-content-block_header">
                        <div className="card-content-block_header_actions">
                            <div
                                className="card-content-block_header_accordion"
                                onClick={() => setParamsOpen(!paramsOpen)}
                            >
                                <Icon name={paramsOpen ? 'angle down' : 'angle up'}/>
                            </div>
                        </div>
                    </div>
                    {paramsOpen && <Form>
                        <Grid>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <Date
                                        name="startDate"
                                        value={params.startDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Date
                                        name="endDate"
                                        value={params.endDate}
                                        onChange={handleChangeParams}
                                        noClearable
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <State
                                        isTranslate
                                        name="dateType"
                                        value={params.dateType}
                                        source={'dateTypeState'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        isTranslate
                                        name="planers"
                                        value={params.planers}
                                        textValue={(!params.planers || params.planers.length === 0) && t('All')}
                                        source={'shippings/TripCreatorName'}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={4}>
                                <Grid.Column>
                                    <MultiSelect
                                        isTranslate
                                        name="primaryTripStatus"
                                        source="primaryTripStatusState"
                                        textValue={(!params.primaryTripStatus || params.primaryTripStatus.length === 0) && t('All')}
                                        value={params.primaryTripStatus}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        isTranslate
                                        name="tarifficationType"
                                        source="tarifficationTypeState"
                                        textValue={(!params.tarifficationType || params.tarifficationType.length === 0) && t('All')}
                                        value={params.tarifficationType}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <MultiSelect
                                        name="transportCompanyIds"
                                        source="transportCompanies/all"
                                        textValue={(!params.transportCompanyIds || params.transportCompanyIds.length === 0) && t('All')}
                                        value={params.transportCompanyIds}
                                        onChange={handleChangeParams}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <Button className='report_button' primary disabled={notChangeForm || loading}
                                            onClick={generateReport}>
                                        {t('Generate report')}
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>}
                </div>

                <div className={`report_table ${!paramsOpen ? 'report_table_max' : ''}`}>
                    <Block
                        height={height}
                        item={item}
                        actions={item.actions}
                        loading={loading}
                        name={'operationReport'}
                        representation={representation}
                        footer={click && pagination}
                    />
                </div>
            </div>
        </div>
    );
};

export default OperationalReport;
