import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Popup } from 'semantic-ui-react';
import './style.scss';
import {
    BIG_TEXT_TYPE,
    BOOLEAN_TYPE,
    DATE_TIME_TYPE,
    DATE_TYPE,
    ENUM_TYPE,
    LINK_TYPE,
    NUMBER_TYPE,
    SELECT_TYPE,
    STATE_TYPE,
    TEXT_TYPE,
    TIME_TYPE,
    LOCAL_DATE_TIME,
    ACTIVE_TYPE,
    INTEGER_TYPE,
    CUSTOM_STATE_TYPE,
    MULTISELECT_TYPE,
    CONTAINS_TYPE,
    TIME_ZONE_TYPE,
    DATES_TYPE
} from '../../constants/columnTypes';
import TextFacet from './Text';
import NumberFacet from './Number';
import SelectFacet from './Select';
import DateFacet from './Date';
import TimeFacet from './Time';
import StateFacet from './State';
import Bool from './Bool';
import TextCropping from './TextCropping';

const getTypeFacet = {
    [TEXT_TYPE]: <TextFacet />,
    [BIG_TEXT_TYPE]: <TextFacet />,
    [NUMBER_TYPE]: <NumberFacet />,
    [SELECT_TYPE]: <SelectFacet />,
    [MULTISELECT_TYPE]: <SelectFacet />,
    [DATE_TIME_TYPE]: <DateFacet />,
    [DATE_TYPE]: <DateFacet />,
    [DATES_TYPE]: <DateFacet />,
    [LOCAL_DATE_TIME]: <DateFacet />,
    [TIME_TYPE]: <TimeFacet />,
    [STATE_TYPE]: <StateFacet />,
    [BOOLEAN_TYPE]: <Bool />,
    [ENUM_TYPE]: <SelectFacet />,
    [LINK_TYPE]: <TextFacet />,
    [ACTIVE_TYPE]: <Bool />,
    [CONTAINS_TYPE]: <Bool isContains/>,
    [INTEGER_TYPE]: <TextFacet />,
    [TIME_ZONE_TYPE]: <SelectFacet />,
};

const Control = props => {
    const { type } = props;

    return React.cloneElement(getTypeFacet[type] || <TextFacet />, props);
};

const FacetField = ({
    name,
    sort,
    setSort,
    type,
    value,
    setFilter,
    source,
    filterSource,
    index,
    handleResize,
    width,
    displayNameKey,
    notWrapHeader,
    notSortAndFilter,
    notFilter,
    gridName,
    filters,
    noSort,
    emptyValue,
    dataForRequest = null,
    className = '',
    reportFilters = {},
    customFilterNames
}) => {
    const { t } = useTranslation();

    const handleSort = () => {
        if (sort === true) {
            setSort({
                name,
                desc: false,
            });
        } else if (sort === false) {
            setSort(null);
        } else {
            setSort({
                name,
                desc: true,
            });
        }
    };

    const contextRef = useRef(null);
    const thRef = useRef(null);

    return (
        <div className={`facet ${className}`} ref={thRef}>
            {notSortAndFilter ? (
                <div className={`facet-field__disabled ${notWrapHeader ? 'facet-field__nowrap' : ''}`} ref={contextRef}>
                    {t(displayNameKey)}
                </div>
            ) : (
                <>
                    {notWrapHeader ? (
                        <div className="facet-field facet-field__nowrap" onClick={noSort ? null : handleSort} ref={contextRef}>
                            <span dangerouslySetInnerHTML={{ __html: t(displayNameKey) }} />
                        </div>
                    ) : (
                        <div onClick={noSort ? null : handleSort} ref={contextRef}>
                            <TextCropping width={width} indexColumn={index}>
                                <span dangerouslySetInnerHTML={{ __html: t(displayNameKey) }} />
                            </TextCropping>
                        </div>
                    )}
                    <div className="facet-actions">

                        {notFilter ? null : (
                            <div
                                className={
                                    value ? 'facet-actions__filter_active' : 'facet-actions__filter'
                                }
                            >
                                <Popup
                                    trigger={
                                        <Button>
                                            <Icon name="filter" />
                                        </Button>
                                    }
                                    context={contextRef}
                                    content={
                                        <Control
                                            type={type}
                                            name={name}
                                            value={value}
                                            source={source}
                                            emptyValue={emptyValue}
                                            gridName={gridName}
                                            filters={filters}
                                            onChange={setFilter}
                                            t={t}
                                            dataForRequest={dataForRequest}
                                            reportFilters={reportFilters}
                                            names={customFilterNames || ['containsValue', 'empty_value']}
                                            filterSource={filterSource}
                                            displayNameKey={displayNameKey}
                                        />
                                    }
                                    pinned
                                    basic
                                    position={'bottom center'}
                                    className="from-popup"
                                    on="click"
                                />
                            </div>
                        )}


                        <div className="facet-actions__sort">
                            {sort === false ? <Icon name="sort amount up" /> : null}
                            {sort === true ? <Icon name="sort amount down" /> : null}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default React.memo(FacetField);
