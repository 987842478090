import React, { useEffect, useState, useRef } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    customDictionariesMenuSelector,
    dataLoadingMenuSelector,
    dictionariesHeaderSelector,
    dictionariesMenuSelector,
    editSettingShippingsSelector,
    gridsMenuSelector,
    otherMenuSelector,
    reportsMenuSelector,
    rolesAndUsersMenu,
    roleSelector,
    userNameSelector,
    viewPersonalCabinetSelector,
} from '../../ducks/profile';
import { isAuthSelector, logoutRequest } from '../../ducks/login';
import './style.scss';
import {
    DICTIONARY_LIST_LINK,
    GRID_LIST_LINK,
    PARAMS_SETTING_LINK,
    PERSONAL_CABINET_LINK,
    PROFILE_LINK,
    REPORT_LINK,
    TC_FORM_NO_LOGGED_LINK
} from '../../router/links';
import {
    dataLoadingRequest,
    progressLoadingDataSelector,
    templateUploadRequest,
} from '../../ducks/loadingData';
import {PERSONAL_CABINET, PERSONAL_CABINET_HOME} from "../../constants/personalCabinetPages";
import logoFm from '../../img/fm-logo-menu.svg';
import SupportContacts from "./supportContacts";
import DutyShiftModal from "../../containers/customDictionary/dutyShiftModal";

const Header = () => {
    const dispatch = useDispatch();
    const grids = useSelector(state => gridsMenuSelector(state));
    const dictionariesList = useSelector(state => dictionariesMenuSelector(state));
    const dictionariesMenu = useSelector(state => dictionariesHeaderSelector(state));
    const customDictionariesMenu = useSelector(state => customDictionariesMenuSelector(state));
    const otherMenu = useSelector(state => otherMenuSelector(state));
    const usersAndRoles = useSelector(state => rolesAndUsersMenu(state));
    const dataLoadingMenu = useSelector(state => dataLoadingMenuSelector(state));
    const reportMenu = useSelector(state => reportsMenuSelector(state));
    const userName = useSelector(state => userNameSelector(state));
    const userRole = useSelector(state => roleSelector(state));
    const isAuth = useSelector(state => isAuthSelector(state));
    const loading = useSelector(state => progressLoadingDataSelector(state));
    const viewPersonalCabinet = useSelector(state => viewPersonalCabinetSelector(state));
    const editSettingShippings = useSelector(editSettingShippingsSelector);
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const fileUploader = useRef(null);

    let [activeItem, setActiveItem] = useState(location.pathname);
    let [currentTypeApi, setCurrentTypeApi] = useState(null);

    const [openDutyShiftModal, setOpenDutyShiftModal] = useState(false);

    useEffect(() => {
        setActiveItem(location.pathname);
    }, [location.pathname]);

    const logOut = () => {
        dispatch(logoutRequest());
    };

    const handleOpenProfile = () => {
        history.push(PROFILE_LINK);
    };

    const handleOpenParamsSetting = () => {
        history.push(PARAMS_SETTING_LINK);
    };

    const handleClickLoadData = (type, typeApi) => {
        if (type === 'unloading') {
            dispatch(templateUploadRequest({
                typeApi
            }));
        } else if (type === 'loading') {
            setCurrentTypeApi(typeApi);
            fileUploader && fileUploader.current.click();
        }
    };

    const handleClickReport = (type) => {
        history.push(REPORT_LINK.replace(':type', type));
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            dataLoadingRequest({
                form: data,
                typeApi: currentTypeApi
            }),
        );
        setCurrentTypeApi(null);
        fileUploader.current.value = null;
    };

    const isRtc = history.location.pathname.split("/").length > 2 && history.location.pathname.split("/")[history.location.pathname.split("/").length - 1]
    const headerView = isAuth || (history.location.pathname.includes(TC_FORM_NO_LOGGED_LINK.replace('/:id', '')) && isRtc);

    return (
        <>
            {headerView ? (
                <header>
                    <Menu pointing secondary fixed="top">
                        <Menu.Item
                            as={Link}
                            to={'/'}
                        >
                            <img alt="logo" src={logoFm} height={21} />
                        </Menu.Item>
                        {grids &&
                            grids.map(item => (
                                <Menu.Item
                                    className="large"
                                    key={item}
                                    as={Link}
                                    to={GRID_LIST_LINK.replace(':name', item)}
                                    name={item}
                                    active={activeItem.includes(item)}
                                >
                                    {t(item)}
                                </Menu.Item>
                            ))}
                        {dictionariesMenu && dictionariesMenu.length
                            ? dictionariesMenu.map(item => (
                                  <Menu.Item
                                      className="large"
                                      key={item}
                                      as={Link}
                                      to={DICTIONARY_LIST_LINK.replace(':name', item)}
                                      name={item}
                                      active={activeItem.includes(item)}
                                  >
                                      {t(item)}
                                  </Menu.Item>
                              ))
                            : null}
                        {otherMenu && otherMenu.length
                            ? otherMenu.map(item => (
                                  <Menu.Item
                                      className="large"
                                      key={item.name}
                                      as={Link}
                                      to={item.link}
                                      name={item.name}
                                      active={activeItem.includes(item.name)}
                                  >
                                      {t(item.name)}
                                  </Menu.Item>
                              ))
                            : null}
                        {(dictionariesList && dictionariesList.length) || usersAndRoles.length  || customDictionariesMenu.length ? (
                            <Menu.Menu>
                                <Dropdown
                                    text={t('dictionaries')}
                                    item
                                    className={`${[
                                        ...dictionariesList,
                                        ...usersAndRoles.map(item => item.name),
                                        ...customDictionariesMenu.map(item => item.name),
                                    ].some(x => activeItem.includes(DICTIONARY_LIST_LINK.replace(':name', x))) && 'superActive'}`}
                                >
                                    <Dropdown.Menu>
                                        {dictionariesList.map(item => {
                                            return (
                                                <Dropdown.Item
                                                    key={item}
                                                    as={Link}
                                                    to={DICTIONARY_LIST_LINK.replace(':name', item)}
                                                    active={activeItem.includes(DICTIONARY_LIST_LINK.replace(':name', item))}
                                                    name={item}
                                                >
                                                    {t(item)}
                                                </Dropdown.Item>
                                            );
                                        })}
                                        {[...usersAndRoles, ...customDictionariesMenu].map(item => (
                                            <Dropdown.Item
                                                key={item.name}
                                                as={Link}
                                                to={item.link}
                                                active={activeItem.includes(item.name)}
                                                name={item.name}
                                            >
                                                {t(item.name)}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {reportMenu && reportMenu.length ? (
                            <Menu.Menu>
                                <Dropdown
                                    className={reportMenu.some(x => activeItem.includes(x.type)) ? 'superActive' : ''}
                                    text={t('Reports')}
                                    item>
                                    <Dropdown.Menu>
                                        {reportMenu.map(item => (
                                            <Dropdown.Item
                                                key={item.name}
                                                name={item.name}
                                                text={t(item.name)}
                                                active={activeItem.includes(item.type)}
                                                onClick={() => handleClickReport(item.type)}
                                            />
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {dataLoadingMenu && dataLoadingMenu.length ? (
                            <Menu.Menu>
                                <Dropdown text={t('data_loading')} item loading={loading}>
                                    <Dropdown.Menu>
                                        {dataLoadingMenu.map(item => (
                                            <Dropdown
                                                item
                                                className="link item"
                                                key={item.name}
                                                name={item.name}
                                                text={t(item.name)}
                                            >
                                                <Dropdown.Menu>
                                                    {item.items.map(childItem => (
                                                        <Dropdown.Item
                                                            key={childItem.name}
                                                            name={childItem.name}
                                                            text={t(childItem.name)}
                                                            onClick={() =>
                                                                handleClickLoadData(childItem.type, item.typeApi)
                                                            }
                                                        />
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu.Menu>
                        ) : null}
                        {viewPersonalCabinet && <Menu.Item
                            className="large"
                            key={PERSONAL_CABINET}
                            as={Link}
                            to={PERSONAL_CABINET_LINK.replace(':pageName', PERSONAL_CABINET_HOME)}
                            name={PERSONAL_CABINET}
                            active={activeItem.includes(PERSONAL_CABINET)}
                        >
                            {t(PERSONAL_CABINET)}
                        </Menu.Item>}
                        {isAuth && <Menu.Item
                            className="large"
                            key={'dutyShift'}
                            onClick={() => setOpenDutyShiftModal(true)}
                        >
                            {t('dutyShift')}
                        </Menu.Item>}
                        <div className="header-support">
                            <SupportContacts/>
                            {userName && userRole ? (
                                <Menu.Menu>
                                    <Dropdown text={`${userName} (${userRole})`} item>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={handleOpenProfile}>
                                                {t('profile_settings')}
                                            </Dropdown.Item>
                                            {
                                                editSettingShippings &&
                                                <Dropdown.Item className='header-menu-wrap' onClick={handleOpenParamsSetting}>
                                                    {t('settingNotificationsAndParametersShippings')}
                                                </Dropdown.Item>
                                            }
                                            <Dropdown.Item onClick={logOut}>
                                                {t('exit')}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Menu.Menu>
                            ) : null}
                        </div>
                    </Menu>
                </header>
            ) : null}
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onChange={onFilePicked}
            />
            <DutyShiftModal
                open={openDutyShiftModal}
                onClose={() => setOpenDutyShiftModal(false)}
            />
        </>
    );
};

Header.propTypes = {};

export default Header;
