import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import TableInfo from '../../components/TableInfo';
import {
    canCreateByFormSelector,
    canDeleteSelector,
    canExportToExcelSelector,
    canImportFromExcelSelector,
    checkboxRowsSelector,
    clearDictionaryInfo,
    columnsFromListSelector as columnsSelector,
    deleteDictionaryRowsRequest,
    exportProgressSelector,
    exportToExcelRequest,
    getListRequest,
    importFromExcelRequest,
    importProgressSelector,
    isVisibleFieldsSelector,
    listSelector,
    progressSelector,
    saveDictionaryCardRequest,
    toggleActiveRequest,
    totalCountSelector,
} from '../../ducks/dictionaryView';
import { DICTIONARY_CARD_LINK, DICTIONARY_NEW_LINK } from '../../router/links';
import {Icon, Popup} from "semantic-ui-react";
import EditRow from "./editRow";
import {dateToUTC} from "../../utils/dateTimeFormater";
import {DUTY_PLANNERS} from "../../constants/grids";
import {transportCompanyIdSelector} from "../../ducks/profile";

class List extends Component {
    state = {
        editRows: []
    };

    componentWillUnmount() {
        this.props.clear();
    }

    handleImportFromExcel = (form, callbackSuccess) => {
        const { importFromExcel, match } = this.props;
        const { params = {} } = match;
        const { name = this.props.modalName || '' } = params;

        importFromExcel({
            form,
            name,
            callbackSuccess,
        });
    };

    handleExportToExcel = filter => {
        const { exportFromExcel, match } = this.props;
        const { params = {} } = match;
        const { name = this.props.modalName || '' } = params;
        exportFromExcel({
            name,
            filter,
        });
    };

    handleToggleIsActive = (itemID, checked, load, keyName) => {
        const { toggleActive, match = {} } = this.props;
        const { params = {} } = match;
        const { name = '' } = params;

        toggleActive({
            keyName: keyName === 'isAuctionsAvailable' ? 'setAuctionsAvailable': keyName,
            name: name,
            id: itemID,
            active: checked,
            callbackSuccess: () => {
                load(false, true);
            },
        });
    };

    changeEditRowsList = (id) => {
        this.setState(prevState => {
            let newEditRows = [];
            const prevEditRows = prevState.editRows;
            if (id) {
                newEditRows = prevEditRows.includes(id)
                    ? prevEditRows.filter(rowId => rowId !== id)
                    : [...prevEditRows, id]
            }
            return {editRows: newEditRows}
        })
    }

    editRow = (row, cols, actions) => {
        return <EditRow
            row={row}
            columns={cols}
            actions={actions}
        />
    }

    create = (row = {}) => {
        const id = `id-${Math.random()}`;
        this.setState(prevState => ({rows: [{...row, id, isNew: true}, ...(prevState.rows || [])]}))
        this.changeEditRowsList(id)
    }

    createNew = () => this.create();

    copy = (row, toTop) => {
        let rowNew = {...row};
        const {match = {}} = this.props;
        const {params = {}} = match;
        const {name} = params;
        if (name === DUTY_PLANNERS) {
            let date = new Date();
            const newDate = date.setDate(date.getDate() + 1);
            rowNew.responsiableEmployee = null;
            rowNew.showDate = [dateToUTC(newDate)];
        }
        this.create(rowNew);
        toTop && toTop();
    }

    save = (row) => {
        const {saveDictionaryCard, match = {}} = this.props;
        const {params = {}} = match;
        const {name} = params;

        let form = {...row};

        if (row.isNew) {
            delete form.isNew;
            delete form.id;
        }

        saveDictionaryCard({
            name,
            params: form,
            changedProps: [],
            callbackSuccess: () => {
                this.setState(
                    prevState => ({trigger: !prevState.trigger}),
                    () => this.cancel(row.id)
                )
            }
        })
    }

    cancel = (id) => {
        this.setState(prevState => ({rows: (prevState.rows || []).filter(r => r.id !== id)}))
        this.changeEditRowsList(id)
    }

    actions = (row, load, t, deleteRow, isEdit, toTop) => {
        return [
            <Popup
                content={t('copy')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='copy outline'
                        onClick={() => this.copy(row, toTop)}
                    />
                }
            />,
            isEdit && <Popup
                content={t('save')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='save'
                        color='light-green'
                        onClick={() => this.save(row)}
                    />
                }
            />,
            !isEdit && <Popup
                content={t('edit')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='pencil alternate'
                        onClick={() => this.changeEditRowsList(row.id)}
                    />
                }
            />,
            !row.isNew && !isEdit && <Popup
                content={t('delete')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='trash alternate'
                        onClick={() => deleteRow(row.id)}
                    />
                }
            />,
            isEdit && <Popup
                content={t('Cancel')}
                position="left center"
                trigger={
                    <Icon
                        className='margin-left-8'
                        name='delete'
                        onClick={() => this.cancel(row.id)}
                    />
                }
            />
        ]
    }

    render() {
        const {
            match = {},
            columns,
            loadList,
            progress,
            totalCount,
            list,
            isCreateBtn,
            isImportBtn,
            isExportBtn,
            importLoader,
            exportLoader,
            clear,
            checkboxRows,
            deleteRows,
            isVisibleFields,
            canDelete,
            modalName = null,
            transportCompanyUserId
        } = this.props;
        const {rows = [], trigger} = this.state;
        const {params = {}} = match;
        const {name = modalName || ''} = params;

        const columnsList = columns.filter(column => !(isVisibleFields[column.name]));
        const isFullWidth = columnsList.length >= 10
            || ['deliveryAddresses', 'companiesContacts', 'documentTemplates', 'contactsWorkScheduleDispatchers', 'systemSettings'].includes(name);
        const createDisabled = ['notices'].includes(name)

        const isNewStyle = [DUTY_PLANNERS].includes(name);

        return (
            <TableInfo
                isNewStyle={isNewStyle}
                isShowActions={isNewStyle}
                actions={isNewStyle && this.actions}
                editRow={(row, actions) => this.editRow(row, columnsList, actions)}
                editRows={this.state.editRows}
                newOnClick={(isCreateBtn && isNewStyle) && this.createNew}
                triggerLoad={isNewStyle && trigger}

                key={name}
                headerRow={columnsList}
                name={name}
                className={
                    modalName ? '' : (isFullWidth
                        ? 'container'
                        : 'wider ui container container-margin-top-bottom')
                }
                loadList={loadList}
                loading={progress}
                totalCount={totalCount}
                title={name}
                list={isNewStyle ? [...rows, ...list] : list}
                clear={clear}
                storageSortItem={!modalName && `${name}Sort`}
                storageFilterItem={!modalName && `${name}Filters`}
                isDeleteBtn={canDelete}
                isImportBtn={isImportBtn}
                isExportBtn={isExportBtn}
                importFromExcel={this.handleImportFromExcel}
                exportToExcel={this.handleExportToExcel}
                importLoader={importLoader}
                exportLoader={exportLoader}
                newLink={(isCreateBtn && !modalName && !createDisabled && !isNewStyle) ? DICTIONARY_NEW_LINK : null}
                cardLink={(isCreateBtn && !modalName && !isNewStyle) ? DICTIONARY_CARD_LINK : null}
                checkboxRows={checkboxRows && !modalName}
                deleteRows={deleteRows}
                isModal={!!modalName}
                toggleIsActive={this.handleToggleIsActive}
                transportCompanyUserId={transportCompanyUserId}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { match = {} } = ownProps;
    const { params = {} } = match;
    const { name = ownProps.modalName || '' } = params;

    return {
        columns: columnsSelector(state, name),
        isVisibleFields: isVisibleFieldsSelector(state, name),
        progress: progressSelector(state),
        totalCount: totalCountSelector(state),
        list: listSelector(state),
        isCreateBtn: canCreateByFormSelector(state, name),
        canDelete: canDeleteSelector(state, name),
        isImportBtn: canImportFromExcelSelector(state, name),
        isExportBtn: canExportToExcelSelector(state, name),
        checkboxRows: checkboxRowsSelector(state, name),
        importLoader: importProgressSelector(state),
        exportLoader: exportProgressSelector(state),
        transportCompanyUserId: transportCompanyIdSelector(state)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadList: params => {
            dispatch(getListRequest(params));
        },
        importFromExcel: params => {
            dispatch(importFromExcelRequest(params));
        },
        exportFromExcel: params => {
            dispatch(exportToExcelRequest(params));
        },
        deleteRows: params => {
            dispatch(deleteDictionaryRowsRequest(params));
        },
        clear: () => {
            dispatch(clearDictionaryInfo());
        },
        toggleActive: (params) => {
            dispatch(toggleActiveRequest(params));
        },
        saveDictionaryCard: (params) => {
            dispatch(saveDictionaryCardRequest(params));
        },
    };
};

export default withTranslation()(
    withRouter(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(List),
    ),
);
