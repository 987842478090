import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledLogo = styled.div`
    min-height: 40px;
    margin-bottom: 40px;
`;

export const StyledLoginWrapper = styled.div`
    background-position: center top;
    background-size: 100%;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-image: url('/login_background.png');
`;

export const StyledLogin = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 64px 28px 26px 28px;
    height: 100vh;
`;

export const StyledLoginTitle = styled.h3`
    color: #015da9;
    font-size: 45px;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 32px;
    max-width: 375px;
`;

export const StyledLoginButton = styled(Button)`
    margin-top: 30% !important;
    font-size: 14px !important;
    padding: 16px 0 !important;
`;

export const StyledLoginLink = styled.a`
    text-decoration: underline;
    text-align: center;
    display: block;
    padding: 0 24px;
    margin: 16px 0;
    max-width: 375px;
`;
