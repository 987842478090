import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
    Button,
    Checkbox,
    Dimmer,
    Form,
    Icon,
    Loader,
    Modal,
    Segment
} from "semantic-ui-react";
import './styles.scss'
import YMap from "./map";
import RadioButtons from "../../components/BaseComponents/RadioButtons";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {isTransportCompanyUserSelector} from "../../ducks/profile";
import {
    defaultFiltersSelector,
    getDefaultFiltersRequest,
    getShippingsMapDataRequest,
    mapDataSelector,
    progressSelector
} from "../../ducks/maps";
import {Link, useHistory} from "react-router-dom";
import {GRID_CARD_LINK} from "../../router/links";
import {SHIPPINGS_GRID} from "../../constants/grids";
import DateOrPeriod from "../../components/BaseComponents/DateOrPeriod";
import {toast} from "react-toastify";

export const colors = {
    blue: '#0066ff',
    orange: '#ff5900',
    green: '#1fb622',
}

const Point = ({txt, color}) => (
    <div className='b-point'>
        <div className='circle-point'
             style={color ? {
                 outline: `2px solid ${color}`,
                 backgroundColor: color
             } : {}}></div>
        <div className='description'>{txt}</div>
    </div>
)

const ShippingsMap = ({open, onClose}) => {
    const history = useHistory();

    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [form, setForm] = useState({});
    const [shipping, setShipping] = useState(null);
    const [route, setRoute] = useState(null);

    const onChange = (e, {value, name}) => {
        setForm(f => ({
            ...f,
            [name]: value
        }))
    }

    const isTransportCompanyUser = useSelector(state => isTransportCompanyUserSelector(state));
    const mapData = useSelector(state => mapDataSelector(state));
    const loading = useSelector(state => progressSelector(state));
    const defaultFilters = useSelector(state => defaultFiltersSelector(state));

    const refresh = (f) => {
        setRoute(null)
        setShipping(null)
        dispatch(getShippingsMapDataRequest({
            filters: f || form,
            arrayIsEmptyCallback: () => {
                toast.error(t('shippingsNotFound'))
            }
        }))
    }

    useEffect(() => {
        open && dispatch(getDefaultFiltersRequest())
    }, [open])

    useEffect(() => {
        if (open) {
            setForm(defaultFilters)
            refresh(defaultFilters)
        }
    }, [defaultFilters])

    const closeModal = () => {
        setForm({})
        setRoute(null)
        setShipping(null)
        onClose()
    }

    const btnNameByType = (mD) => {
        switch (mD.pointType) {
            case 0:
                return 'pickUpShipping'
            case 1:
                return 'sendPriceOffer';
            case 2:
                return null;
        }
    }

    const pointInfo = (point) => {
        const isDelivery = point.isDelivery;
        return <Form.Field key={point.orderNumber + '_' + point.isDelivery}>
            <div className='margin-bottom-4'>
                <b>{`${!isDelivery ? t('dateUploading') : t('dateUnloading')}: `}</b>{point.date || ''}</div>
            <div className='margin-bottom-4'>
                <b>{`${!isDelivery ? t('uploading') : t('unloading')} ${point.orderNumber}: `}</b>{point.address || ''}
            </div>
        </Form.Field>
    }

    useEffect(() => {
        !form.datePeriod && onChange(null, {
            name: 'showInProgressShippings',
            value: false
        })
    }, [form.datePeriod])

    const [height, setHeight] = useState(0);

    const ro = useRef(new ResizeObserver(el => {
        setHeight(el[0].target.offsetHeight);
    }));

    const resize = useCallback((container) => {
        if (container) ro.current.observe(container);
        else if (ro.current) {
            ro.current.disconnect();
            setHeight(0);
        }
    }, [ro.current]);

    return (
        <Modal
            open={open}
            closeIcon
            onClose={closeModal}
            className='map-modal'
        >
            <Segment vertical>
                <Dimmer active={loading} inverted>
                    <Loader size="huge"/>
                </Dimmer>
                <div className='map-modal__content'>
                    <div className='map-modal__filters'>
                        <div ref={resize}>
                            <Form >
                                <Form.Field>
                                    <label><b>{t('selectUploadDate')}</b></label>
                                    <DateOrPeriod
                                        noLabel
                                        name='datePeriod'
                                        value={form.datePeriod || ''}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><b>{t('selectVehicleType')}</b></label>
                                    <RadioButtons
                                        isMultiSelect
                                        noLabel
                                        source='vehicleTypeEnum'
                                        name='vehicleTypes'
                                        value={form.vehicleTypes}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label><b>{t('selectVehicleBodyType')}</b></label>
                                    <RadioButtons
                                        isMultiSelect
                                        noLabel
                                        source='bodyTypes'
                                        name='vehicleBodyTypes'
                                        value={form.vehicleBodyTypes}
                                        onChange={onChange}
                                    />
                                </Form.Field>
                                {form.datePeriod && <Form.Field>
                                    <Checkbox
                                        label={t(isTransportCompanyUser ? 'showMyShippings' : 'showTcShippings')}
                                        onChange={(e, {checked}) => onChange(e, {
                                            name: 'showInProgressShippings',
                                            value: checked
                                        })}
                                        checked={form.showInProgressShippings}
                                    />
                                    <span className='field-hint'>* {t('shippingMap.periodInfo')}</span>
                                </Form.Field>}
                                <Button
                                    className='map-modal__btn'
                                    color='blue'
                                    onClick={() => refresh()}
                                >
                                    <b>{t('refreshShippings')}</b>
                                </Button>
                                <div className='map-modal__legend'>
                                    <label><b>{t('shippingMap.colorSchemeInfo')}:</b></label>
                                    <div className='map-modal__legend-points'>
                                        <Point color={colors.blue} txt={t('shippingMap.legend.firstUploadingPoint')}/>
                                        <Point txt={t('shippingMap.legend.intermediatePoint')}/>
                                        <Point color={colors.green} txt={t('shippingMap.legend.lastUnloadingPoint')}/>
                                        <Point color={colors.orange} txt={t('shippingMap.legend.lastInprogressPointUnloading')}/>
                                    </div>
                                </div>
                            </Form>
                            <div className='map-modal__line'/>
                        </div>

                        {shipping && <Form className='map-modal__scroll' style={{height: `calc(100% - ${height}px`}}>
                            <Form.Field className='d-flex'>
                                <b className='map-modal__shipping-number'>
                                    <Link
                                        className='color__black'
                                        to={GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', shipping.id)}
                                    >
                                        №{shipping.shippingNumber}
                                    </Link>
                                    {shipping.pointType !== 2 && shipping.cost ? ` - ${shipping.cost} ₽` : ''}
                                </b>
                                {shipping.pointType !== 2 &&
                                    <Button
                                        className='margin-left-8 w-100'
                                        size='mini'
                                        onClick={() => setRoute(route => (route && shipping.id === route.id) ? null : shipping)}
                                    >
                                        <Icon name='map marker alternate'/> <b>{t('route')}</b>
                                    </Button>
                                }
                            </Form.Field>
                            {
                                (shipping.stoppers || []).map(s => pointInfo(s))
                            }
                            <Form.Field>
                                <div className='margin-bottom-4'><b>{shipping.vehicleTypeName}</b></div>
                                <div className='margin-bottom-4'>
                                    <b>{`${t('contactNumber')}: ${shipping.contactNumber || ''}`}</b></div>
                            </Form.Field>
                            {btnNameByType(shipping) && <Button
                                className='map-modal__btn'
                                color='blue'
                                onClick={() => history.push(GRID_CARD_LINK.replace(':name', SHIPPINGS_GRID).replace(':id', shipping.id))}
                            >
                                <b>{t(btnNameByType(shipping))}</b>
                            </Button>}
                        </Form>}
                    </div>
                    <div className='map-modal__map'>
                        <YMap
                            isTransportCompanyUser={isTransportCompanyUser}
                            mapData={mapData}
                            setShipping={setShipping}
                            routeData={route}
                            setRouteData={setRoute}
                        />
                    </div>
                </div>
            </Segment>
        </Modal>
    );
};

export default ShippingsMap;