import React from 'react';
import styled from 'styled-components';
import { Card } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


import StateValue from '../../components/ColumnsValue/StateValue';
import { roleSelector } from '../../ducks/profile';

const AddressIcon = () => (
    <svg width="14" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6.835C1 3.612 3.566 1 6.732 1c3.166 0 5.732 2.612 5.732 5.835 0 3.197-1.83 6.928-4.684 8.262a2.471 2.471 0 0 1-2.096 0C2.829 13.763 1 10.032 1 6.835Z" fill="#22BA46" stroke="#22BA46" strokeWidth="1.1" />
        <path d="M6.732 8.882a2.15 2.15 0 1 0 0-4.3 2.15 2.15 0 0 0 0 4.3Z" fill="#fff" stroke="#fff" strokeWidth="1.1" />
    </svg>
);

const AddressOutlineIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 6.67589C2 3.54118 4.49646 1 7.57601 1C10.6556 1 13.152 3.54118 13.152 6.67589C13.152 9.78604 11.3724 13.4153 8.59565 14.7131C7.94842 15.0156 7.2036 15.0156 6.55636 14.7131C3.77967 13.4153 2 9.78604 2 6.67589Z" stroke="#1C78D3" strokeWidth="1.2" />
        <path d="M7.57611 8.66711C8.73094 8.66711 9.66711 7.73094 9.66711 6.57611C9.66711 5.42128 8.73094 4.48511 7.57611 4.48511C6.42128 4.48511 5.48511 5.42128 5.48511 6.57611C5.48511 7.73094 6.42128 8.66711 7.57611 8.66711Z" stroke="#1C78D3" strokeWidth="1.2" />
    </svg>

);

const ClockFastIcon = () => (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 6.5C2 3.46243 4.46243 1 7.5 1C10.5376 1 13 3.46243 13 6.5C13 9.36902 10.8033 11.725 8 11.9776V12.9811C11.3562 12.7257 14 9.92161 14 6.5C14 2.91015 11.0899 0 7.5 0C3.91015 0 1 2.91015 1 6.5C1 6.66824 1.00639 6.83498 1.01894 7H2.02242C2.00758 6.83532 2 6.66854 2 6.5Z" fill="#1C78D3" />
        <path d="M0.796976 8C0.520834 8 0.296976 8.22386 0.296976 8.5C0.296976 8.77614 0.520834 9 0.796976 9H3C3.27614 9 3.5 8.77614 3.5 8.5C3.5 8.22386 3.27614 8 3 8H0.796976Z" fill="#1C78D3" />
        <path d="M0 10.5C0 10.2239 0.223858 10 0.5 10H4.5C4.77614 10 5 10.2239 5 10.5C5 10.7761 4.77614 11 4.5 11H0.5C0.223858 11 0 10.7761 0 10.5Z" fill="#1C78D3" />
        <path d="M1.5 12C1.22386 12 1 12.2239 1 12.5C1 12.7761 1.22386 13 1.5 13H6C6.27614 13 6.5 12.7761 6.5 12.5C6.5 12.2239 6.27614 12 6 12H1.5Z" fill="#1C78D3" />
    </svg>
);

const TemperatureIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.375 3.004C10.375 -0.118998 5.6245 -0.119998 5.625 3.004V9.623C5.0075 10.235 4.625 11.0835 4.625 12.021C4.625 13.885 6.136 15.396 8 15.396C9.864 15.396 11.375 13.885 11.375 12.021C11.375 11.0835 10.9925 10.235 10.375 9.623V3.004ZM8 14.625C6.55 14.625 5.375 13.4495 5.375 11.9995C5.375 11.223 5.712 10.5255 6.2475 10.045L6.25 10.043L6.253 10.037C6.318 9.976 6.3605 9.892 6.3685 9.7985V9.797C6.369 9.79 6.372 9.785 6.3725 9.778L6.3755 9.7635V3.0035C6.374 2.977 6.373 2.946 6.373 2.9145C6.373 2.0625 7.0635 1.372 7.9155 1.372C7.9455 1.372 7.9755 1.373 8.005 1.3745H8.001C8.0265 1.373 8.056 1.372 8.086 1.372C8.938 1.372 9.6285 2.0625 9.6285 2.9145C9.6285 2.946 9.6275 2.977 9.6255 3.0075V3.0035V9.7635L9.6285 9.778L9.632 9.796C9.6395 9.8915 9.6825 9.976 9.7475 10.037L9.7505 10.043C10.2885 10.526 10.6255 11.2235 10.6255 11.9995C10.6255 13.4495 9.45 14.6245 8 14.625ZM8.375 10.6835V6.7505C8.375 6.5435 8.207 6.3755 8 6.3755C7.793 6.3755 7.625 6.5435 7.625 6.7505V10.6835C7.043 10.8525 6.625 11.3805 6.625 12.0065C6.625 12.766 7.2405 13.3815 8 13.3815C8.7595 13.3815 9.375 12.766 9.375 12.0065C9.375 11.3805 8.957 10.8525 8.3845 10.686L8.375 10.6835ZM13.25 1.125C12.3525 1.125 11.625 1.8525 11.625 2.75C11.625 3.6475 12.3525 4.375 13.25 4.375C14.1475 4.375 14.875 3.6475 14.875 2.75C14.874 1.853 14.147 1.126 13.25 1.125ZM13.25 3.625C12.767 3.625 12.375 3.233 12.375 2.75C12.375 2.267 12.767 1.875 13.25 1.875C13.733 1.875 14.125 2.267 14.125 2.75C14.1245 3.233 13.733 3.6245 13.25 3.625Z" fill="#1C78D3" />
    </svg>
);

const CalendarIcon = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.07812 7.5C4.07812 7.81929 3.81929 8.07812 3.5 8.07812C3.18071 8.07812 2.92188 7.81929 2.92188 7.5C2.92188 7.18071 3.18071 6.92188 3.5 6.92188C3.81929 6.92188 4.07812 7.18071 4.07812 7.5Z" fill="#1C78D3" />
        <path d="M3.5 10.0781C3.81929 10.0781 4.07812 9.81929 4.07812 9.5C4.07812 9.18071 3.81929 8.92188 3.5 8.92188C3.18071 8.92188 2.92188 9.18071 2.92188 9.5C2.92188 9.81929 3.18071 10.0781 3.5 10.0781Z" fill="#1C78D3" />
        <path d="M6.07812 7.5C6.07812 7.81929 5.81929 8.07812 5.5 8.07812C5.18071 8.07812 4.92188 7.81929 4.92188 7.5C4.92188 7.18071 5.18071 6.92188 5.5 6.92188C5.81929 6.92188 6.07812 7.18071 6.07812 7.5Z" fill="#1C78D3" />
        <path d="M5.5 10.0781C5.81929 10.0781 6.07812 9.81929 6.07812 9.5C6.07812 9.18071 5.81929 8.92188 5.5 8.92188C5.18071 8.92188 4.92188 9.18071 4.92188 9.5C4.92188 9.81929 5.18071 10.0781 5.5 10.0781Z" fill="#1C78D3" />
        <path d="M8.07812 7.5C8.07812 7.81929 7.81929 8.07812 7.5 8.07812C7.18071 8.07812 6.92188 7.81929 6.92188 7.5C6.92188 7.18071 7.18071 6.92188 7.5 6.92188C7.81929 6.92188 8.07812 7.18071 8.07812 7.5Z" fill="#1C78D3" />
        <path d="M9.5 8.07812C9.81929 8.07812 10.0781 7.81929 10.0781 7.5C10.0781 7.18071 9.81929 6.92188 9.5 6.92188C9.18071 6.92188 8.92188 7.18071 8.92188 7.5C8.92188 7.81929 9.18071 8.07812 9.5 8.07812Z" fill="#1C78D3" />
        <path d="M8.07812 9.5C8.07812 9.81929 7.81929 10.0781 7.5 10.0781C7.18071 10.0781 6.92188 9.81929 6.92188 9.5C6.92188 9.18071 7.18071 8.92188 7.5 8.92188C7.81929 8.92188 8.07812 9.18071 8.07812 9.5Z" fill="#1C78D3" />
        <path d="M9.5 10.0781C9.81929 10.0781 10.0781 9.81929 10.0781 9.5C10.0781 9.18071 9.81929 8.92188 9.5 8.92188C9.18071 8.92188 8.92188 9.18071 8.92188 9.5C8.92188 9.81929 9.18071 10.0781 9.5 10.0781Z" fill="#1C78D3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M3.5 0C3.77614 0 4 0.223858 4 0.5V2H9V0.5C9 0.223858 9.22386 0 9.5 0C9.77614 0 10 0.223858 10 0.5V2H10.5C11.8807 2 13 3.11929 13 4.5V10.5C13 11.8807 11.8807 13 10.5 13H2.5C1.11929 13 0 11.8807 0 10.5V4.5C0 3.11929 1.11929 2 2.5 2H3V0.5C3 0.223858 3.22386 0 3.5 0ZM2.5 3C1.84689 3 1.29127 3.4174 1.08535 4H11.9146C11.7087 3.4174 11.1531 3 10.5 3H2.5ZM1 5H12V10.5C12 11.3284 11.3284 12 10.5 12H2.5C1.67157 12 1 11.3284 1 10.5V5Z" fill="#1C78D3" />
    </svg>

);

const DocumentIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.5 1C4.11929 1 3 2.11929 3 3.5V11.5C3 12.8807 4.11929 14 5.5 14H10.5C11.8807 14 13 12.8807 13 11.5V5.48477C13 5.08694 12.842 4.70541 12.5607 4.4241L9.5759 1.43934C9.29459 1.15804 8.91306 1 8.51524 1H5.5ZM4 3.5C4 2.67157 4.67157 2 5.5 2H8V4.5C8 5.32843 8.67157 6 9.5 6H12L12 11.5C12 12.3284 11.3284 13 10.5 13H5.5C4.67157 13 4 12.3284 4 11.5V3.5ZM11.7223 5L9 2.27766V4.5C9 4.77614 9.22386 5 9.5 5H11.7223Z" fill="#1C78D3" />
    </svg>

);

const ShareIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.06 3.40053C3.33801 2.03098 4.54804 1 6 1H12C13.6569 1 15 2.34315 15 4V11C15 12.6569 13.6569 14 12 14H6C4.34315 14 3 12.6569 3 11V10.5C3 10.2239 3.22386 10 3.5 10C3.77614 10 4 10.2239 4 10.5V11C4 12.1046 4.89543 13 6 13H12C13.1046 13 14 12.1046 14 11V4C14 2.89543 13.1046 2 12 2H6C5.033 2 4.22528 2.68678 4.04002 3.59947C3.98508 3.87009 3.72117 4.04494 3.45054 3.99001C3.17992 3.93507 3.00507 3.67116 3.06 3.40053Z" fill="#1C78D3" />
        <path d="M0.5 3C0.776142 3 1 3.22386 1 3.5V3.55C1 5.45538 2.54462 7 4.45 7H9.79289L7.64645 4.85355C7.45118 4.65829 7.45118 4.34171 7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L10.6464 6.43934C11.2322 7.02513 11.2322 7.97487 10.6464 8.56066L8.35355 10.8536C8.15829 11.0488 7.84171 11.0488 7.64645 10.8536C7.45118 10.6583 7.45118 10.3417 7.64645 10.1464L9.79289 8L4.45 8C1.99233 8 1.19209e-07 6.00767 0 3.55V3.5C0 3.22386 0.223858 3 0.5 3Z" fill="#1C78D3" />
    </svg>

);

const MoneyIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7 9H8.5C9.88071 9 11 7.88071 11 6.5C11 5.11929 9.88071 4 8.5 4H6.69048C6.30914 4 6 4.30914 6 4.69048V8H5.5C5.22386 8 5 8.22386 5 8.5C5 8.77614 5.22386 9 5.5 9H6V10H5.5C5.22386 10 5 10.2239 5 10.5C5 10.7761 5.22386 11 5.5 11H6V11.75C6 12.0261 6.22386 12.25 6.5 12.25C6.77614 12.25 7 12.0261 7 11.75V11H9.25C9.52614 11 9.75 10.7761 9.75 10.5C9.75 10.2239 9.52614 10 9.25 10H7V9ZM7 5V8H8.5C9.32843 8 10 7.32843 10 6.5C10 5.67157 9.32843 5 8.5 5H7Z" fill="#1C78D3" />
        <path fillRule="evenodd" clipRule="evenodd" d="M1 8.00439C1 4.1384 4.13401 1.00439 8 1.00439C11.866 1.00439 15 4.1384 15 8.00439C15 11.8704 11.866 15.0044 8 15.0044C4.13401 15.0044 1 11.8704 1 8.00439ZM8 2.00439C4.68629 2.00439 2 4.69069 2 8.00439C2 11.3181 4.68629 14.0044 8 14.0044C11.3137 14.0044 14 11.3181 14 8.00439C14 4.69069 11.3137 2.00439 8 2.00439Z" fill="#1C78D3" />
    </svg>

);

const AuctionIcon = () => (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9255 5.96324C13.4653 5.50307 12.7894 5.42034 12.2549 5.67624L8.67774 2.09904C8.93749 1.55371 8.84224 0.880026 8.39136 0.429148C7.81925 -0.143049 6.88823 -0.143049 6.31606 0.429148L4.24073 2.50445C3.66859 3.07659 3.66859 4.00758 4.24073 4.57972C4.69047 5.02952 5.36309 5.12693 5.91062 4.8661L6.66194 5.61742L0.430147 11.8492C-0.143339 12.4227 -0.143426 13.3509 0.430147 13.9245C1.00363 14.498 1.93188 14.4981 2.50545 13.9245L8.73724 7.69272L9.48847 8.44392C9.22867 8.98928 9.32391 9.66293 9.77482 10.1138C10.052 10.391 10.4205 10.5437 10.8125 10.5437C11.2045 10.5437 11.5729 10.391 11.8501 10.1138L13.9254 8.03854C14.4976 7.46637 14.4976 6.53543 13.9255 5.96324ZM5.62431 3.88796C5.43358 4.07866 5.12324 4.0786 4.93252 3.88796C4.74183 3.69721 4.74183 3.38693 4.93252 3.19621L7.00782 1.12091C7.19851 0.930212 7.5088 0.930124 7.69958 1.12091C7.8903 1.31163 7.8903 1.62194 7.69961 1.81266L5.62431 3.88796ZM1.81369 13.2327C1.6225 13.4238 1.31313 13.4239 1.12193 13.2327C0.930772 13.0415 0.930743 12.7321 1.12193 12.5409L7.35373 6.30912L8.04549 7.0009L1.81369 13.2327ZM6.66194 4.23384L7.00779 3.88793L8.04546 2.85027L11.5043 6.30914L10.1208 7.69269L6.66194 4.23384ZM13.2337 7.34681L11.1584 9.42211C10.9672 9.6133 10.6579 9.6133 10.4667 9.42211C10.2759 9.23139 10.2759 8.92105 10.4667 8.73032L12.542 6.65502C12.7331 6.46383 13.0426 6.46386 13.2337 6.65502C13.4249 6.84619 13.4249 7.15559 13.2337 7.34681Z" fill="#22BA46" />
        <path d="M13.5197 12.0651H9.60649C8.79734 12.0651 8.13904 12.7234 8.13904 13.5326V14.5109C8.13904 14.7811 8.35806 15 8.62818 15H14.498C14.7682 15 14.9872 14.781 14.9872 14.5109V13.5326C14.9872 12.7234 14.3289 12.0651 13.5197 12.0651ZM14.0089 14.0217H9.11735V13.5326C9.11735 13.2629 9.33678 13.0435 9.60649 13.0435H13.5197C13.7894 13.0435 14.0088 13.2629 14.0088 13.5326V14.0217H14.0089Z" fill="#22BA46" />
    </svg>
);

const TelephoneIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_812_7642)">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.99739 1.69685C3.58798 1.10626 4.54553 1.10626 5.13613 1.69685L6.6696 3.23032C7.26281 3.82354 7.26281 4.78533 6.6696 5.37855C6.35218 5.69597 6.08273 6.04721 5.93725 6.39233C5.79565 6.72822 5.78312 7.02413 5.91344 7.29536C6.11053 7.70555 6.48358 8.20618 7.14267 8.86528C7.80173 9.52434 8.30245 9.89737 8.71268 10.0945C8.9839 10.2248 9.27981 10.2123 9.61571 10.0707C9.96082 9.92518 10.3121 9.65574 10.6295 9.33832C11.2227 8.7451 12.1845 8.7451 12.7777 9.33832L14.3112 10.8718C14.9018 11.4624 14.9018 12.4199 14.3112 13.0105C13.1191 14.2026 11.3935 15.0499 9.6197 14.6612C8.12496 14.3337 6.37478 13.5421 4.42031 11.5876C2.46585 9.63318 1.67421 7.88309 1.34668 6.38833C0.958014 4.61458 1.80535 2.88889 2.99739 1.69685ZM4.42902 2.40396C4.22895 2.20389 3.90457 2.20389 3.70449 2.40396C2.65431 3.45414 2.03345 4.85056 2.32351 6.17429C2.60805 7.47287 3.30116 9.05428 5.12742 10.8805C6.95366 12.7068 8.53516 13.3999 9.83375 13.6844C11.1575 13.9745 12.5539 13.3536 13.6041 12.3034C13.8041 12.1033 13.8041 11.779 13.6041 11.5789L12.0706 10.0454C11.8679 9.84273 11.5393 9.84273 11.3366 10.0454C10.9712 10.4108 10.5142 10.7771 10.0042 10.9921C9.48492 11.211 8.8788 11.2837 8.2796 10.9958C7.72304 10.7284 7.12948 10.2663 6.43556 9.57238C5.74168 8.8785 5.27951 8.28503 5.01208 7.72843C4.72419 7.12924 4.79689 6.52312 5.01578 6.00388C5.23078 5.49386 5.59708 5.03686 5.96249 4.67144C6.16518 4.46875 6.16518 4.14012 5.96249 3.93743L4.42902 2.40396Z" fill="#1C78D3" />
        </g>
        <defs>
            <clipPath id="clip0_812_7642">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

const UserOutlineIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M7.07536 1.3645C5.44075 1.3645 4.11563 2.68962 4.11563 4.32424C4.11563 5.95885 5.44075 7.28397 7.07536 7.28397C8.70998 7.28397 10.0351 5.95885 10.0351 4.32424C10.0351 2.68962 8.70998 1.3645 7.07536 1.3645ZM5.38485 4.32424C5.38485 3.39059 6.14172 2.63373 7.07536 2.63373C8.00901 2.63373 8.76587 3.39059 8.76587 4.32424C8.76587 5.25788 8.00901 6.01475 7.07536 6.01475C6.14172 6.01475 5.38485 5.25788 5.38485 4.32424Z" fill="#1C78D3" />
        <path d="M3.25946 11.7499C3.25946 11.1696 3.41136 10.7693 3.62436 10.4801C3.84201 10.1846 4.15645 9.96096 4.54679 9.79501C5.34747 9.4546 6.34626 9.40571 7.07534 9.40571C7.80442 9.40571 8.80321 9.4546 9.60389 9.79501C9.99423 9.96096 10.3087 10.1846 10.5263 10.4801C10.7393 10.7693 10.8912 11.1696 10.8912 11.7499C10.8912 12.1004 11.1754 12.3845 11.5258 12.3845C11.8763 12.3845 12.1604 12.1004 12.1604 11.7499C12.1604 10.9308 11.9399 10.2591 11.5483 9.7274C11.1613 9.20201 10.6389 8.85587 10.1005 8.62696C9.04365 8.17765 7.81719 8.13648 7.07534 8.13648C6.3335 8.13648 5.10704 8.17765 4.0502 8.62696C3.51178 8.85587 2.9894 9.20201 2.60242 9.7274C2.21077 10.2591 1.99023 10.9308 1.99023 11.7499C1.99023 12.1004 2.27436 12.3845 2.62485 12.3845C2.97533 12.3845 3.25946 12.1004 3.25946 11.7499Z" fill="#1C78D3" />
    </svg>
);

const StyledSpan = styled.span`
    font-weight: 500;
    color: #8A8A8A;
`;

const StyledIcon = styled.div`
    width: 16px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledCard = styled(Card)`
    width: 100% !important;

    & .content {
        padding: 10px !important;
    }

    & > .card-header {
        & > .card-header__title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;

            & > span {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0px;
            }

            & > .status-value {
                font-size: 12px;
                flex-basis: content;
            }
        }

        & > .card-header__address {
            display: flex;
            gap: 4px;
            align-items: center;

            & > svg {
                min-width: 18px;
            }

            & > span {
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                letter-spacing: 0px;

                word-break: break-all;
            }
        }
    }

    & > .card-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 10px;
        gap: 3px;
        font-size: 14px !important;;
        align-items: center;

        & > .card-content__item {
            display: flex;
            gap: 4px;
            font-size: 12px;

            &:nth-child(-n+3) {
                width: auto !important;
            }

            & > .card-content__title {
                display: flex;
                gap: 4px;
            }

            & > .card-content__text {
                word-break: break-all;
            }
        }
    }

    & > .card-footer {}
`;

const renderItems = (items, t) => items.filter(Boolean).map(
    ({ icon, label, value }) => (
        <div className="card-content__item" key={label + value}>
            <div className="card-content__title">
                {icon && <StyledIcon>{icon}</StyledIcon>}

                <StyledSpan>{`${t(label)}:`}</StyledSpan>
            </div>

            <div className="card-content__text">{value}</div>
        </div>
    ),
);

const getData = (values, isDocuments, userIsAdminOrCarrier) => {
    if (isDocuments) {
        return {
            content: [
                { icon: <CalendarIcon />, label: 'schedule', value: values.schedule },
                { label: 'Type', value: values.deliveryPointType?.name },
                { icon: <AddressOutlineIcon />, label: 'address', value: values.address },
            ],
            otherContent: [
                { icon: <UserOutlineIcon />, label: 'contactPerson', value: values.contactPerson },
                { icon: <TelephoneIcon />, label: 'telephoneNumber', value: <a href={`tel:${values.telephoneNumber}`}>{values.telephoneNumber}</a> },
            ],
        };
    }

    return {
        content: [
            { icon: <ClockFastIcon />, label: 'vehicleTypeId', value: values.vehicleTypeId?.name },
            values.temperatureMin && { icon: <TemperatureIcon />, label: 'temperatureLabel', value: `${values.temperatureMin} °С` },
            userIsAdminOrCarrier && values.transportCompanyId && { icon: <DocumentIcon />, label: 'Case_TransportCompanyId', value: values.transportCompanyId?.name },
            { icon: <CalendarIcon />, label: 'firstLoadingDateTime', value: values.firstLoadingDateTime },
            { icon: <DocumentIcon />, label: 'uploading', value: `${values.firstLoadingClientName}${values.firstLoadingCity ? `, ${values.firstLoadingCity}` : ''}` },
            { icon: <CalendarIcon />, label: 'firstUnloadingDateTime', value: values.firstUnloadingDateTime },
            { icon: <DocumentIcon />, label: 'unloading', value: `${values.lastUnloadingClientName}, ${values.lastUnloadingCity}` },
        ],
        otherContent: [
            { icon: <ShareIcon />, label: 'loadingStoppersCountShort', value: values.loadingStoppersCount },
            { icon: <ShareIcon />, label: 'unloadingStoppersCountShort', value: values.unloadingStoppersCount },
            { icon: <MoneyIcon />, label: 'ShippingDto.ServiceCost', value: values.basicDeliveryCostWithoutVAT || '-' },
            values.bestAuctionPriceOffer && { icon: <AuctionIcon />, label: 'bestAuctionPriceOfferShort', value: values.bestAuctionPriceOffer },
        ],
    };
};

const MobileCard = ({
    indexRow,
    data,
    isHeader,
}) => {
    const { t } = useTranslation();
    const userRole = useSelector(state => roleSelector(state));
    const userIsAdminOrCarrier = (
        userRole === 'Administrator' || userRole === 'TransportCompanyEmployee'
    );
    const cardData = getData(data, isHeader, userIsAdminOrCarrier);

    return (
        <StyledCard>
            {!isHeader ? (
                <Card.Content className="card-header">
                    <div className="card-header__title">
                        <span>{`№ ${data.shippingNumber}`}</span>

                        <StateValue
                            value={data.status}
                            source="shippingState"
                            indexRow={indexRow}
                        />
                    </div>

                    <div className="card-header__address">
                        <AddressIcon />

                        <span>{`${data.fromWhere} - ${data.whereTo}`}</span>
                    </div>
                </Card.Content>
            ) : null}

            <Card.Content className="card-content">
                {renderItems(cardData.content, t)}
            </Card.Content>

            <Card.Content className="card-content">
                {renderItems(cardData.otherContent, t)}
            </Card.Content>
        </StyledCard>
    );
};

export default MobileCard;
