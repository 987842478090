import React, {useCallback} from 'react';
import { useHistory } from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Button, Divider, Form, Grid} from 'semantic-ui-react';
import {useSelector} from 'react-redux';
import {columnsTypesConfigSelector} from '../../../../ducks/representations';
import {SHIPPINGS_GRID} from '../../../../constants/grids';
import FormField from "../formField";
import {LINKS_TYPE} from "../../../../constants/columnTypes";
import {REPORT_LINK} from "../../../../router/links";

import {DOC_RETURN_REPORT_TYPE} from "../../../../constants/reportType";
import {userPermissionsSelector} from "../../../../ducks/profile";
import {DOCS_RETURN_REPORT_SHOW_PERMISSION} from "../../../../constants/permissions";

const DeliveryDocuments = ({form = {}, onChange, settings = {}, error}) => {
    const {t} = useTranslation();
    const history = useHistory();

    const goToReport = () => {
        history.push(`${REPORT_LINK.replace(':type', DOC_RETURN_REPORT_TYPE)}?shippingNumber=${form.shippingNumber}`);
    }

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};
    const userPermissions = useSelector(state => userPermissionsSelector(state));

    const changeDocumentsDeliveryPointHandler = useCallback((e, {name, value}) => {
        onChange(e, {name: 'documentsDeliveryPointAddress', value: null})
        onChange(e, {name, value})
    }, []);

    return (
        <Form className="tabs-card">
            <Grid>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Grid.Row>
                            <b>{t('driverData')}</b>
                            <Divider/>
                        </Grid.Row>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="documentsDeliveryDate"
                                        columnsConfig={columnsConfig}
                                        settings={settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                        popperPlacement='bottom'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="documentsDeliveryPoint"
                                        columnsConfig={columnsConfig}
                                        settings={settings}
                                        form={form}
                                        error={error}
                                        onChange={changeDocumentsDeliveryPointHandler}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {form.documentsDeliveryPoint && form.documentsDeliveryPoint.value !== 'transportCompany' && <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="documentsDeliveryPointAddress"
                                        columnsConfig={columnsConfig}
                                        settings={settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                        source={`deliveryAddresses/${form.documentsDeliveryPoint.value}`}
                                    />
                                </Grid.Column>
                            </Grid.Row>}
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="documentsDeliveryPointByDriver"
                                        columnsConfig={columnsConfig}
                                        settings={settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column>
                        <Grid.Row>
                            <b>{t('tcData')}</b>
                            <Divider/>
                        </Grid.Row>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isDisabled={!form.isDocumentDataEdit}
                                        name="documentsReceiveDate"
                                        columnsConfig={columnsConfig}
                                        settings={!form.isDocumentDataEdit && settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                        popperPlacement='bottom'
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isDisabled={!form.isDocumentDataEdit}
                                        name="documentsDeliveryStatus"
                                        columnsConfig={columnsConfig}
                                        settings={!form.isDocumentDataEdit && settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isDisabled={!form.isDocumentDataEdit}
                                        name="documentsDeliveryStatusComment"
                                        columnsConfig={columnsConfig}
                                        settings={!form.isDocumentDataEdit && settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isDisabled={!form.isDocumentDataEdit}
                                        name="anomalyType"
                                        columnsConfig={columnsConfig}
                                        settings={!form.isDocumentDataEdit && settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isDisabled={!form.isDocumentDataEdit}
                                        name="documentLinks"
                                        columnsConfig={columnsConfig}
                                        settings={settings}
                                        form={form}
                                        error={error}
                                        onChange={onChange}
                                        type={LINKS_TYPE}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row centered>
                    <Button onClick={goToReport} disabled={!userPermissions.includes(DOCS_RETURN_REPORT_SHOW_PERMISSION)}>{t('showDetails')}</Button>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default DeliveryDocuments;
