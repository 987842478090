import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Divider, Form, Grid, Icon} from 'semantic-ui-react';
import FormField from '../../../../components/BaseComponents';
import {TEXT_TYPE, BIG_TEXT_TYPE, DATE_TIME_TYPE, NUMBER_TYPE} from '../../../../constants/columnTypes';
import {
    anomaliesClientSelector,
    getAnomalies,
    settingsExtSelector
} from '../../../../ducks/gridCard';
import StopperDocuments from './stopperDocuments';
import Anomalies from "./anomalies";
import {columnsTypesConfigSelector} from "../../../../ducks/representations";
import {SHIPPINGS_GRID} from "../../../../constants/grids";
import {SETTINGS_TYPE_HIDE} from "../../../../constants/formTypes";

const Stopper = ({ name, form = {}, stopper = {}, index, settings: baseSettings, onChange, updateHistory }) => {
    const { t } = useTranslation();
    const settings = useSelector(state => settingsExtSelector(state, form.status));
    const [anomaliesOpen, setAnomaliesOpen] = useState(false);
    const [changedValues, setChangedValues] = useState({});
    const dispatch = useDispatch();

    const columnsConfig = useSelector(state => columnsTypesConfigSelector(state, SHIPPINGS_GRID)) || {};

    const onChangeValue = (e, {value, name}) => {
        let newValue = [...form.stoppers];
        setChangedValues(v => ({...v, [`${name}_${index}`]: true}));

        newValue[index] = {
            ...newValue[index],
            [name]: value
        };

        onChange(e, {
            value: newValue,
            name: 'stoppers'
        })
    };

    const anomalies = useSelector(state => anomaliesClientSelector(state, stopper.id)) || [];

    const anomalyVisible = baseSettings['isAnomaliesAvailable'] !== SETTINGS_TYPE_HIDE;

    useEffect(()=>{
         setAnomaliesOpen(anomalies && anomalies.length > 0 && anomalyVisible);
    }, [anomalies]);

    const getStopperAnomalies = () => {
        dispatch(getAnomalies({
            id: stopper.id,
            type: 'stopperAnomalyType'
        }));
    };

    useEffect(()=>{
        stopper.id && getStopperAnomalies();
    }, [stopper.id]);

    const callbackSuccess = () => {
        //getStopperAnomalies();
        updateHistory();
    }

    return (
        <Form style={{ paddingLeft: '12px' }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width="8">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width="16">
                                    <FormField
                                        isReadOnly
                                        name="clientName"
                                        text={
                                            stopper.isDelivery
                                                ? 'stopper_clientNameDelivery'
                                                : 'stopper_clientNameLoading'
                                        }
                                        value={stopper['clientName'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['clientName']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width="10">
                                    <FormField
                                        isReadOnly
                                        name="dateTime"
                                        text={'estimatedDateTime'}
                                        value={stopper['dateTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['dateTime']}
                                    />
                                </Grid.Column>
                                <Grid.Column width="6">
                                    <FormField
                                        isReadOnly
                                        name="tariffZoneMoscow"
                                        text={'stopper_tariffZoneMoscow'}
                                        value={stopper['tariffZoneMoscow'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['tariffZoneMoscow']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="driverArrivalTimeComment"
                                        text={'driverArrivalTimeComment'}
                                        value={stopper['driverArrivalTimeComment'] || ''}
                                        type={BIG_TEXT_TYPE}
                                        settings={settings['driverArrivalTimeComment']}
                                        onChange={onChangeValue}
                                        isReadOnly={stopper['driverArrivalTimeComment'] && !changedValues[`driverArrivalTimeComment_${index}`]}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="factArrivalTime"
                                        text={'factArrivalTime'}
                                        value={stopper['factArrivalTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['factArrivalTime']}
                                        onChange={onChangeValue}
                                        isReadOnly={stopper['factArrivalTime'] && !changedValues[`factArrivalTime_${index}`]}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        name="factDepartureTime"
                                        text={'factDepartureTime'}
                                        value={stopper['factDepartureTime'] || ''}
                                        type={DATE_TIME_TYPE}
                                        settings={settings['factDepartureTime']}
                                        onChange={onChangeValue}
                                        isReadOnly={stopper['factDepartureTime'] && !changedValues[`factDepartureTime_${index}`]}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width="8">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="address1"
                                        text={'stopper_address1'}
                                        value={stopper['address1'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['address1']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="address2And3"
                                        text={'stopper_address2And3'}
                                        value={stopper['address2And3'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['address2And3']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="city"
                                        text={'stopper_city'}
                                        value={stopper['city'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['city']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="postalCode"
                                        text={'stopper_postalCode'}
                                        value={stopper['postalCode'] || ''}
                                        type={TEXT_TYPE}
                                        settings={settings['postalCode']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="cargoUnits"
                                        text={'stopper_cargoUnits'}
                                        value={stopper['cargoUnits'] || ''}
                                        type={BIG_TEXT_TYPE}
                                        settings={settings['cargoUnits']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="pointWeight"
                                        text={'pointWeight'}
                                        value={stopper['pointWeight'] || ''}
                                        type={NUMBER_TYPE}
                                        settings={settings['pointWeight']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <FormField
                                        isReadOnly
                                        name="instruction"
                                        text={'stopper_instruction'}
                                        value={stopper['instruction'] || ''}
                                        type={BIG_TEXT_TYPE}
                                        settings={settings['instruction']}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid className='stopper-anomaly'>
                <Grid.Row>
                    <Grid.Column>
                        <div style={{display: 'flex', justifyContent: 'space-between', color: 'black'}}>
                            <b>{t('stopperAnomalies')}</b>
                            <div className="card-content-block_header_actions">
                                <div
                                    className="card-content-block_header_accordion"
                                    onClick={() => setAnomaliesOpen(!anomaliesOpen)}
                                >
                                    <Icon name={anomaliesOpen ? 'angle down' : 'angle up'}/>
                                </div>
                            </div>
                        </div>
                        <Divider/>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row style={{marginBottom: '20px'}}>
                    <Grid.Column>
                        <Grid style={{display: anomaliesOpen ? 'inherit' : 'none'}}>
                            <Anomalies
                                isDelivery={stopper.isDelivery}
                                disabled={!anomalyVisible}
                                id={stopper.id}
                                name={'stopperAnomalies'}
                                typeName={'stopperAnomalyType'}
                                form={anomalies}
                                settings={baseSettings}
                                onChange={onChangeValue}
                                columnsConfig={columnsConfig}
                                callbackSuccess={callbackSuccess}/>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <StopperDocuments form={stopper} settings={settings} />
        </Form>
    );
};

export default Stopper;
