import React, {useEffect, useState} from 'react'
import {Button, Form, Icon, Input, Popup} from 'semantic-ui-react'
import Facet from "../FilterComponents/Date";
import Dates from "./Dates";
import {useTranslation} from "react-i18next";

const DateOrPeriod = ({value, name, onChange, multiDates, error, noLabel, isDisabled, isRequired, text, textAll = '', onClose}) => {
    const {t} = useTranslation();
    const [isPeriod, setIsPeriod] = useState(false);

    const clear = () => {
        onChange(null, {name, value: null});
    }

    const input = (
        <Input
            disabled={isDisabled}
            name={name}
            value={value || textAll}
            icon={(multiDates && value) ? <Icon
                name='delete'
                link
                onClick={clear}
            /> : 'calendar alternate outline'}
            error={error}
        />
    );

    const content = <Facet
        isPeriod={multiDates}
        scrollOff
        name={name}
        value={multiDates ? (value || [''])[0] : value}
        onChange={multiDates ? (e, {value, name}) => onChange(e, {name, value: [value]}) : onChange}
    />

    const handlePeriod = () => {
        clear();
        setIsPeriod(prevState => !prevState)
    }

    useEffect(() => {
        if (multiDates && value && value[0] && value[0].includes('-')) setIsPeriod(true)
    }, [value])

    return (
        <Form.Field className='dates-input dates-or-period'>
            {!noLabel ? (
                <label className={isDisabled ? 'label-disabled' : null}>
                    <span dangerouslySetInnerHTML={{__html: `${t(text || name)}${isRequired ? ' *' : ''}`}}/>
                </label>
            ) : null}
            <Popup
                trigger={input}
                content={!multiDates ? content : <div>
                    {
                        !isPeriod ?
                            <Dates
                                inline
                                value={value}
                                onChange={onChange}
                                name={name}
                                error={error}
                                noLabel
                            />
                            : content
                    }
                    <div className='margin-top-8'>
                        <Button size="mini" compact onClick={handlePeriod}>
                            {t(isPeriod ? 'Choose dates' : 'Choose period')}
                        </Button>
                    </div>
                </div>}
                on='click'
                hideOnScroll
                position='bottom center'
                onClose={onClose}
            />
            {error && typeof error === 'string' ? (
                <span className="label-error" dangerouslySetInnerHTML={{ __html: error }} />
            ) : null}
        </Form.Field>
    )

}
export default DateOrPeriod
