import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import {
    saveModelProgressSelector,
    setModel
} from "../../../../ducks/gridCard";
import RequiredFieldsModal from "../../../../components/RequiredFieldsModal";
import {SELECT_TYPE} from "../../../../constants/columnTypes";

const ModelModal = ({open = false, error, onClose, name, id}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const saveLoading = useSelector(state => saveModelProgressSelector(state));

    const fieldName = name === 'vehicles' ? 'vehicleModel' : 'trailerModel';
    const title = name === 'vehicles' ? 'setVehicleModel' : 'setTrailerModel';
    const text = name === 'vehicles' ? 'setVehicleModelText' : 'setTrailerModelText';

    const saveLicense = (data) => {
        dispatch(setModel({...data, model: data.form[field.name].value, name}));
    }

    const field = {
        type: SELECT_TYPE,
        name: fieldName,
        isRequired: true,
        source: `${fieldName}List`
    }

    return (
        <RequiredFieldsModal
            open={open}
            id={id}
            onClose={onClose}
            error={error}
            text={t(text)}
            title={t(title)}
            fields={[field]}
            saveRequest={saveLicense}
            loading={saveLoading}
        />
    );
}

export default ModelModal;
