import React, { useEffect } from 'react';
import { Modal, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateToUTC } from '../../utils/dateTimeFormater';
import { getListRequest, listForModalSelector } from '../../ducks/dictionaryView';
import { DUTY_PLANNERS } from '../../constants/grids';

const DutyShiftModal = ({ open = false, onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const date = dateToUTC(new Date());

    const tableHeaders = [
        {
            name: 'area',
            key: 'area',
        },
        {
            name: 'responsiableEmployee',
            key: 'responsiableEmployee',
        },
        {
            name: 'subdivision',
            key: 'subdivision',
        }];

    const rows = useSelector((state) => listForModalSelector(state)) || [];

    useEffect(() => {
        const countRows = 50;

        const params = {
            filter: {
                filter: {
                    showDate: date,
                },
                skip: 0,
                take: countRows,
            },
            name: DUTY_PLANNERS,
            isTableModal: true,
        };

        (open && date) && dispatch(getListRequest(params));
    }, [open, date]);

    return (
        <Modal
            open={open}
            closeIcon
            onClose={onClose}
        >
            <Modal.Header>
                {t('dutyShiftTitle', { date })}
            </Modal.Header>
            <Modal.Content className="padding-0">
                <div className="table-modal duty-shift-table">
                    <Table
                        celled
                        structured
                    >
                        <Table.Header>
                            <Table.Row>
                                {
                                    tableHeaders.map((header) => (
                                        <Table.HeaderCell textAlign="center">
                                            {t(header.name)}
                                        </Table.HeaderCell>
                                    ))
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                (rows && rows.length) ? rows.map((row) => (
                                    <Table.Row>
                                        {
                                            tableHeaders.map((header) => (
                                                <Table.Cell>
                                                    {row[header.key] || ''}
                                                </Table.Cell>
                                            ))
                                        }
                                    </Table.Row>
                                )) : (
                                    <Table.Row>
                                        <Table.Cell colSpan={tableHeaders.length} textAlign="center">
                                            {t('noData')}
                                        </Table.Cell>
                                    </Table.Row>
                                )
                            }
                        </Table.Body>
                    </Table>
                </div>
            </Modal.Content>
        </Modal>
    );
};

export default DutyShiftModal;
