import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    List,
} from 'semantic-ui-react';
import { FloatingBubble } from 'antd-mobile';
import { AddOutline } from 'antd-mobile-icons';
import InfiniteScroll from 'react-infinite-scroll-component';

import { getListRequest, listSelector, totalCountSelector } from '../../../../ducks/dictionaryView';
import { PAGE_SIZE } from '../../../../constants/settings';
import StateValue from '../../../../components/ColumnsValue/StateValue';
import Filters from '../../components/filters/Filters';
import MobileCard from '../../newCard';

const columns = {
    trailers: [
        {
            kind: 'select',
            name: 'securityServiceCheckState',
            source: 'list',
            title: 'status',
            icon: 'calendar alternate outline',
            params: {
                name: 'securityServiceCheckState',
                entities: 'trailers',
                isFilter: true,
                params: {
                    filter: { search: '' },
                },
            },
        },
        {
            kind: 'select',
            name: 'vehicleTypeId',
            source: 'list',
            title: 'vehicleTypeId',
            icon: 'map marker alternate',
            params: {
                name: 'vehicleTypeId',
                entities: 'shippings',
                isFilter: true,
            },
        },
    ],
    vehicles: [
        {
            kind: 'select',
            name: 'securityServiceCheckState',
            source: 'list',
            title: 'status',
            icon: 'calendar alternate outline',
            params: {
                name: 'securityServiceCheckState',
                entities: 'trailers',
                isFilter: true,
                params: {
                    filter: { search: '' },
                },
            },
        },
        {
            kind: 'select',
            name: 'vehicleTypeId',
            source: 'list',
            title: 'vehicleTypeId',
            icon: 'map marker alternate',
            params: {
                name: 'vehicleTypeId',
                entities: 'shippings',
                isFilter: true,
            },
        },
    ],
    transportCompanyDrivers: [
        {
            kind: 'text',
            name: 'firstName',
            title: 'firstName',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'lastName',
            title: 'lastName',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'patronymic',
            title: 'patronymic',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'telephoneNumber',
            title: 'telephoneNumber',
            icon: 'map marker alternate',
            params: {},
        },
    ],
    deliveryAddresses: [
        {
            kind: 'select',
            name: 'deliveryPointType',
            title: 'deliveryPointType',
            icon: 'map marker alternate',
            params: { entities: 'deliveryAddresses', isFilter: true, params: { filter: { search: '' } } },
        },
        {
            kind: 'text',
            name: 'address',
            title: 'address',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'contactPerson',
            title: 'contactPerson',
            icon: 'map marker alternate',
            params: {},
        },
        {
            kind: 'text',
            name: 'telephoneNumber',
            title: 'telephoneNumber',
            icon: 'map marker alternate',
            params: {},
        },
    ],
};

const SecurityServiceWrapper = ({ value, index }) => (
    <StateValue
        value={value}
        source="objectSecurityCheckState"
        indexRow={index}
    />
);

const TelephoneNumberWrapper = ({ value }) => (
    <a href={`tel:${value}`}>{value}</a>
);

const defaultColumns = {
    vehicles: [
        {
            name: 'vehicleTypeId', title: 'vehicleTypeId',
        },
        { name: 'vehicleNumber', title: 'number' },
        { name: 'securityServiceCheckState', title: 'Статус', wrapper: SecurityServiceWrapper },
    ],
    trailers: [
        {
            name: 'vehicleTypeId', title: 'vehicleTypeId',
        },
        { name: 'trailerNumber', title: 'number' },
        { name: 'securityServiceCheckState', title: 'Статус', wrapper: SecurityServiceWrapper },
    ],
    transportCompanyDrivers: [
        {
            name: 'lastName', title: 'lastName',
        },
        {
            name: 'firstName', title: 'firstName',
        },
        { name: 'patronymic', title: 'patronymic' },
        { name: 'telephoneNumber', title: 'telephoneNumber', wrapper: TelephoneNumberWrapper },
    ],
    deliveryAddresses: [],
};

const VehiclesPage = ({ getList, list, totalCount }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { name } = useParams();
    const isCard = name === 'deliveryAddresses';
    const [filters, setFilters] = useState({});

    const loadMore = ({ isConcat = false, isReload = false }) => {
        getList({
            name,
            filter: {
                filter: {
                    ...filters,
                    vehicleTypeId: filters.vehicleTypeId?.join('|'),
                    securityServiceCheckState: filters.securityServiceCheckState?.join('|'),
                    deliveryPointType: filters.deliveryPointType?.join('|'),
                },
                take: PAGE_SIZE,
                skip: isReload ? 0 : list?.length,
            },
            isConcat,
            isReload,
        });
    };

    const handleChangeFilters = (newFilters) => setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
    }));

    const handleResetFilters = (newFilters) => setFilters(() => ({
        ...newFilters,
    }));

    const onClick = () => {
        history.push(`/dictionary/${name}/new`);
    };

    useEffect(() => {
        loadMore({ isConcat: false, isReload: true });
    }, [name, filters]);

    useEffect(() => {
        setFilters({});
    }, [name]);

    const renderCardContent = () => (
        <List style={{ margin: '2px 4px' }}>
            {list.map((item, index) => (
                <List.Item key={item?.id}>
                    <MobileCard isHeader indexRow={index} data={item} />
                </List.Item>
            ))}
        </List>
    );

    const renderTableContent = () => (
        <Table fixed collapsing singleLine unstackable size="small" style={{ width: '100%' }}>
            <TableHeader>
                <TableRow>
                    {defaultColumns[name].map((model) => (
                        <TableHeaderCell key={model.name}>{t(model.title)}</TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBody>
                {list.map((item, index) => (
                    <TableRow key={item?.id}>
                        {!!defaultColumns[name]?.length && defaultColumns[name].map((model) => (
                            <TableCell key={model.name + item?.id} style={{ textWrap: 'balance' }}>
                                {model.wrapper ? (
                                    <model.wrapper value={item[model.name]} index={index} />
                                ) : item[model.name]?.name || item[model.name]}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    return (
        <>
            <Filters
                columns={columns[name]}
                onChange={handleChangeFilters}
                onReset={handleResetFilters}
            />

            <InfiniteScroll
                dataLength={list.length}
                next={() => loadMore({ isConcat: true })}
                hasMore={list.length < totalCount}
                style={{ padding: '0 10px' }}
            >
                {isCard ? renderCardContent() : renderTableContent()}
            </InfiniteScroll>

            <FloatingBubble
                style={{
                    '--initial-position-bottom': '24px',
                    '--initial-position-right': '24px',
                    '--edge-distance': '24px',
                    '--background': '#22BA46',
                }}
                onClick={onClick}
            >
                <AddOutline fontSize={32} />
            </FloatingBubble>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getList: (params) => {
        dispatch(getListRequest(params));
    },
});

const mapStateToProps = (state) => ({
    list: listSelector(state),
    totalCount: totalCountSelector(state),
});

const WrappedPage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VehiclesPage);

export default WrappedPage;
