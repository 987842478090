import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Redirect, Switch, useLocation, useHistory, Route,
} from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { List } from 'semantic-ui-react';
import styled from 'styled-components';
import qs from 'qs';

import {
    getListRequest,
    totalCountSelector,
    modesFromGridSelector,
    listSelector,
    progressSelector,
    gridModesCountersSelector,
} from '../../ducks/gridList';
import { homePageSelector } from '../../ducks/profile';
import CustomGridCard from '../../containers/customGrid/card';
import { setUrlSearchParam } from '../../utils/urlParamsHelper';
import CustomDictionaryCard from '../../containers/customDictionary/card';
import { SHIPPINGS_GRID } from '../../constants/grids';
import { PAGE_SIZE } from '../../constants/settings';
import {
    GRID_LIST_LINK,
    GRID_CARD_LINK,
    DICTIONARY_LIST_LINK,
    DICTIONARY_CARD_LINK,
    DICTIONARY_NEW_LINK,
    LOGIN_LINK,
} from '../links';
import UnauthRoute from '../unauthRoute';
import PrivateRoute from '../privateRoute';
import Filters from './components/filters/Filters';
import MobileCard from './newCard';
import MyTabs from './tabs';
import LoginMobile from './pages/login/login';
import VehiclesPage from './pages/vehicles';

const columns = [
    {
        kind: 'select',
        name: 'fromWhere',
        source: 'zones',
        title: 'fromWhere',
        icon: 'map marker alternate',
        params: { isForm: true },
    },
    {
        kind: 'select',
        name: 'whereTo',
        source: 'zones',
        title: 'whereTo',
        icon: 'map marker alternate',
        params: { isForm: true },
    },
    {
        kind: 'calendar',
        name: 'firstLoadingDateTime',
        title: 'firstLoadingDateTimeFilter',
        icon: 'calendar alternate outline',
        getSelectedItems(dates = []) {
            return () => dates;
        },
    },
    {
        kind: 'select',
        name: 'vehicleTypeId',
        source: 'list',
        title: 'vehicleTypeId',
        icon: 'map marker alternate',
        params: {
            name: 'vehicleTypeId',
            entities: 'shippings',
            isFilter: true,
        },
    },
];

const StyledText = styled.div`
    text-align: center;
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    color: #dbdbdb;
    padding: 10px 0;
`;

const HomePage = ({
    list, getList, totalCount, modes,
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [filters, setFilters] = useState({ search: '' });
    const loading = useSelector(progressSelector);
    const counters = useSelector(gridModesCountersSelector);

    const loadMore = ({ isConcat, isReload, tab = 0 }) => {
        getList({
            name: SHIPPINGS_GRID,
            filter: {
                filter: {
                    search: filters.search,
                    fromWhere: filters.fromWhere,
                    whereTo: filters.whereTo,
                    vehicleTypeId: filters.vehicleTypeId?.join('|'),
                    firstLoadingDateTime: filters.firstLoadingDateTime?.join('-'),
                    gridMode: `${tab}`,
                },
                take: PAGE_SIZE,
                skip: isReload ? 0 : list?.length,
            },
            isConcat,
            isReload,
        });
    };

    const handleChangeFilters = (newFilters) => setFilters((prevFilters) => ({
        ...prevFilters,
        ...newFilters,
    }));

    const handleResetFilters = (newFilters) => setFilters(() => ({
        ...newFilters,
    }));

    const handleClick = (id) => {
        history.push(`/grid/shippings/${id}`);
    };

    const handleChangeTab = (data) => {
        loadMore({ tab: data.activeIndex, isReload: true, isConcat: false });
    };

    useEffect(() => {
        let { search } = location;
        const { mode } = qs.parse(search, { ignoreQueryPrefix: true }) || {};

        if (
            modes?.length && (
                mode === undefined
                || !modes.find((item) => item.mode.toString() === mode)
            )
        ) {
            search = setUrlSearchParam(location, 'mode', modes[0].mode).search;
        }

        history.replace({ pathname: location.pathname, state: null, search });
    }, []);

    useEffect(() => {
        loadMore({ isReload: true, isConcat: false });
    }, [filters]);

    return (
        <MyTabs onTabChange={handleChangeTab} counters={counters}>
            <Filters
                columns={columns}
                source={SHIPPINGS_GRID}
                onChange={handleChangeFilters}
                onReset={handleResetFilters}
            />

            <InfiniteScroll
                dataLength={list.length}
                next={() => loadMore({ isConcat: true })}
                hasMore={list.length < totalCount}
                loader={<StyledText>{t('data_loading')}</StyledText>}
                endMessage={<StyledText>{t('shippingsNotFound')}</StyledText>}
            >
                <List style={{ margin: '2px 14px' }}>
                    {list.map((item, index) => (
                        <List.Item key={item?.id} onClick={() => handleClick(item.id)}>
                            <MobileCard
                                indexRow={index}
                                data={item}
                            />
                        </List.Item>
                    ))}
                </List>
            </InfiniteScroll>
        </MyTabs>
    );
};

const mapDispatchToProps = (dispatch) => ({
    getList: (params) => {
        dispatch(getListRequest(params));
    },
});

const mapStateToProps = (state, ownProps) => {
    const { match = {}, nameReport } = ownProps;
    const { params = {} } = match;
    const { name = nameReport || '' } = params;

    return {
        list: listSelector(state),
        modes: modesFromGridSelector(state, name),
        totalCount: totalCountSelector(state),
    };
};

const WrappedHomePage = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomePage);

const MainRouteMobile = () => {
    const location = useLocation();
    const history = useHistory();

    const homePage = useSelector((state) => homePageSelector(state));

    useEffect(
        () => {
            const { pathname } = location;

            if (pathname === '/grid' && homePage) {
                history.push(homePage);
            }
        },
        [homePage],
    );

    return (
        <Switch>
            <PrivateRoute exact path={GRID_LIST_LINK} component={WrappedHomePage} />
            <PrivateRoute exact path={GRID_CARD_LINK} component={(props) => <CustomGridCard {...props} isMobile />} />

            <Route exact path={DICTIONARY_LIST_LINK} component={VehiclesPage} />
            <PrivateRoute exact path={DICTIONARY_NEW_LINK} component={CustomDictionaryCard} />
            <PrivateRoute exact path={DICTIONARY_CARD_LINK} component={CustomDictionaryCard} />

            <UnauthRoute exact path={LOGIN_LINK} component={LoginMobile} />

            <PrivateRoute exact path="*" component={() => <Redirect to={homePage} />} />
        </Switch>
    );
};

export default MainRouteMobile;
